import { FC, useState } from "react";
import CustomDatePicker from "../common/FullWidthDatePicker";
interface ILabelAndDate {
  label: string;
  value: Date | undefined;
  setValue: (value: React.SetStateAction<Date|undefined>) => void;
  isRequired?:boolean;
}
const LabelAndDate: FC<ILabelAndDate> = ({ label, value, setValue,isRequired }) => {
  return (
    <div className="mb-5 last:mb-0">
      <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
        {label}{isRequired==true?<span className="text-14 text-red-500">*</span>:''}
      </label>
      <CustomDatePicker
        placeholder={label}
        date={value!=undefined?new Date(value):undefined}
        setDate={value => setValue(value)}
        
      />
    </div>
  );
};
export default LabelAndDate;
