import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Fade, Modal } from "@mui/material";
import classNames from "classnames";
import "react-phone-input-2/lib/style.css";
import { CloseIcon, SendIcon } from "../../components/common/Icons";
import Button from "../../components/common/Button";
import { StringDropdownList } from "../../components/common/DropdownList";
import CustomDatePicker from "../../components/common/BaseDatePicker";
import { EmployeeData } from "../../components/EmployeeTable/EmployeeTable";
import eyeshow from "../../login/img/eye-show.svg";
import eyehide from "../../login/img/eye-open.svg";
import styles from "./Employees.module.scss";
import Styles from "../../components/common/components.module.scss";
import CommonLanguage from "../../components/common/CommonLanguage";
import { renderDateFormatYMD } from "../../app/services/api";
export type TNameOnlyPopup = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  editedData?: any;
  setEditData?: any;
  savePopupData: any;
  updatePopupData: any;
  title: string;
  isSubmit: boolean;
  setIsSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  uploading: boolean;
  setUploading: React.Dispatch<React.SetStateAction<boolean>>;
  departments: EmployeeData[];
  workgroups: any;
  setErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  errorMsg: string;
  setIsClear: React.Dispatch<React.SetStateAction<boolean>>;
  isClear: boolean;
};
const image_extensions=['image/png',"image/svg+xml",'image/jpg','image/jpeg','image/wepb'];
const validateEmail = (email: any) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};
const genderList = [
  {
    id: 1,
    name: "Male",
  },
  {
    id: 2,
    name: "Female",
  },
  {
    id: 3,
    name: "Other",
  },
];
const EmployeePopup: FC<TNameOnlyPopup> = ({
  isOpen,
  setIsOpen,
  editedData,
  setEditData,
  savePopupData,
  updatePopupData,
  title,
  isSubmit,
  setIsSubmit,
  setUploading,
  uploading,
  departments,
  setErrorMsg,
  errorMsg,
  workgroups,
  isClear
}) => {
  const [activeLang, setActiveLang] = useState<string>("en");
  const [department, setDepartment] = useState<string>("");
  const [workgroup, setWorkgroup] = useState<string>("");

  const [status, setStatus] = useState<boolean>(false);
  const [gender, setGender] = useState<string>("");
  const [name_en, setName_en] = useState<string>("");
  const [name_tc, setName_tc] = useState<string>("");
  const [name_sc, setName_sc] = useState<string>("");
  const [workGroup, setWorkGroup] = useState<string>("");
  const [locationData, setLocation] = useState<string>("");
  const [medical, setMedical] = useState<string>("");
  const [id, setID] = useState<string>("");
  const [jobtitle, setJobtitle] = useState<string>("");
  const [employeeID, setEmployeeID] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [joinedDate, setJoinedDate] = useState<Date | undefined>();
  const [selectedQRInFileName, setSelectedQRInFileName] = useState("No file chosen");
  const [selectedQROutFileName, setSelectedQROutFileName] = useState("No file chosen");
  const [qrInError, setQrInError] = useState("No file chosen");
  const [qrOutError, setQrOutError] = useState("No file chosen");
  const [qrInFile, setQRInFile] = useState<any>({});
  const [qrOutFile, setQROutFile] = useState<any>({});
  const uploadRefQRIn: any = useRef(null);
  const uploadRefQROut: any = useRef(null);
  const clearData = () => {
    setName_en("");
    setName_tc("");
    setName_sc("");
    setWorkGroup("");
    setUploading(false);
    setSelectedQRInFileName("");
    setSelectedQROutFileName("");
    setQRInFile("");
    setMobile("");
    setJoinedDate(undefined);
    setDepartment("");
    setGender("");
    setPassword("");
    setEmail("");
    setStatus(false);
    setLocation("");
    setMedical("");
    setID("");
    setEmployeeID("");
    setWorkgroup("");
    setJobtitle("");
    setQROutFile(null);
    setQRInFile(null);
    setQrInError('');
    setQrOutError('')
  };
  const UploadFileQRIn = useCallback(() => {
    const file = uploadRefQRIn?.current.files[0];
    setQRInFile(file);
    setSelectedQRInFileName(file.name);
    const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
    if (!file) {
      setErrorMsg("Please choose a file");
      return;
    }
    if(!image_extensions.includes(file.type)){
      setQrInError("Please choose a valid file type (.png, .svg, .jpeg, .jpg, .webp)");
      return;
    }
    setQrOutError('')
    for (let i = 0; i < file.length; i++) {
      if (file[i].size > MAX_FILE_SIZE) {
        setErrorMsg("File size exceeds the maximum allowed (100MB).");
        return;
      } else {
        setErrorMsg("");
      }
    }
  }, [setSelectedQRInFileName, setQRInFile, setErrorMsg]);
  const UploadFileQROut = useCallback(() => {
    const file = uploadRefQROut?.current.files[0];
    setQROutFile(file);
    setSelectedQROutFileName(file.name);
    const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
    if (!file) {
      setErrorMsg("Please choose a file");
      return;
    }
    if(!image_extensions.includes(file.type)){
      setQrOutError("Please choose a valid file type (.png, .svg, .jpeg, .jpg, .webp)");
      return;
    }
    setQrOutError('')
    for (let i = 0; i < file.length; i++) {
      if (file[i].size > MAX_FILE_SIZE) {
        setErrorMsg("File size exceeds the maximum allowed (100MB).");
        return;
      } else {
        setErrorMsg("");
      }
    }
  }, [setSelectedQROutFileName, setQRInFile, setErrorMsg]);
  const checkValidation = () => {
    if (
      employeeID &&
      department &&
      // gender &&
      name_en &&
      name_tc &&
      name_sc &&
      // workGroup &&
      jobtitle &&
      // mobile &&
      (id ? true : password) &&
      email &&
      (joinedDate != null || joinedDate != undefined) && qrInError=='' && qrOutError==''
    ) {
      return false;
    } else {
      return true;
    }
  };
  const handleClickQRIn = () => {
    uploadRefQRIn.current.click();
  };
  const handleClickQROut = () => {
    uploadRefQROut.current.click();
  };
  const handleSubmit = async () => {
    setIsSubmit(true);
    setUploading(true);
    const saveData = {
      id: id,
      staffID: employeeID,
      name_en: name_en,
      name_tc: name_tc,
      name_sc: name_sc,
      workGroup: workgroup,
      jobTitle: jobtitle,
      mobile: mobile,
      password: password,
      workEmail: email,
      department_id: department,
      status: status == true ? 1 : 0,
      joinedDate: joinedDate,
      gender: gender,
      location: locationData,
      medicalPlan: medical,
      // workgroup:workgroup
    };
    const check = checkValidation();
    let formSaveData = new FormData();
    formSaveData.append("id", saveData.id);
    formSaveData.append("staffID", saveData.staffID);
    formSaveData.append("name_en", saveData.name_en);
    formSaveData.append("name_tc", saveData.name_tc);
    formSaveData.append("name_sc", saveData.name_sc);
    formSaveData.append("workGroup", saveData.workGroup);
    formSaveData.append("jobTitle", saveData.jobTitle??'');
    formSaveData.append("mobile", saveData.mobile??'');
    formSaveData.append("password", saveData.password);
    formSaveData.append("workEmail", saveData.workEmail??'');
    formSaveData.append("department_id", saveData.department_id);
    formSaveData.append("status", saveData.status.toString());
    formSaveData.append(
      "joinedDate",
      saveData.joinedDate ? renderDateFormatYMD(saveData.joinedDate, "-") : ""
    );
    formSaveData.append("gender", saveData.gender??'');
    formSaveData.append("location", saveData.location??'');
    formSaveData.append("medicalPlan", saveData.medicalPlan??'');
    formSaveData.append("qr_in_code", uploadRefQRIn?.current?.files[0]);
    formSaveData.append("qr_out_code", uploadRefQROut?.current?.files[0]);
    if (check == false) {
      if (editedData) {
        formSaveData.append("_method", "PUT");
        updatePopupData(formSaveData, id);
      } else {
        savePopupData(formSaveData, id);
      }
    } else {
      setUploading(false);
    }
  };
  const switchEyeHandler = (event: any) => {
    var hideimg = eyehide;
    var showimg = eyeshow;
    const type =
      event.target.previousElementSibling.getAttribute("type") == "password"
        ? "text"
        : "password";
    const img =
      event.target.previousElementSibling.getAttribute("type") == "password"
        ? hideimg
        : showimg;
    event.target.previousElementSibling.setAttribute("type", type);
    event.target.setAttribute("src", img);
  };
  useEffect(() => {
    if (editedData) {
      setID(editedData.id);
      setEmployeeID(editedData.employeeID);
      setName_en(editedData.name_en ?? "");
      setName_tc(editedData.name_tc ?? "");
      setName_sc(editedData.name_sc ?? "");
      setStatus(editedData.status == 0 ? false : true);
      setJobtitle(editedData.jobTitle);
      setMobile(editedData.mobile??'');
      setEmail(editedData.workEmail);
      setPassword(editedData.password);
      const genderData = genderList
        ?.find((x) => x.name == editedData.gender)
        ?.id.toString();
      setGender(genderData ? genderData : "");
      setDepartment(editedData.department?.id);
      setJoinedDate(new Date(editedData.joinedDate));      
      setLocation(editedData.location??'');
      setMedical(editedData.medical??'');
      setWorkgroup(editedData.workgroup?.id);
      setSelectedQRInFileName(editedData.qr_in_code);
      setSelectedQROutFileName(editedData?.qr_out_code);
    }
  }, [editedData]);
  useEffect(() => {
    if (!editedData) {
      clearData();
    }
    setUploading(false);
  }, [editedData]);
  useEffect(() => {
    if (isClear==true) {
      clearData();
    }
    setUploading(false);
  }, [isClear]);
  const handleChange = (e: any) => {
    const input = e.target.value;
    const formattedInput = formatPhoneNumber(input);
    setMobile(formattedInput);
  };
  const formatPhoneNumber = (input: string) => {
    // Remove all non-numeric characters
    const numbersOnly = input.replace(/\D/g, "");

    // Insert a "+" at the beginning if not present
    if (numbersOnly[0] !== "+") {
      return `+${numbersOnly}`;
    }

    return numbersOnly;
  };
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };

  return (
    <Modal
      className="project_popup"
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          setIsOpen(false);
          setEditData("");
          setIsSubmit(false);
          clearData();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={isOpen} timeout={500}>
        <div className="xl:max-w-[1000px] max-w-[90%] w-full m-auto">
          <div className="modalContent w-full h-full p-6 lg:p-0 flex justify-center items-center relative z-[999] max-h-[90vh] overflow-hidden">
            <div className="rounded-xl bg-white font-primary w-full">
              <div className="px-2 sm:pl-4 sm:pr-4 py-3 bg-vorpblue flex justify-between rounded-t-lg items-center relative z-[999]">
                <h3 className="text-18 leading-[27px] font-semibold text-white">
                  {title}
                </h3>
                <button
                  key={Math.random() * 100}
                  type="button"
                  aria-label="Close Modal"
                  onClick={() => {
                    setIsOpen(false);
                    setIsSubmit(false);
                    setEditData("");
                    setUploading(false);
                    setErrorMsg("");
                    clearData();
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
              <div className="flex flex-col justify-between w-full px-5 py-2 max-h-[67vh] overflow-auto">
                <CommonLanguage
                  changeLanguage={changeLanguage}
                  activeLang={activeLang}
                />
                <div className="grid sm:grid-cols-2 grid-cols-1 gap-3 px-2">
                  <div>
                    <div
                      className={classNames(
                        activeLang == "en" ? "" : "hidden",
                        "mb-0 last:mb-0 w-full"
                      )}
                    >
                      <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-[0.688rem]">
                        Name<span className=" text-red-500">*</span>
                      </label>
                      <input
                        value={name_en}
                        onChange={(e) => setName_en(e.target.value)}
                        type="text"
                        placeholder="Name"
                        className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                      ></input>
                      <p
                        className={classNames(
                          isSubmit && name_en == "" ? "" : "hidden",
                          "mb-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        Name required
                      </p>
                    </div>
                    <div
                      className={classNames(
                        activeLang == "tc" ? "" : "hidden",
                        "mb-0 last:mb-0 w-full"
                      )}
                    >
                      <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-[0.688rem]">
                        Name<span className=" text-red-500">*</span>
                      </label>
                      <input
                        value={name_tc}
                        onChange={(e) => setName_tc(e.target.value)}
                        type="text"
                        placeholder="Name"
                        className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                      ></input>
                      <p
                        className={classNames(
                          isSubmit && name_tc == "" ? "" : "hidden",
                          "mb-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        Name required
                      </p>
                    </div>
                    <div
                      className={classNames(
                        activeLang == "sc" ? "" : "hidden",
                        "mb-0 last:mb-0 w-full"
                      )}
                    >
                      <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-[0.688rem]">
                        Name<span className=" text-red-500">*</span>
                      </label>
                      <input
                        value={name_sc}
                        onChange={(e) => setName_sc(e.target.value)}
                        type="text"
                        placeholder="Name"
                        className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                      ></input>
                      <p
                        className={classNames(
                          isSubmit && name_sc == "" ? "" : "hidden",
                          "mb-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        Name required
                      </p>
                    </div>
                  </div>

                  <div className="mb-0 last:mb-0 w-full">
                    <label
                      className="text-black2 font-normal body-15 mb-[0.688rem]"
                      htmlFor=""
                    >
                      Workgroup
                    </label>
                    <div className="mt-1">
                      <StringDropdownList
                        state={workgroup}
                        setState={setWorkgroup}
                        placeholder="Workgroup"
                        className="w-full py-[12px] pl-3 md:text-sm text-xs text-black2"
                        dropdownItems={workgroups?.map((dept: any) => ({
                          value: dept.id,
                          label: dept.name_en,
                        }))}
                        textAlign={"text-left"}
                        fontSize="md:text-sm text-xs"
                        border="border-goalinputborder"
                        color="text-black2"
                        paddingLi="py-2"
                      />
                    </div>
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-[0.688rem]">
                      EmployeeID<span className=" text-red-500">*</span>
                    </label>
                    <input
                      value={employeeID}
                      onChange={(e) => setEmployeeID(e.target.value)}
                      type="text"
                      placeholder="EmployeeID"
                      className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                    ></input>
                    <p
                      className={classNames(
                        isSubmit && employeeID === "" ? "" : "hidden",
                        "mb-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      Employee ID is required
                    </p>
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-[0.688rem]">
                      Job Title<span className=" text-red-500">*</span>
                    </label>
                    <input
                      value={jobtitle}
                      onChange={(e) => setJobtitle(e.target.value)}
                      type="text"
                      autoComplete="false"
                      placeholder="Job Title"
                      className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                    ></input>
                    <p
                      className={classNames(
                        isSubmit && jobtitle === "" ? "" : "hidden",
                        "mb-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      Job Title required
                    </p>
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label
                      className="text-black2 font-normal body-15 mb-[0.688rem]"
                      htmlFor=""
                    >
                      Department <span className="text-redPink">*</span>
                    </label>
                    <div className="mt-1">
                      <StringDropdownList
                        state={department}
                        setState={setDepartment}
                        placeholder="Department"
                        className="w-full py-[12px] pl-3 md:text-sm text-xs text-black2"
                        dropdownItems={departments?.map((dept: any) => ({
                          value: dept.id,
                          label:
                            dept.name_en +
                            "" +
                            (dept.department_code
                              ? "(" + dept.department_code + ")"
                              : ""),
                        }))}
                        textAlign={"text-left"}
                        fontSize="md:text-sm text-xs"
                        border="border-goalinputborder"
                        color="text-black2"
                        paddingLi="py-2"
                      />
                    </div>
                    {(isSubmit == true &&
                      (department == "" || department == "0")) == true ? (
                      <span className="text-redPink font-normal text-xs my-1">
                        {"Department is required."}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label
                      className="text-black2 font-normal body-15 mb-[0.688rem]"
                      htmlFor=""
                    >
                      Gender
                    </label>
                    <div className="mt-1">
                      <StringDropdownList
                        state={gender}
                        setState={setGender}
                        placeholder="Gender"
                        className="w-full py-[12px] pl-3 md:text-sm text-xs text-black2"
                        dropdownItems={genderList?.map((location: any) => ({
                          value: location.id,
                          label: location.name,
                        }))}
                        textAlign={"text-left"}
                        fontSize="md:text-sm text-xs"
                        border="border-goalinputborder"
                        color="text-black2"
                        paddingLi="py-2"
                      />
                    </div>
                    {/* {(isSubmit == true && (gender == "" || gender == "0")) ==
                    true ? (
                      <span className="text-redPink font-normal text-xs my-1">
                        {"Gender is required."}
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                  {!id ? (
                    <div className="mb-0 last:mb-0 w-full">
                      <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-[0.688rem]">
                        Password<span className=" text-red-500">*</span>
                      </label>
                      <div className="relative">
                        <input
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          placeholder="Password"
                          className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                        ></input>
                        <img
                          src={eyeshow}
                          alt="eye-open"
                          className={classNames(
                            styles.theeye,
                            "absolute top-1/2 right-4 cursor-pointer w-[22px] h-[16px] object-cover"
                          )}
                          onClick={switchEyeHandler}
                        />
                      </div>

                      <p
                        className={classNames(
                          isSubmit && password === "" ? "" : "hidden",
                          "mb-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        Password required
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mb-0 last:mb-0 w-full">
                    <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-[0.688rem]">
                      Mobile
                    </label>
                    <input
                      onKeyDown={(evt) =>
                        ["e", "E", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      value={mobile}
                      onChange={handleChange}
                      type="tel"
                      placeholder="Mobile"
                      className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                    ></input>
                    {/* <p
                      className={classNames(
                        isSubmit && mobile === "" ? "" : "hidden",
                        "mb-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      Mobile required
                    </p> */}
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-[0.688rem]">
                      Email<span className=" text-red-500">*</span>
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                      placeholder="Email"
                      className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                    ></input>
                    <p
                      className={classNames(
                        isSubmit && email === "" ? "" : "hidden",
                        "mb-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      Email required
                    </p>
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-[0.688rem]">
                      Joined Date<span className=" text-red-500">*</span>
                    </label>
                    <div className="w-full">
                      <CustomDatePicker
                        format="yyyy-mm-dd"
                        date={joinedDate}
                        //minDate={warantyFromDate ? warantyFromDate : new Date()}
                        setDate={setJoinedDate}
                        isFilterSection={false}
                        placeholder="yyyy-mm-dd"
                        iconColor="#858795"
                        borderColor="border-goalinputborder"
                        fontSize="md:text-sm text-xs"
                      />
                    </div>
                    <p
                      className={classNames(
                        isSubmit &&
                          (joinedDate === null || joinedDate === undefined)
                          ? ""
                          : "hidden",
                        "mb-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      Joined Date required
                    </p>
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-[0.688rem]">
                      Location
                      {/* <span className=" text-red-500">*</span> */}
                    </label>
                    <input
                      value={locationData}
                      onChange={(e) => setLocation(e.target.value)}
                      type="text"
                      placeholder="Location"
                      className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                    ></input>
                    {/* <p
                      className={classNames(
                        isSubmit && locationData === "" ? "" : "hidden",
                        "mb-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      Location is required
                    </p> */}
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-[0.688rem]">
                      Medical Plan
                      {/* <span className=" text-red-500">*</span> */}
                    </label>
                    <input
                      value={medical}
                      onChange={(e) => setMedical(e.target.value)}
                      type="text"
                      placeholder="Medical Plan"
                      className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                    ></input>
                    {/* <p
                      className={classNames(
                        isSubmit && medical === "" ? "" : "hidden",
                        "mb-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      Medical plan is required
                    </p> */}
                  </div>                  
                  <div className="">
                    <label
                      className="text-black2 font-normal body-15 mb-[0.688rem]"
                      htmlFor=""
                    >
                      QR IN
                    </label>
                    <div className="flex items-center px-3 py-3.5  border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px] rounded">
                      <input
                        type="file"
                        id="upload"
                        name="file"
                        onChange={UploadFileQRIn}
                        ref={uploadRefQRIn}
                        className="hidden"
                        accept=".jpg,.png,.svg,.jpeg,.webp"
                      />
                      <button
                        onClick={handleClickQRIn}
                        className={classNames(
                          uploading ? "pointer-events-none" : "",
                          "text-15 whitespace-nowrap"
                        )}
                      >
                        Choose file
                      </button>
                      <p
                        className="font-primary text-15 text-black2 ml-3 "
                        style={{ wordBreak: "break-word" }}
                      >
                        {qrInFile?.name
                          ? qrInFile.name
                          : selectedQRInFileName?.split("/").length > 0
                          ? selectedQRInFileName?.split("/")[
                            selectedQRInFileName?.split("/").length - 1
                            ]
                          : selectedQRInFileName}
                      </p>
                    </div>
                    <p
                      className={classNames(
                        isSubmit &&
                          (qrInError)
                          ? ""
                          : "hidden",
                        "mb-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      {qrInError}
                    </p>
                  </div>
                  <div className="">
                    <label
                      className="text-black2 font-normal body-15 mb-[0.688rem]"
                      htmlFor=""
                    >
                      QR OUT
                    </label>
                    <div className="flex items-center px-3 py-3.5  border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px] rounded">
                      <input
                        type="file"
                        id="upload"
                        name="file"
                        onChange={UploadFileQROut}
                        ref={uploadRefQROut}
                        className="hidden"
                        accept=".jpg,.png,.svg,.jpeg,.webp"
                      />
                      <button
                        onClick={handleClickQROut}
                        className={classNames(
                          uploading ? "pointer-events-none" : "",
                          "text-15 whitespace-nowrap"
                        )}
                      >
                        Choose file
                      </button>
                      <p
                        className="font-primary text-15 text-black2 ml-3 "
                        style={{ wordBreak: "break-word" }}
                      >
                        {qrOutFile?.name
                          ? qrOutFile.name
                          : selectedQROutFileName?.split("/").length > 0
                          ? selectedQROutFileName?.split("/")[
                            selectedQROutFileName?.split("/").length - 1
                            ]
                          : selectedQROutFileName}
                      </p>
                    </div>
                    <p
                      className={classNames(
                        isSubmit &&
                          (qrOutError)
                          ? ""
                          : "hidden",
                        "mb-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      {qrOutError}
                    </p>
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label
                      className="text-black2 font-normal body-15 mb-[0.688rem]"
                      htmlFor=""
                    >
                      Status <span className="text-redPink">*</span>
                    </label>
                    <div className={`w-fit h-fit ${Styles.customSwitch}`}>
                      <label
                        htmlFor="status"
                        className="flex items-center cursor-pointer relative"
                      >
                        <input
                          type="checkbox"
                          id="status"
                          name="status"
                          value={`${status}`}
                          checked={status}
                          className="sr-only"
                          onChange={(e) => {
                            setStatus(e.target.checked);
                          }}
                        />
                        <div className="toggleBg bg-[#EFF2F5] w-10 h-6 sm:h-[30px] sm:w-12 rounded-full after:absolute after:content-[''] after:top-1 after:left-1 after:bg-white after:rounded-full  after:h-4 after:w-4  after:sm:h-[22px] after:sm:w-[22px] transition-all duration-300 after:transition-all after:duration-300"></div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="px-2 pb-5 pt-2">
                <p
                  className={classNames(
                    isSubmit || errorMsg ? "" : "hidden",
                    "mb-5 font-poppins text-12 text-redPink"
                  )}
                >
                  {errorMsg}
                </p>
                <div className="flex flex-wrap">
                  <div className="flex px-2 justify-center items-center">
                    <Button
                      type="button"
                      label={
                        <span className="flex items-center text-12 leading-[18px]">
                          <SendIcon className="mr-[10px] group-hover:opacity-0 transition-all duration-300" />
                          {editedData ? "Update" : "Save"}
                        </span>
                      }
                      onClick={() => {
                        handleSubmit();
                      }}
                      variant="no-outline"
                      size="lg"
                      customClass={classNames(
                        uploading ? " pointer-events-none opacity-50" : "",
                        "hover:bg-vorpblue mr-2 py-2 lg:py-2 px-3 custom-button-size"
                      )}
                    />

                    <Button
                      type="button"
                      label={
                        <span className="flex items-center text-12 leading-[18px] ">
                          <CloseIcon
                            width={14}
                            height={14}
                            className="mr-[10px] [&_path]:fill-graydark"
                          />
                          Cancel
                        </span>
                      }
                      onClick={() => {
                        setIsOpen(false);
                        setIsSubmit(false);
                        setEditData("");
                        setErrorMsg("");
                        clearData();
                        setUploading(false);
                        setIsSubmit(false);
                      }}
                      variant="no-outline"
                      size="lg"
                      customClass=" mr-2 py-2 lg:py-2 px-3 bg-offwhite text-black2 border-none custom-button-size"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default EmployeePopup;
