import { Dispatch, FC, SetStateAction, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon, CalendarIcon2 } from "./Icons";
//import { weekday } from "../../utils/constants";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";

interface IDatePicker {
  placeholder: string;
  date?: Date;
  setDate: Dispatch<SetStateAction<Date | undefined>>;
  className?: string;
  isFilterSection?: boolean;
  customPadding?: string;
  iconColor?: string;
  minData?: Date;
  isProject?: boolean;
  isPlaceHolder?: string;
  isProjectCalendarIcon?: boolean;
}

// interface ICustomDateInputUi {
//   value?: string;
//   onClick?: () => void;
//   label: string;
//   customPadding?: string;
//   iconColor?: string;
// }

const CustomDatePicker: FC<IDatePicker> = ({
  placeholder,
  isPlaceHolder,
  isProject,
  date,
  setDate,
  isFilterSection = true,
  customPadding = "",
  iconColor,
  minData,
  isProjectCalendarIcon,
}) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [expirydate, setexpiryDate] = useState<Date|undefined>(undefined);
  function getDayName(cusdate = date, locale = "en-US") {
    return cusdate?cusdate.toLocaleDateString(locale, { weekday: "long" }):'';
  }
  const renderDateFormatFull = (date: Date|undefined) => {
    if(date){
      var year = date.getFullYear();
      var day = date.getDate();
      return (
        getDayName() +
        " , " +
        (day.toString().length < 2 ? "0" + day : day) +
        " " +
        monthNames[date.getMonth()] +
        " " +
        year
      );
    }else{
      return ''
    }
    
  };

  return (
    <DatePicker
      minDate={minData ? minData : null}
      selected={date}
      dateFormat="d MMM yyyy"
      showMonthDropdown
      showYearDropdown
      onChange={(prev: Date) => setDate(prev)}
      customInput={
        <button
          className={classNames(
            isProject ? "h-[48px]" : "h-[48px]",
            "border rounded border-vorpgraylight flex justify-between items-center w-full  text-[13px] sm:text-14 leading-[2.2] text-graydark"
          )}
        >
          <span
            className={twMerge(
              isProject ? "py-1" : "py-2",
              "w-full h-full px-[10px] text-left text-black2 flex items-center",
              customPadding
            )}
          >
            {isProject && date === undefined ? (
              isPlaceHolder ? (
                <span className="text-graydark">{isPlaceHolder}</span>
              ) : (
                "Select Date"
              )
            ) : (
              renderDateFormatFull(date ? date : expirydate)
            )}
          </span>
          <div
            className={twMerge(
              isProject ? "" : "border-l border-[#CCDAE4]",
              " px-[10px] py-[6px]",
              customPadding
            )}
          >
            {isProjectCalendarIcon ? (
              <CalendarIcon2 width={24} height={24} />
            ) : (
              <CalendarIcon width={24} height={24} />
            )}
          </div>
        </button>
      }
    />
  );
};

export default CustomDatePicker;
