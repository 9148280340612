import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Listbox } from "@headlessui/react";
import { ChevronDown, RoundedCloseIcon } from "./Icons";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { usePopper } from "../../hooks/usePopper";
import { baseObj } from "../../utils/constants";
import { Cell } from "@tanstack/react-table";
// import { makeStyles, Tooltip } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";

import DebounceSearchInput from "../Tables/DebounceSearchInput";
import { toast } from "react-toastify";

export type TOptions = {
  label: string;
  value: any;
  isNew?: boolean;
  id?: number;
  name?: string;
  flag?: string;
};
interface IMultipleSelectBox<P = unknown> {
  setTechList?: Dispatch<SetStateAction<any[]>>;
  techList?: any[];
  setData?: Dispatch<SetStateAction<baseObj[]>>;
  data?: baseObj[];
  state: P[];
  setState: Dispatch<SetStateAction<P[]>>;
  setSelected?: any;
  options: TOptions[];
  className?: string;
  cell?: Cell<unknown, unknown>;
  showAll?: boolean;
  isObject?: boolean;
  createData?: any;
  isProject?: boolean;
  isCreate?: boolean;
  placeholder?: string;
}
const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));
const MultipleTagsBoxFactory = <P extends unknown>() => {
  const MultipleSelectBox: FC<IMultipleSelectBox<P>> = ({
    state,
    setState,
    options,
    className = "",
    data,
    setData,
    cell,
    showAll,
    isObject,
    createData,
    setSelected,
    isProject,
    setTechList,
    techList,
    isCreate = true,
    placeholder,
  }) => {
    // const [dd, setDD] = useState<string[]>();
    // const [datas, setDatas] = useState<string[]>();
    const [newTag, setNewTag] = useState<string>("");
    const [globalSearch, setGlobalSearch] = useState<string>("");
    const [optionData, setOptionData] = useState<TOptions[]>(options);
    const classes = useStyles();
    const changeState = (event: any) => {
      if (tagInput.current) {
        tagInput.current.value = "";
      }

      var eventObj: any = [];
      options.map((opt: any) => {
        if (event.includes(opt.label)) {
          eventObj.push({
            id: opt.value,
            name: opt.label,
          });
        }
      });
      if (event == "All") {
        setState(event?.filter((x: any) => x == "All"));
      } else {
        setState(event);
      }
      if (setSelected) {
        if (event == "All") {
          setSelected(eventObj.filter((x: any) => x.name == "All"));
        } else {
          setSelected(eventObj);
        }
      }
      if (setData) {
        setData((prev: baseObj[]) => {
          return prev.map((data: baseObj, index: number) => {
            if (index === Number(cell?.row.id)) {
              if (cell) {
                if (isObject) {
                  return { ...data, [cell.column.id]: eventObj };
                } else {
                  return { ...data, [cell.column.id]: event };
                }
              } else {
                return { ...data };
              }
            }
            return data;
          });
        });
      }
    };

    const removeFromSelection = (value: P) => {
      var filterData: any = [];
      var filterDataIDs: any = [];
      if (value == "All") {
        setState([]);
      } else {
        setState((prev) => {
          filterData = prev.filter((item) => item !== value);
          return filterData;
        });
      }
      options.map((opt: any) => {
        if (filterData.includes(opt.label)) {
          filterDataIDs.push({
            id: opt.value,
            name: opt.label,
          });
        }
      });
      if (setSelected) {
        if (value == "All") {
          setSelected(filterDataIDs.filter((x: any) => x.name == "All"));
        }
        else{
          setSelected(filterDataIDs);
        }
      }
      if (setData) {
        setData((prev: baseObj[]) => {
          return prev.map((data: baseObj, index: number) => {
            if (index === Number(cell?.row.id)) {
              if (cell) {
                return { ...data, [cell.column.id]: filterData };
              } else {
                return { ...data };
              }
            }
            return data;
          });
        });
      }
    };

    const mainContainer = useRef<HTMLDivElement>(null);
    const tagInput = useRef<HTMLInputElement>(null);
    const [width, setWidth] = useState<number>();
    let [trigger, container] = usePopper({
      placement: "bottom-end",
      strategy: "fixed",
      modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
      onFirstUpdate: () => {
        tagInput?.current?.focus();
      },
    });
    const changeTagInput = (value: string) => {
      setTimeout(function () {
        setNewTag(value);
        if (value !== "") {
          var filter = options.filter((x) => x.label.startsWith(value));
          if (filter.length <= 0) {
            var newData = {
              label: value,
              value: "0",
              isNew: true,
            };
            filter.push(newData);
            setOptionData(filter);
          } else {
            setOptionData(filter);
          }
        } else {
          setOptionData(options);
        }
      }, 2000);
    };
    useEffect(() => {
      setNewTag(globalSearch);
      if (globalSearch !== "") {
        var filter = options.filter((x) =>
          x?.label
            ?.toLocaleLowerCase()
            .startsWith(globalSearch.toLocaleLowerCase())
        );
        if (filter.length <= 0) {
          var newData = {
            label: globalSearch,
            value: "0",
            isNew: true,
          };
          filter.push(newData);
          setOptionData(filter);
        } else {
          setOptionData(filter);
        }
      } else {
        setOptionData(options);
      }
    }, [globalSearch]);

    useEffect(() => {
      setOptionData(options);
    }, [options]);

    useEffect(() => {
      // if (mainContainer != undefined) {
      setWidth(mainContainer.current?.clientWidth as number);
      const resizer: any = window.addEventListener("resize", () =>
        setWidth(mainContainer.current?.clientWidth as number)
      );
      return () => window.removeEventListener("resize", resizer);
      // }
    }, [mainContainer]);

    const createTagData = (label: string) => {
      if (createData) {
        createData({ type_name: label })?.then((res: any) => {
          var result = res?.data?.data;
          if (res.data) {
            var newTagData = {
              id: result?.id,
              value: result?.name,
            };
            if (setTechList) {
              if (techList) {
                setTechList([
                  ...techList,
                  { id: result?.id, label: result?.name },
                ]);
              }
            }

            if (state) {
              if (state.find((x) => x == "All")) {
                setState(state.filter((x) => x != "All"));
              } else {
                setState([...state, result?.name]);
              }
            } else {
              setState([result?.name]);
            }
          }
          if (res.error) {
            toast(res?.error?.data?.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "success",
            });
          }
        });
      }
      if (tagInput?.current) {
        tagInput.current.value = "";
      }
      setNewTag("");
      setGlobalSearch("");
    };
    return (
      <div className="relative w-full h-full" ref={mainContainer}>
        <Listbox value={state} onChange={(e) => changeState(e)} multiple>
          {({ open }) => (
            <>
              <Listbox.Button
                id="commonTagDropdown"
                ref={trigger}
                onClick={() => {
                  tagInput?.current?.focus();
                }}
                className="w-full h-full"
              >
                <div
                  className={classNames(
                    "custom-padding h-full border border-goalinputborder  px-3 w-full rounded-4 text-14 leading-[21px] text-graydark",
                    className,
                    showAll == true ? "py-3" : ""
                  )}
                >
                  {state?.length == 0 ? (
                    <div className="flex justify-between items-center w-full h-full">
                      <div className="flex w-full">
                        <DebounceSearchInput
                          setState={setGlobalSearch}
                          debounce={800}
                        >
                          {(setState) => (
                            <input
                              ref={tagInput}
                              defaultValue=""
                              type="text"
                              placeholder={
                                placeholder
                                  ? placeholder
                                  : isProject
                                  ? `Select Tag`
                                  : "Select Tag"
                              }
                              className="focus:outline-none sm:text-12 md:text-14 text-black2 w-full"
                              onChange={(e) => setState(e.target.value)}
                            />
                          )}
                        </DebounceSearchInput>
                      </div>
                      <ChevronDown color="#464E5F" />
                    </div>
                  ) : (
                    <div className="flex items-center gap-y-1 h-full flex-wrap">
                      {showAll == true ? (
                        state?.find((x) => x == "All") ? (
                          state
                            .filter((x) => x == "All")
                            ?.map((s, key: number) => {
                              return (
                                <div
                                  className="flex"
                                  key={Math.random() * 1000}
                                >
                                  <span
                                    key={Math.random() * 100}
                                    className="py-[3px] mr-[6px] sm:mr-[10px] px-[10px] border border-[#D8D8D8] rounded-full flex justify-between items-center text-10 sm:text-12 md:text-14 leading-5 text-black2"
                                  >
                                    {s as string}
                                    <span
                                      aria-label="remove-item-btn"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        removeFromSelection(s);
                                      }}
                                    >
                                      <RoundedCloseIcon className="ml-[10px]" />
                                    </span>
                                  </span>
                                </div>
                              );
                            })
                        ) : (
                          state?.map((s, key: number) => {
                            return (
                              <div className="flex" key={Math.random() * 1000}>
                                <span
                                  key={Math.random() * 100}
                                  className="py-[3px] mr-[6px] sm:mr-[10px] px-[10px] border border-[#D8D8D8] rounded-full flex justify-between items-center text-10 sm:text-12 md:text-14 leading-5 text-black2"
                                >
                                  {s as string}
                                  <span
                                    aria-label="remove-item-btn"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      removeFromSelection(s);
                                    }}
                                  >
                                    <RoundedCloseIcon className="ml-[10px]" />
                                  </span>
                                </span>
                              </div>
                            );
                          })
                        )
                      ) : (
                        <div className="flex">
                          <span
                            key={Math.random() * 100}
                            className="py-[3px] mr-[6px] sm:mr-[10px] px-[10px] border border-[#D8D8D8] rounded-full flex justify-between items-center text-10 sm:text-12 md:text-14 leading-5 text-black2"
                          >
                            {state[0] as string}
                            <span
                              aria-label="remove-item-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeFromSelection(state[0]);
                              }}
                            >
                              <RoundedCloseIcon className="ml-[10px]" />
                            </span>
                          </span>
                        </div>
                      )}
                      {state?.length > 0 && !showAll && (
                        <>
                          <Tooltip
                            arrow
                            classes={{ tooltip: classes.tooltip }}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            placement="left"
                            title={
                              <>
                                <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                                  {[...state]
                                    .splice(0, state?.length)
                                    .map((tname, index: number) => (
                                      <span className="block" key={index}>
                                        {tname as string}
                                      </span>
                                    ))}
                                </div>
                              </>
                            }
                          >
                            <span className="font-semibold cursor-pointer">
                              +
                            </span>
                          </Tooltip>
                          <div className="relative hover:cursor-pointer pl-1"></div>
                        </>
                      )}
                      <DebounceSearchInput
                        setState={setGlobalSearch}
                        debounce={800}
                      >
                        {(setState) => (
                          <input
                            ref={tagInput}
                            type="text"
                            placeholder=""
                            className="focus:outline-none text-10 sm:text-12 text-black2 w-full"
                            onChange={(e) => setState(e.target.value)}
                          />
                        )}
                      </DebounceSearchInput>
                    </div>
                  )}
                </div>
              </Listbox.Button>
              <Listbox.Options as="div" className="w-full">
                <div
                  className={classNames(
                    "absolute top-[110%] left-0 w-full bg-white rounded-4 z-10 max-h-[280px] overflow-y-auto list-none",
                    { "border border-graydark": open }
                  )}
                  ref={container}
                  style={{ width }}
                >
                  {optionData?.map((option, index: number) => {
                    
                    return (
                      <div
                        key={index}
                        className={`${
                          state?.toString()
                            ? state?.toString().includes("All")
                              ? option.label == "All"
                                ? "opacity-100 pointer-events-auto"
                                : "opacity-50 pointer-events-none"
                              : ""
                            : ""
                        } w-full hover:bg-vorpblue hover:text-white px-3 py-2 hover:cursor-pointer text-12 sm:text-14 text-graydark ${
                          state?.toString().includes("All")
                            ? option.label == "All"
                              ? "bg-vorpblue text-white"
                              : ""
                            : state?.find(x=>x==option.label)//state?.toString().includes(option.label)
                            ? "bg-vorpblue text-white"
                            : ""
                        }`}
                      >
                        <Listbox.Option
                          key={index}
                          value={option.label}
                          className={classNames(
                            option?.isNew == true ? "hidden" : ""
                          )}
                        >
                          {option?.label as string}
                        </Listbox.Option>
                      </div>
                    );
                  })}
                  {isCreate == false ? (
                    ""
                  ) : (
                    <div
                      className={classNames(
                        optionData[optionData.length - 1]?.isNew == true
                          ? "items-center mr-2 flex text-12 text-graydark px-3 py-2"
                          : "hidden"
                      )}
                    >
                      <button
                        onClick={() => createTagData(newTag)}
                        className="px-2 py-1 bg-vorpblue text-white mr-2 rounded-md"
                      >
                        Create
                      </button>
                      <span>{newTag as string}</span>
                    </div>
                  )}
                </div>
              </Listbox.Options>
            </>
          )}
        </Listbox>
      </div>
    );
  };
  return MultipleSelectBox;
};

export const CommonTagDropdown = MultipleTagsBoxFactory<string>();
