import { twJoin } from "tailwind-merge";
import { useState } from "react";
import JobDepartment, { JobDepartmentType } from "../components/JobDepartment";
import SectionList from "../components/job-category/SectionList";
import DepartmentSectionList from "../components/job-category/DepartmentSectionList";

const JobDepartmentPage = () => {
  const [data, setData] = useState<JobDepartmentType>({
    id: 0,
    title_en: "",
    title_sc: "",
    title_tc: "",
  });
  const [showList, setShowList] = useState<boolean>(true);

  return (
    <div
      className={twJoin(
        "text-left bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pb-8 sm:pb-9 "
      )}
    >
      <DepartmentSectionList/>
    </div>
  );
};
export default JobDepartmentPage;
