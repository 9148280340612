import { useEffect, useState } from "react";
import DebounceSearchInput from "../../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../../components/common/Icons";
import { NumberDropdownList } from "../../../components/common/DropdownList";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { baseObj } from "../../../utils/constants";
import { templateList } from "../../../active-time-report/views/ActiveTimeReport";
import MediaReportTable from "./JobApplicationTable";
import { useFetch_applicationQuery, useFetch_application_infoMutation } from "../../../app/services/job/jobApplication";
import JobApplication, { JobApplicationType } from "../JobApplication";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { endpointUrl } from "../../../app/services/api";
import { getAuthUser } from "../../../app/services/dashboard";

const JobApplicationSectionList = () => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });
  const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
    false
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [data, setData] = useState<baseObj[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [tempList, setTempList] = useState<any>(templateList);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [activeLang, setActiveLang] = useState<string>("en");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [viewCols, setCols] = useState<any>([]);
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isSave, setIsSave] = useState<boolean>(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [detailData, setDetailData] = useState<JobApplicationType>({
    id: 1,
    job_id: 1,
    job_opening: {},
    source_type: 1,
    expected_salary: "",
    date_of_availability: "",
    reference_in_pre_offer_notice: "",

    category: "",
    first_name: "",
    last_name: "",
    first_name_chinese: "",
    last_name_chinese: "",
    sex: 1,
    date_of_birth: "",
    phone_number: "",
    email: "",
    address: "",
    is_permanent_resider_hk: false,
    hk_id_passport_no: "",
    chinese_commercial_code: "",
    is_right_of_abode_hk: false,
    require_hk_work_visa: false,
    emergency_contact_name: "",
    emergency_contact_relation: "",
    emergency_contact_call: "",

    can_overtime: false,
    can_overtime_short_notice: false,
    can_rotating_shift: false,
    can_work_at_night: false,
    can_night_shift: false,
    can_sunday_or_statutory_holiday: false,
    have_any_criminal_offence: false,
    involved_bankruptcy_proceedings: false,
    issue_ara_permit: false,
    have_rejected_airport_area_permit: false,

    working_experiences: [],
    professional_qualifications: [],
    academic_background: "",

    educations: [],
    languages: [],
    english: "",
    cantonese: "",
    mandarin: "",
    other_language: "",
    other_language_name: "",

    friend_employed_by_company: "",
    related_to_sats_hk: "",
    have_declare: false,
    have_authorize: false,
    have_read: false,
    application_signature_path: undefined,
  });
  const [showList, setShowList] = useState<boolean>(true);
  const [isView, setIsView] = useState<boolean>(false);
  const [getApplicationInfo] = useFetch_application_infoMutation();
  const { data: application } = useFetch_applicationQuery({
    page: currentPage,
    limit: pagination.pageSize,
    search: globalSearch,
    sort: sorting ? sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(',') : '',
  });
  useEffect(() => {
    if (application) {
      setData(application?.data);
      setItemCount(application?.total);
    }
  }, [application]);
  const clear_data = () => {
    setDetailData({
      id: 1,
      job_id: 1,
      job_opening: {},
      source_type: 1,
      expected_salary: "",
      date_of_availability: "",
      reference_in_pre_offer_notice: "",

      category: "",
      first_name: "",
      last_name: "",
      first_name_chinese: "",
      last_name_chinese: "",
      sex: 1,
      date_of_birth: "",
      phone_number: "",
      email: "",
      address: "",
      is_permanent_resider_hk: false,
      hk_id_passport_no: "",
      chinese_commercial_code: "",
      is_right_of_abode_hk: false,
      require_hk_work_visa: false,
      emergency_contact_name: "",
      emergency_contact_relation: "",
      emergency_contact_call: "",

      can_overtime: false,
      can_overtime_short_notice: false,
      can_rotating_shift: false,
      can_work_at_night: false,
      can_night_shift: false,
      can_sunday_or_statutory_holiday: false,
      have_any_criminal_offence: false,
      involved_bankruptcy_proceedings: false,
      issue_ara_permit: false,
      have_rejected_airport_area_permit: false,

      working_experiences: [],
      professional_qualifications: [],
      academic_background: "",

      educations: [],
      languages: [],
      english: "",
      cantonese: "",
      mandarin: "",
      other_language: "",
      other_language_name: "",

      friend_employed_by_company: "",
      related_to_sats_hk: "",
      have_declare: false,
      have_authorize: false,
      have_read: false,
      application_signature_path: undefined,
    })
  }
  const handle_submit = async (data: JobApplicationType) => {
    const formData = new FormData();
    const url = data?.id ? 'update-job-openings' : 'create-job-openings';
    var getUser = getAuthUser();
    await axios(endpointUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
      },
      data: formData,
    })
      .then((res: any) => {
        if (res?.data?.status == true) {
          getApplicationInfo({
            page: 1,
            limit: 20,
            search: ''
          }).then((res: any) => {
            setData(res?.data?.data);
            setItemCount(res?.data?.total);
          })
          toast(res?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
          setShowList(true);
        } else {
          setErrorPopup(true);
          setMessage(res?.data?.message);
        }
        setIsSave(false);
      })
      .catch((err) => {
        setErrorPopup(true);
        setMessage(err?.response?.data?.message);
      });
  }
  return (
    <div className="bg-white rounded-xl pt-4 pb-4 sm:pt-4 sm:pb-9 px-4 w-full h-full font-primary">
      {showList == false ? <div>
        <JobApplication
          isSave={isSave}
          handle_submit={handle_submit}
          initialData={detailData}
          setValue={setDetailData}
          setShowList={setShowList}
          setIsView={setIsView}
          isView={isView}
        />
      </div> : <>
        <div className="flex flex-wrap justify-between items-center mb-1">
          <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] my-1">
            <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
              {setState =>
                <div className="flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                  <input
                    type="text"
                    placeholder="Search"
                    className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                    onChange={e => setState(e.target.value)}
                  />
                  <SearchIcon className="mr-2" />
                </div>}
            </DebounceSearchInput>
          </div>
          <div className="flex items-center 3xl:ml-3">
              <p className="xl:text-sm text-12 mr-2 text-black2 font-primary">
                DISPLAY
              </p>
              <NumberDropdownList
                state={pagination.pageSize}
                setState={value =>
                  setPagination(prev => ({
                    ...prev,
                    pageSize: value as number
                  }))}
                dropdownItems={new Array(5).fill(1).map((_, index: number) => {
                  const count = (index + 1) * 20;
                  return {
                    value: count,
                    label: String(count)
                  };
                })}
                className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                textAlign="text-left pl-3"
                paddingClass="py-[2px]"
                placeholder="All"
              />
            </div>
        </div>

        <div>
          <div className="text-left">
            <MediaReportTable
              // columnsData={columnsData}
              isLayoutControlOpen={isLayoutControlOpen}
              setIsLayoutControlOpen={setIsLayoutControlOpen}
              globalSearch={globalSearch}
              setGlobalSearch={setGlobalSearch}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              pagination={pagination}
              data={data}
              setData={setData}
              setPagination={setPagination}
              hiddenFields={hiddenFields ? hiddenFields : []}
              setHiddenFields={setHiddenFields}
              checkManagement={true}
              total={itemCount}
              localModuleId={localModuleId}
              // visArray={visArray}
              templateList={tempList}
              setCurrentView={() => 1}
              setCols={setCols}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              setDetailData={setDetailData}
              setShowList={setShowList}
              sorting={sorting}
              setSorting={setSorting}
              setIsView={setIsView}
              isView={isView}
            />
          </div>
        </div>
      </>}
    </div>
  );
};
export default JobApplicationSectionList;
