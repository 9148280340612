import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useAddTemplateMutation,
  useDestoryTemplateMutation,
  useMultiDeleteTemplateMutation,
  addTemplate,
  destoryTemplate,
  multiDeleteTemplate,
  getSpecificTemplate,
  getByModuleTemplate,
  useGetSpecificTemplateQuery,
  useGetByModuleTemplateQuery,
  addTempleData,
} from "../../app/services/template";
import { CloseIcon, SaveIcon, SearchIcon, SelectedIcon } from "../common/Icons";
// import { Modal } from "@material-ui/core";
import { Modal, Fade } from "@mui/material";

import { Column, Table } from "@tanstack/react-table";
import Button from "../common/Button";
import DraggableCard from "./DraggableCard";
import Styles from "./Tables.module.scss";
import { F } from "lodash/fp";
import { ToastContainer, toast } from "react-toastify";
import classNames from "classnames";

interface ITableLayoutEditor {
  isOpen: boolean;
  isWorkingShift?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsWorkingShift?: Dispatch<SetStateAction<boolean>>;
  table: Table<unknown>;
  label?: string;
  unDraggableFields: string[];
  setColumnOrder: Dispatch<SetStateAction<string[]>>;
  setColumnVisibility: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  saveTemplateData: any;
  localModuleId?: number;
  setCols?: any;
  setSuccessPopup?: any;
  setMessage?: any;
  setErrorPopup?: any;
  setGetPlaceHolder?: any;
  setIsTemplate?: Dispatch<SetStateAction<boolean>>;
  setCurrentView?: any;
  unShowFields?: string[];
}

interface IColumnVisibilityToggler {
  column: Column<unknown, unknown>;
  selectedColumns: Column<unknown, unknown>[];
  setSelectedColumns: Dispatch<SetStateAction<Column<unknown, unknown>[]>>;
  unShowFields?: string[];
}

const ColumnVisibilityToggler: FC<IColumnVisibilityToggler> = ({
  column,
  setSelectedColumns,
  selectedColumns,
  unShowFields,
}) => {
  const isSelected =
    selectedColumns.filter((col) => col.id === column.id).length > 0;
  const label = column.columnDef.header;
  return label ? (
    <div className=" mb-3" key={column.id}>
      <label
        htmlFor={column.id}
        className={classNames(
          unShowFields?.includes(column.id)
            ? "pointer-events-none opacity-50"
            : "",
          "relative max-h-[24px] flex items-center hover:cursor-pointer"
        )}
      >
        <input
          type="checkbox"
          id={column.id}
          className={classNames(
            unShowFields?.includes(column.id)
              ? "pointer-events-none opacity-50"
              : "",
            " checked:bg-vorpblue w-3 h-3 sm:w-4 sm:h-4 p-3 border-none outline-none appearance-none bg-offwhite rounded hover:cursor-pointer"
          )}
          checked={isSelected || unShowFields?.includes(column.id)}
          onChange={() => {
            if (isSelected) {
              setSelectedColumns((prev) =>
                prev.filter((col) => col.id !== column.id)
              );
            } else {
              setSelectedColumns((prev) => [...prev, column]);
            }
          }}
        />
        {(isSelected || unShowFields?.includes(column.id)) && (
          <SelectedIcon className="absolute top-2 left-1.5 right-0" />
        )}
        <p className="text-12 sm:text-14 leading-5 text-black2 ml-3">
          {
            //@ts-ignore
            typeof label === "function" ? label() : label
          }
        </p>
      </label>
    </div>
  ) : (
    <div></div>
  );
};

const TableLayoutEditor: FC<ITableLayoutEditor> = ({
  isOpen,
  setIsOpen,
  setIsWorkingShift,
  isWorkingShift,
  label,
  table,
  setColumnOrder,
  setColumnVisibility,
  unDraggableFields,
  saveTemplateData,
  localModuleId,
  setCols,
  setIsTemplate,
  setErrorPopup,
  setSuccessPopup,
  setMessage,
  setGetPlaceHolder,
  setCurrentView,
  unShowFields,
}) => {
  return (
    <>
      <Modal
        open={isOpen}
        style={{
          background: "rgb(0 0 0 / 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            // Set 'open' to false, however you would do that with your particular code.
            setIsOpen(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus={true}
      >
        <Fade in={isOpen} timeout={500}>
          <div className="modalContent max-w-[1000px] w-full m-auto px-4 py-8 xl:px-0 xl:py-0">
            <TableLayoutEditorBody
              setCurrentView={setCurrentView}
              isOpen={isOpen}
              unDraggableFields={unDraggableFields}
              setColumnOrder={setColumnOrder}
              setIsOpen={setIsOpen}
              isWorkingShift={isWorkingShift}
              setIsWorkingShift={setIsWorkingShift}
              label={label}
              table={table}
              setColumnVisibility={setColumnVisibility}
              saveTemplateData={saveTemplateData}
              localModuleId={localModuleId}
              setCols={setCols}
              setIsTemplate={setIsTemplate}
              setSuccessPopup={setSuccessPopup}
              setErrorPopup={setErrorPopup}
              setMessage={setMessage}
              setGetPlaceHolder={setGetPlaceHolder}
              unShowFields={unShowFields}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
};

const TableLayoutEditorBody: FC<ITableLayoutEditor> = ({
  setIsOpen,
  isWorkingShift,
  setIsWorkingShift,
  isOpen,
  label,
  table,
  setColumnOrder,
  setColumnVisibility,
  saveTemplateData,
  localModuleId,
  setCols,
  setIsTemplate,
  setSuccessPopup,
  setMessage,
  setErrorPopup,
  setGetPlaceHolder,
  setCurrentView,
  unShowFields,
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [nameText, setNameText] = useState<string>("");

  const originalColumns = table
    .getAllLeafColumns()
    .filter(({ id }) => id !== "selection");
  const [columns, setColumns] =
    useState<Column<unknown, unknown>[]>(originalColumns);
  const [selectedColumns, setSelectedColumns] = useState<
    Column<unknown, unknown>[]
  >(columns?.filter((column) => unShowFields?.includes(column.id)));
  const overflowContainer1 = useRef<HTMLDivElement>(null);
  const overflowContainer2 = useRef<HTMLDivElement>(null);
  const [addTemplate] = useAddTemplateMutation();
  // const {} = templateList
  useEffect(() => {
    [overflowContainer1, overflowContainer2].forEach((element) => {
      if (!element?.current || Number(element.current?.scrollTop) <= 20) {
        element.current?.previousElementSibling?.classList.add("hidden");
      }
    });
  }, []);

  useEffect(() => {
    const searchValue = searchText.toLowerCase().trim();
    if (searchText !== "") {
      setColumns((prev) =>
        prev.filter((column) =>
          column.columnDef.header
            ?.toString()
            .toLowerCase()
            .includes(searchValue)
        )
      );
    } else {
      setColumns(originalColumns);
    }
  }, [searchText]);

  const saveLayout = useCallback(() => {
    const cols = selectedColumns.map((col) => col.id);
    // let fasleVisible:any =[];
    const visibleColumns: { [key: string]: boolean } = {};
    originalColumns.forEach((col) => {
      if (cols.length === 0) {
        visibleColumns[col.id] = true;
      } else {
        if (!cols.includes(col.id)) {
          // fasleVisible.push(col.id);
          visibleColumns[col.id] = false;
        }
      }
    });
    try {
      var currentDate = new Date().toISOString();
      var newTemplate = {
        id: 0,
        name: isWorkingShift ? nameText : "View" + currentDate,
        module_id: localModuleId,
        viewColumns: unShowFields ? [...unShowFields, ...cols] : cols,
      };


      addTemplate(newTemplate)
        .unwrap()
        .then((payload: any) => {
          if (payload.status) {
            setIsOpen(false);
            if (setIsWorkingShift) {
              setIsWorkingShift(false);
            }

            if (setIsTemplate) {
              setIsTemplate(true);
            }
            toast("Create Successfully!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "success",
            });

            if (setSuccessPopup) {
              setSuccessPopup(true);
            }
            if (!isOpen) {
              setMessage(payload ? [payload?.message] : "Successfully Created");
            }
            // setGetPlaceHolder(payload?.data)
            if (setCols) {
              setCols(payload?.data);
              // setCols(payload?.data?.view_columns)
            }
          }
          if (setCurrentView) {
            setCurrentView(payload?.data?.id);
          }
        })
        .catch((error: any) => {
          if (isOpen) {
            if (setErrorPopup) {
              setErrorPopup(true);
              setMessage(["Something went wrong!Trying again."]);
            }
          }
        });

      if (saveTemplateData) {
        saveTemplateData(visibleColumns);
      }
      setColumnVisibility(visibleColumns);
      setColumnOrder(["selection", ...cols]);
      setIsOpen(false);
    } catch (error) {
    }
  }, [originalColumns, selectedColumns, setColumnOrder, setColumnVisibility]);

  const handleScroll = useCallback((e: any) => {
    const bottom =
      Math.ceil(
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
      ) < 20;
    if (bottom) {
      e.target.previousElementSibling.classList.add("hidden");
    } else {
      e.target.previousElementSibling.classList.remove("hidden");
    }
  }, []);

  const handleSelect = useCallback(() => {
    // setSelectedColumns(columns.map((col: any, index: number) => col))
    const result = columns.filter(
      (cols: any) => !cols.id.toLowerCase().startsWith("none")
    );
    const result2 = result.filter(
      (cols: any) => !cols.id.toLowerCase().startsWith("uniqe")
    );
    const result3 = result2.filter(
      (cols: any) => !cols.id.toLowerCase().startsWith("action")
    );
    const result4 = result3.filter(
      (cols: any) => cols.id.toLowerCase() !== "lasted-no"
    );
    const result6 = result4.filter(
      (cols: any) => cols.id.toLowerCase() !== "lasted"
    );
    setSelectedColumns([...result6]);
  }, [columns, setSelectedColumns]);

  return (
    // <div className="modalContent max-w-[1000px] w-full m-auto px-4 py-8 xl:px-0 xl:py-0">
    <>
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <div className="rounded-xl overflow-hidden font-primary">
        <div className="px-6 sm:px-8 md:px-10 py-4 sm:py-6 bg-vorpblue flex justify-between items-center">
          <h1 className="text-16 sm:text-18 leading-[27px] font-semibold text-white">
            Choose which columns you see
          </h1>
          <button
            type="button"
            onClick={() => {
              setIsOpen(false);
              if (setIsWorkingShift) {
                setIsWorkingShift(false);
              }
            }}
          >
            <CloseIcon width={18} height={18} />
          </button>
        </div>
        <div className="bg-white p-4 xs:p-6 sm:p-8 md:p-10">
          <div className="flex flex-col md:flex-row justify-between items-stretch">
            <div className="flex-1 relative min-h-full w-full">
              <div
                className={`absolute overlay-layer w-full h-10 lg:h-16 4xl:h-[74px] bottom-0 left-0 z-[1] ${Styles.overlayLayer}`}
              ></div>
              <div
                ref={overflowContainer1}
                onScroll={handleScroll}
                className="w-full min-h-full max-h-[160px] 2xs:max-h-[240px] md:max-h-[380px] 4xl:max-h-[487px] overflow-y-auto"
              >
                {isWorkingShift ? (
                  <div className="flex border border-vorpgraylight rounded overflow-hidden mb-4">
                    <input
                      type="text"
                      className="p-3 text-12 sm:text-14 leading-5 placeholder:text-vorpgraylight text-black2  w-full focus-within:outline-none focus:outline-none"
                      placeholder="Enter Name"
                      value={nameText}
                      onChange={(e) => setNameText(e.target.value)}
                      onBlur={(e) => setNameText(e.target.value)}
                    />
                  </div>
                ) : null}
                <div className="flex border border-vorpgraylight rounded overflow-hidden mb-4">
                  <input
                    type="text"
                    className="p-3 text-12 sm:text-14 leading-5 placeholder:text-vorpgraylight text-black2  w-full focus-within:outline-none focus:outline-none"
                    placeholder="Search properties"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onBlur={(e) => setSearchText(e.target.value)}
                  />
                  <button type="button" className="pr-3">
                    <SearchIcon width={24} height={24} />
                  </button>
                </div>
                <h2 className="text-12 sm:text-15 leading-[22px] text-black2 font-medium mb-4">
                  {label}
                </h2>
                <div>
                  {columns
                    ?.filter((x) => x.id != "actions" && x.id != "none-actions")
                    //?.filter((x => !unShowFields?.includes(x.id)))
                    .map((column) => (
                      <ColumnVisibilityToggler
                        key={column.id}
                        column={column}
                        selectedColumns={selectedColumns}
                        setSelectedColumns={setSelectedColumns}
                        unShowFields={unShowFields}
                      />
                    ))}
                </div>
              </div>
            </div>
            <div className="flex-1 pt-8 md:pt-0 md:pl-7 w-full min-h-full relative">
              <div
                className={`absolute  overlay-layer w-full h-10 lg:h-16 4xl:h-[74px] bottom-0 left-0 z-[1] ${Styles.overlayLayer}`}
              ></div>
              <div
                ref={overflowContainer2}
                onScroll={handleScroll}
                className="max-h-[160px] 2xs:max-h-[240px] md:max-h-[380px] min-h-full 4xl:max-h-[487px] overflow-y-auto pr-3 w-full"
              >
                <h2 className="text-14 sm:text-16 md:text-18 leading-7 font-semibold text-black2">
                  SELECTED COLUMNS({selectedColumns.length})
                </h2>
                <div className="pt-4 w-full">
                  {selectedColumns.map((col) => (
                    <DraggableCard
                      key={col.id}
                      col={col}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col-reverse xs:flex-row xs:items-center mt-10">
            <div className="flex items-center">
              <Button
                label={
                  <div className="flex items-center">
                    <SaveIcon width={20} height={20} className="mr-2" /> Save
                  </div>
                }
                onClick={saveLayout}
                type="button"
                variant="primary"
                customClass="hover:bg-vorpmyanmar border-none hover:text-vorpblue text-12 sm:text-14 leading-5 font-medium text-white mr-[10px]"
                size="base"
              />
              <Button
                label={
                  <div className="flex items-center">
                    <CloseIcon
                      color="#464E5F"
                      width={9}
                      height={9}
                      className="mr-2"
                    />{" "}
                    Cancel
                  </div>
                }
                onClick={() => {
                  setIsOpen(false);

                  if (setIsWorkingShift) {
                    setIsWorkingShift(false);
                  }
                }}
                type="button"
                variant="primary"
                customClass="hover:bg-offwhite bg-offwhite border-none hover:text-black2 text-black2 text-12 sm:text-[14px] leading-5 font-medium mr-[22px]"
                size="base"
              />
            </div>
            <button
              type="button"
              onClick={() => setSelectedColumns([])}
              className="mr-[22px] text-12 sm:text-14 leading-[21px] font-medium text-vorpblue mb-6 xs:mb-0 text-left w-fit"
            >
              Remove All Columns
            </button>
            <button
              type="button"
              // onClick={() => setSelectedColumns([])}
              onClick={handleSelect}
              className="mr-[22px] text-12 sm:text-14 leading-[21px] font-medium text-vorpblue mb-6 xs:mb-0 text-left w-fit"
            >
              Select All Columns
            </button>
          </div>
        </div>
      </div>
    </>
    // </div>
  );
};

export default TableLayoutEditor;
