import { ReactElement } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Dashboard } from "../dashboard/views/Dashboard";

const DashboardRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;
  return (
    <BasicLayout userData={data} permissions={permissions} sidebars={props.sidebarsData}>
       <Dashboard
          permissions={permissions}
        />
    </BasicLayout>
  );
};

export default DashboardRoute;
