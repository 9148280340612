import { FC, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../../components/common/CommonLanguage";
import LabelAndTextbox from "../../../components/SeoData/LabelAndTextbox";
import LabelAndImage from "../../../components/SeoData/LabelAndImage";
import { CancelIcon, SaveIcon } from "../../../components/common/Icons";
import { IAwardAndCertificate } from "./AwardAndCertificateList";
import LabelAndEditor from "../../../components/SeoData/LabelAndEditor";
import LabelAndDate from "../../../components/SeoData/LabelAndDate";
import LabelAndSelect from "../../../components/SeoData/LabelAndSelect";
import { ILabelValueData } from "../../../latest-news/components/LatestNews";
import SeoData from "../../../components/SeoData/SeoData";

export interface IBoardOfDirectoryInput {
    awards: IAwardAndCertificate;
    setAwards: React.Dispatch<React.SetStateAction<IAwardAndCertificate>>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    isShowDate?: boolean;
    isShowCategory?: boolean;
    handle_submit: (data: IAwardAndCertificate) => Promise<void>;
    isSave: boolean;
    cateogryList?: ILabelValueData[],
    isDetail?: boolean;
}
const AwardAndCertificateInput: FC<IBoardOfDirectoryInput> = ({
    awards,
    setAwards,
    setShow,
    isShowDate,
    isShowCategory,
    handle_submit,
    isSave,
    cateogryList,
    isDetail
}) => {
    const description_en = useRef(null);
    const description_tc = useRef(null);
    const description_sc = useRef(null);
    const [metaImage, setMetaImage] = useState<string>(
        awards.meta_image || ""
    );
    const [data, setData] = useState<IAwardAndCertificate>(awards);
    const [activeLang, setActiveLang] = useState<string>("en");
    const change_data = (value: any, key: string) => {
        if (data) {
            if (key == "image") {
                const url = value ? URL.createObjectURL(value) : '';
                setData((prev) => {
                    return {
                        ...prev,
                        image: value,
                        image_url: url
                    }
                });
                return;
            }
            setData({ ...data, [key]: value });

        }
    }
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    useEffect(() => {
        if (awards) {
            setData({
                id: awards.id,
                title_en: awards.title_en,
                title_tc: awards.title_tc,
                title_sc: awards.title_sc,
                description_en: awards.description_en,
                description_tc: awards.description_tc,
                description_sc: awards.description_sc,
                category_id: awards.category_id,
                upload_date: awards.upload_date ? new Date(awards.upload_date) : new Date(),
                image: undefined,
                image_url: awards.image as any,
                meta_title_en:awards?.meta_title_en??"",
                meta_title_tc:awards?.meta_title_tc??"",
                meta_title_sc:awards?.meta_title_sc??"",
                meta_description_en:awards?.meta_description_en,
                meta_description_tc:awards?.meta_description_tc,
                meta_description_sc:awards?.meta_description_sc,
            });
            setMetaImage(awards?.meta_image??'')
        }
    }, [awards])
    return <div>
        <div className="flex flex-col md:flex-row">
            <div className={twJoin(isDetail == true ? "md:w-3/5 w-full md:mr-5 md:mb-0 mb-5" : "w-full")}>
                <CommonLanguage
                    changeLanguage={changeLanguage}
                    activeLang={activeLang}
                />
                <div className="mt-5">
                    <div
                        className={twJoin(
                            activeLang == "en"
                                ? ""
                                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                        )}
                    >
                        <LabelAndTextbox isRequired={true} label="Title (EN)" value={data?.title_en} setValue={(value) => change_data(value, 'title_en')} />
                        <div className="mt-5">
                            <LabelAndEditor isRequired={true} label="Description (EN)" refId={description_en} value={data?.description_en} setValue={(value) => change_data(value, 'description_en')} />
                        </div>
                    </div>
                    <div
                        className={twJoin(
                            activeLang == "tc"
                                ? ""
                                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                        )}
                    >
                        <LabelAndTextbox isRequired={true} label="Name (TC)" value={data?.title_tc} setValue={(value) => change_data(value, 'title_tc')} />
                        <div className="mt-5">
                            <LabelAndEditor isRequired={true} label="Description (TC)" refId={description_tc} value={data?.description_tc} setValue={(value) => change_data(value, 'description_tc')} />
                        </div>
                    </div>
                    <div
                        className={twJoin(
                            activeLang == "sc"
                                ? ""
                                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                        )}
                    >
                        <LabelAndTextbox isRequired={true} label="Name (SC)" value={data?.title_sc} setValue={(value) => change_data(value, 'title_sc')} />
                        <div className="mt-5">
                            <LabelAndEditor isRequired={true} label="Description (SC)" refId={description_sc} value={data?.description_sc} setValue={(value) => change_data(value, 'description_sc')} />
                        </div>
                    </div>
                    <div className={twJoin(isShowCategory == true ? '' : 'hidden', "mt-5")}>
                        <LabelAndSelect label="Award Category" value={data.category_id} options={cateogryList ? cateogryList : []} setValue={(value) => change_data(value, 'category_id')} />
                    </div>
                </div>
                {isShowDate == true ?
                    <div className="mt-5">
                        <LabelAndDate label="Date"
                            setValue={(date) => change_data(date, 'upload_date')}
                            value={data?.upload_date}
                        />
                    </div> : null}
                <div className="mt-5">
                    <LabelAndImage isRequired={true} label="Image" value={data?.image_url ?? ''} setValue={(value) => change_data(value, 'image_url')} setFile={(value) => change_data(value, 'image')} />
                </div>
            </div>
            <div className={twJoin(isDetail == true ? "md:w-2/5 w-full" : "hidden")}>
                <h2 className="text-lg text-black font-bold text-left my-5">SEO</h2>
                <SeoData
                    showPageTitle={false}
                    setPageTitleEn={() => 1}
                    setPageTitleZh={() => 1}
                    setPageTitleCn={() => 1}
                    metatitle_en={data?.meta_title_en ?? ""}
                    metatitle_zh={data?.meta_title_tc ?? ""}
                    metatitle_cn={data?.meta_title_sc ?? ""}
                    setMetaTitleEn={(value) => change_data(value, 'meta_title_en')}
                    setMetaTitleZh={(value) => change_data(value, 'meta_title_tc')}
                    setMetaTitleCn={(value) => change_data(value, 'meta_title_sc')}
                    metadescription_en={data?.meta_description_en ?? ""}
                    metadescription_zh={data?.meta_description_tc ?? ""}
                    metadescription_cn={data?.meta_description_sc ?? ""}
                    setMetaDescriptionEn={(value) => change_data(value, 'meta_description_en')}
                    setMetaDescriptionZh={(value) => change_data(value, 'meta_description_tc')}
                    setMetaDescriptionCn={(value) => change_data(value, 'meta_description_sc')}
                    meta_image={metaImage}
                    setMetaImage={setMetaImage}
                    setMetaImageFile={(value) => change_data(value, 'meta_image')}
                />
            </div>
        </div>

        <div className="mt-5 last:mb-0 flex items-center">
            <button
                className={twJoin(
                    isSave ? "opacity-50 pointer-events-none" : "",
                    "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                )}
                onClick={() => handle_submit(data)}
            >
                <SaveIcon className="mr-2" /> Save
            </button>
            <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                    setShow(true);
                }}
            >
                <CancelIcon className="mr-2" color="#9E3039" />
                Cancel
            </button>
        </div>
    </div>
}
export default AwardAndCertificateInput;