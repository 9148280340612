import { FC, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
export interface ICompanyProfileData {
    company_profile: ICompanyProfile | undefined;
    activeLang: string;
    setActiveLang: React.Dispatch<React.SetStateAction<string>>;
    setCompanyProfile?: any;
    isShowMission?:boolean;
}
export interface ICompanyProfile {
    title_en: string;
    title_tc: string;
    title_sc: string;
    subtitle_en: string;
    subtitle_tc: string;
    subtitle_sc: string;
    description_en: string;
    description_tc: string;
    description_sc: string;
    image: File | undefined;
    image_url: string;
    mission_title_en?: string;
    mission_title_tc?: string;
    mission_title_sc?: string;
    mission_sub_title_en?: string;
    mission_sub_title_tc?: string;
    mission_sub_title_sc?: string;    
}
const CompanyProfileSection: FC<ICompanyProfileData> = ({
    company_profile,
    activeLang,
    setCompanyProfile,
    isShowMission
}) => {
    const description_en_Ref = useRef();
    const description_tc_Ref = useRef();
    const description_sc_Ref = useRef();
    const [data, setData] = useState<ICompanyProfile | undefined>({
        title_en: '',
        title_tc: '',
        title_sc: '',
        description_en: '',
        description_tc: '',
        description_sc: '',
        image: undefined,
        image_url: '',
        subtitle_en: '',
        subtitle_tc: '',
        subtitle_sc: '',
        mission_title_en: '',
        mission_title_tc: '',
        mission_title_sc: '',
        mission_sub_title_en: '',
        mission_sub_title_tc: '',
        mission_sub_title_sc: '',
    });
    useEffect(() => {
        if (company_profile) {
            setData(company_profile);
        }
    }, [company_profile]);
    const change_data = (value: any, key: string) => {
        if (company_profile) {
            if (key == 'image') {
                const url = value ? URL.createObjectURL(value) : '';
                setCompanyProfile((prev: any) => {
                    if (prev) {
                        return {
                            ...prev,
                            image: value,
                            image_url: url
                        }
                    }
                });
                return;
            }
            setCompanyProfile({ ...company_profile, [key]: value })
        }
    }
    return <div>

        <div className="mt-5">
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >

                <LabelAndTextbox isRequired={true} label="Title (EN)" value={data?.title_en} setValue={(value) => change_data(value, 'title_en')} />
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Subtitle (EN)" value={data?.subtitle_en} setValue={(value) => change_data(value, 'subtitle_en')} />
                </div>
                <div className="mt-5">
                    <LabelAndEditor isRequired={true} refId={description_en_Ref} label="Description (EN)" value={data?.description_en} setValue={(value) => change_data(value, 'description_en')} />
                </div>
                <div className={twJoin(isShowMission==true?'':'hidden',"mt-5")}>
                    <LabelAndTextbox isRequired={true} label="Mission Title (EN)" value={data?.mission_title_en} setValue={(value) => change_data(value, 'mission_title_en')} />
                    <div className="mt-5">
                        <LabelAndTextbox isRequired={true} label="Commitment Title (EN)" value={data?.mission_sub_title_en} setValue={(value) => change_data(value, 'mission_sub_title_en')} />
                    </div>
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >

                <LabelAndTextbox isRequired={true} label="Title (TC)" value={data?.title_tc} setValue={(value) => change_data(value, 'title_tc')} />
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Subtitle (TC)" value={data?.subtitle_tc} setValue={(value) => change_data(value, 'subtitle_tc')} />
                </div>
                <div className="mt-5">
                    <LabelAndEditor isRequired={true} refId={description_tc_Ref} label="Description (TC)" value={data?.description_tc} setValue={(value) => change_data(value, 'description_tc')} />
                </div>
                <div className={twJoin(isShowMission==true?'':'hidden',"mt-5")}>
                    <LabelAndTextbox isRequired={true} label="Mission Title (TC)" value={data?.mission_title_tc} setValue={(value) => change_data(value, 'mission_title_tc')} />
                    <div className="mt-5">
                        <LabelAndTextbox isRequired={true} label="Commitment Title (TC)" value={data?.mission_sub_title_tc} setValue={(value) => change_data(value, 'mission_sub_title_tc')} />
                    </div>
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >

                <LabelAndTextbox isRequired={true} label="Title (SC)" value={data?.title_sc} setValue={(value) => change_data(value, 'title_sc')} />
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Subtitle (SC)" value={data?.subtitle_sc} setValue={(value) => change_data(value, 'subtitle_sc')} />
                </div>
                <div className="mt-5">
                    <LabelAndEditor isRequired={true} refId={description_sc_Ref} label="Description (SC)" value={data?.description_sc} setValue={(value) => change_data(value, 'description_sc')} />
                </div>
                <div className={twJoin(isShowMission==true?'':'hidden',"mt-5")}>
                    <LabelAndTextbox isRequired={true} label="Mission Title (SC)" value={data?.mission_title_sc} setValue={(value) => change_data(value, 'mission_title_sc')} />
                    <div className="mt-5">
                        <LabelAndTextbox isRequired={true} label="Commitment Title (SC)" value={data?.mission_sub_title_sc} setValue={(value) => change_data(value, 'mission_sub_title_sc')} />
                    </div>
                </div>
            </div>
            <LabelAndImage isRequired={true} label="Image" value={data?.image_url} setFile={(value) => change_data(value, 'image')} />
        </div>
    </div>
}
export default CompanyProfileSection;