import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Button from "../common/Button";
import { CloseIcon, SendIcon } from "../common/Icons";
import { Fade, Modal } from "@mui/material";
import classNames from "classnames";
import "react-phone-input-2/lib/style.css";
import { twJoin } from "tailwind-merge";
import { getLanguageCode } from "../../app/services/api";
import CommonLanguage from "../common/CommonLanguage";
import {
  ClassicEditor,
  Context,
  ContextWatchdog,
  Autoformat,
  Bold,
  Italic,
  BlockQuote,
  Base64UploadAdapter,
  CKFinder,
  CKFinderUploadAdapter,
  CloudServices,
  Essentials,
  Heading,
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  PictureEditing,
  Indent,
  Link,
  List,
  Mention,
  Paragraph,
  PasteFromOffice,
  Table,
  TableToolbar,
  TextTransformation,
} from "ckeditor5";
import { CKEditor, CKEditorContext } from "@ckeditor/ckeditor5-react";

import "ckeditor5/ckeditor5.css";
import { StringDropdownList } from "../common/DropdownList";
export const typeList = ["Caution", "Notice", "Warning"];
export type TNameOnlyPopup = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  editedData?: any;
  setEditData?: any;
  savePopupData: any;
  updatePopupData: any;
  title: string;
  isSubmit: boolean;
  setIsSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  uploading: boolean;
  setUploading: React.Dispatch<React.SetStateAction<boolean>>;
  listData: any[];
};
const CreatePopupNameDescription: FC<TNameOnlyPopup> = ({
  isOpen,
  setIsOpen,
  editedData,
  setEditData,
  savePopupData,
  updatePopupData,
  title,
  isSubmit,
  setIsSubmit,
  setUploading,
  uploading,
  listData,
}) => {
  const languageCode = getLanguageCode();
  const [activeLang, setActiveLang] = useState<string>("en");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [selected, setSelected] = useState<string>("");
  const [name_en, setName_en] = useState<string>("");
  const [name_tc, setName_tc] = useState<string>("");
  const [name_sc, setName_sc] = useState<string>("");
  const [description_en, setDescription_en] = useState<string>("");
  const [description_tc, setDescription_tc] = useState<string>("");
  const [description_sc, setDescription_sc] = useState<string>("");
  const editorRef_en: any = useRef(null);
  const editorRef_tc: any = useRef(null);
  const editorRef_sc: any = useRef(null);
  const clearData = () => {
    setName_en("");
    setName_tc("");
    setName_sc("");
    setDescription_en("");
    setDescription_tc("");
    setDescription_sc("");
    setActiveLang("en");
    setSelected("");
  };

  const checkValidation = (saveData: any) => {
    if (
      saveData.title_en === "" ||
      saveData.title_tc === "" ||
      saveData.title_sc === "" ||
      saveData.description_en === "" ||
      selected == ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async () => {
    setIsSubmit(true);
    setUploading(true);
    const saveData = {
      title_en: name_en,
      title_tc: name_tc,
      title_sc: name_sc,
      description_en: description_en,
      description_tc: description_tc,
      description_sc: description_sc,
      type: selected,
    };
    const check = checkValidation(saveData);

    if (!check) {
      if (editedData) {
        updatePopupData(saveData);
      } else {
        savePopupData(saveData);
      }
    } else {
      setUploading(false);
    }
  };

  useEffect(() => {
    if (editedData) {
      setName_en(editedData["title_en"]);
      setName_tc(editedData["title_tc"]);
      setName_sc(editedData["title_sc"]);
      setDescription_en(editedData["description_en"]);
      setDescription_tc(editedData["description_tc"]);
      setDescription_sc(editedData["description_sc"]);
      setSelected(editedData.type_id);
    }
  }, [editedData]);
  useEffect(() => {
    if (!editedData) {
      clearData();
    }
    setActiveLang("en");
  }, [isOpen, editedData]);
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  const handleEditorChange = (event: any, editor: any) => {
    if (activeLang == "en") setDescription_en(editor.getData());
    if (activeLang == "tc") setDescription_tc(editor.getData());
    if (activeLang == "sc") setDescription_sc(editor.getData());
  };
  return (
    <Modal
      className="project_popup"
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          setIsOpen(false);
          setEditData("");
          setIsSubmit(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={isOpen} timeout={500}>
        <div className="lg:max-w-[800px] max-w-full w-full m-auto">
          <div className="modalContent w-full h-full p-6 lg:p-0 flex justify-center items-center relative z-[999]">
            <div className="rounded-xl bg-white font-primary w-full">
            <div
              id="modal-modal-title"
              className="bg-vorpblue relative flex justify-between py-6 sm:px-10 px-5 rounded-t-xl"
            >
                <h3 className="text-18 leading-[27px] font-semibold text-white">
                  {title}
                </h3>
                <button
                  key={Math.random() * 100}
                  type="button"
                  aria-label="Close Modal"
                  onClick={() => {
                    setIsOpen(false);
                    setIsSubmit(false);
                    setEditData("");
                    setUploading(false);
                    setErrorMsg("");
                    clearData();
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
              <div
                id="modal-modal-description"
                className="pb-9 px-5 bg-white rounded-b-xl  overflow-y-auto max-h-[70vh] h-full custom_scrollbar_popup"
              >
                <div className="flex flex-col justify-between w-full ">
                  <CommonLanguage
                    changeLanguage={changeLanguage}
                    activeLang={activeLang}
                  />
                  <div
                    className={twJoin(
                      activeLang == "en" ? "" : "opacity-0 pointer-events-none max-h-0 overflow-hidden",
                      "mb-0 last:mb-0 w-full px-3"
                    )}
                  >
                    <div>
                      <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-[0.688rem]">
                        Name<span className=" text-red-500">*</span>
                      </label>
                      <input
                        value={name_en}
                        onChange={(e) => setName_en(e.target.value)}
                        type="text"
                        placeholder="Name"
                        className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                      ></input>
                      <p
                        className={classNames(
                          isSubmit && name_en === "" ? "" : "hidden",
                          "mb-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        Name required
                      </p>
                    </div>
                    <div>
                      <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-[0.688rem]">
                        Description<span className=" text-red-500">*</span>
                      </label>
                      <CKEditorContext
                        context={Context}
                        contextWatchdog={ContextWatchdog}
                      >
                        <CKEditor
                          editor={ClassicEditor}
                          ref={editorRef_en}
                          config={{
                            plugins: [
                              Essentials,
                              Autoformat,
                              Bold,
                              Italic,
                              BlockQuote,
                              CKFinder,
                              CKFinderUploadAdapter,
                              CloudServices,
                              Heading,
                              Image,
                              ImageCaption,
                              ImageStyle,
                              ImageToolbar,
                              ImageUpload,
                              Base64UploadAdapter,
                              Indent,
                              Link,
                              List,
                              Mention,
                              Paragraph,
                              PasteFromOffice,
                              PictureEditing,
                              Table,
                              TableToolbar,
                              TextTransformation,
                            ],
                            toolbar: [
                              "undo",
                              "redo",
                              "|",
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "|",
                              "link",
                              "uploadImage",
                              "insertTable",
                              "blockQuote",
                              "|",
                              "bulletedList",
                              "numberedList",
                              "|",
                              "outdent",
                              "indent",
                            ],
                          }}
                          data={description_en ? description_en : "<p></p>"}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onBlur={handleEditorChange}
                        />
                      </CKEditorContext>
                      <p
                        className={classNames(
                          isSubmit && description_en === "" ? "" : "hidden",
                          "mb-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        Description is required
                      </p>
                    </div>
                  </div>
                  <div
                    className={twJoin(
                      activeLang == "tc" ? "" : "opacity-0 pointer-events-none max-h-0 overflow-hidden",
                      "mb-0 last:mb-0 w-full px-3"
                    )}
                  >
                    <div>
                      <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-[0.688rem]">
                        Name<span className=" text-red-500">*</span>
                      </label>
                      <input
                        value={name_tc}
                        onChange={(e) => setName_tc(e.target.value)}
                        type="text"
                        placeholder="Name"
                        className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                      ></input>
                      <p
                        className={classNames(
                          isSubmit && description_en === "" ? "" : "hidden",
                          "mb-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        Name required
                      </p>
                    </div>
                    <div>
                      <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-[0.688rem]">
                        Description<span className=" text-red-500">*</span>
                      </label>
                      <CKEditorContext
                        context={Context}
                        contextWatchdog={ContextWatchdog}
                      >
                        <CKEditor
                          editor={ClassicEditor}
                          ref={editorRef_tc}
                          config={{
                            plugins: [
                              Essentials,
                              Autoformat,
                              Bold,
                              Italic,
                              BlockQuote,
                              CKFinder,
                              CKFinderUploadAdapter,
                              CloudServices,
                              Heading,
                              Image,
                              ImageCaption,
                              ImageStyle,
                              ImageToolbar,
                              ImageUpload,
                              Base64UploadAdapter,
                              Indent,
                              Link,
                              List,
                              Mention,
                              Paragraph,
                              PasteFromOffice,
                              PictureEditing,
                              Table,
                              TableToolbar,
                              TextTransformation,
                            ],
                            toolbar: [
                              "undo",
                              "redo",
                              "|",
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "|",
                              "link",
                              "uploadImage",
                              "insertTable",
                              "blockQuote",
                              "|",
                              "bulletedList",
                              "numberedList",
                              "|",
                              "outdent",
                              "indent",
                            ],
                          }}
                          data={description_tc ? description_tc : "<p></p>"}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onBlur={handleEditorChange}
                        />
                      </CKEditorContext>
                      <p
                        className={classNames(
                          isSubmit && description_tc === "" ? "" : "hidden",
                          "mb-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        Description is required
                      </p>
                    </div>
                  </div>
                  <div
                    className={twJoin(
                      activeLang == "sc" ? "" : "opacity-0 pointer-events-none max-h-0 overflow-hidden",
                      "mb-0 last:mb-0 w-full px-3"
                    )}
                  >
                    <div>
                      <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-[0.688rem]">
                        Name<span className=" text-red-500">*</span>
                      </label>
                      <input
                        value={name_sc}
                        onChange={(e) => setName_sc(e.target.value)}
                        type="text"
                        placeholder="Name"
                        className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                      ></input>
                      <p
                        className={classNames(
                          isSubmit && name_sc === "" ? "" : "hidden",
                          "mb-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        Name required
                      </p>
                    </div>
                    <div>
                      <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-[0.688rem]">
                        Description<span className=" text-red-500">*</span>
                      </label>
                      <CKEditorContext
                        context={Context}
                        contextWatchdog={ContextWatchdog}
                      >
                        <CKEditor
                          editor={ClassicEditor}
                          ref={editorRef_sc}
                          config={{
                            plugins: [
                              Essentials,
                              Autoformat,
                              Bold,
                              Italic,
                              BlockQuote,
                              CKFinder,
                              CKFinderUploadAdapter,
                              CloudServices,
                              Heading,
                              Image,
                              ImageCaption,
                              ImageStyle,
                              ImageToolbar,
                              ImageUpload,
                              Base64UploadAdapter,
                              Indent,
                              Link,
                              List,
                              Mention,
                              Paragraph,
                              PasteFromOffice,
                              PictureEditing,
                              Table,
                              TableToolbar,
                              TextTransformation,
                            ],
                            toolbar: [
                              "undo",
                              "redo",
                              "|",
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "|",
                              "link",
                              "uploadImage",
                              "insertTable",
                              "blockQuote",
                              "|",
                              "bulletedList",
                              "numberedList",
                              "|",
                              "outdent",
                              "indent",
                            ],
                          }}
                          data={description_sc ? description_sc : "<p></p>"}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onBlur={handleEditorChange}
                        />
                      </CKEditorContext>
                      <p
                        className={classNames(
                          isSubmit && description_sc === "" ? "" : "hidden",
                          "mb-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        Description is required
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="mb-0 last:mb-0 w-full px-3">
                    <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block mb-2 mb-[0.688rem]">
                    FAQ Type<span className=" text-red-500">*</span>
                    </label>
                    <StringDropdownList
                      state={selected}
                      setState={setSelected}
                      placeholder="Select FAQ Type"
                      className="w-full py-3 pl-3 mb-3 text-14"
                      dropdownItems={listData?.map((status: any) => ({
                        value: status.id,
                        label: status.name_en,
                      }))}
                      textAlign={"text-left"}
                      fontSize="text-14"
                    />
                    <p
                      className={classNames(
                        isSubmit && selected === "" ? "" : "hidden",
                        "mb-2 font-poppins text-14 text-redPink"
                      )}
                    >
                      FAQ Type is required
                    </p>
                  </div>
                </div>
                <div className="px-2 pb-5 pt-5 flex justify-between items-center">
                  <div className="flex flex-wrap">
                    <div className="flex px-2 justify-center items-center">
                      <Button
                        type="button"
                        label={
                          <span className="flex items-center text-12 leading-[18px]">
                            <SendIcon className="mr-[10px] group-hover:opacity-0 transition-all duration-300" />
                            {editedData ? "Update" : "Save"}
                          </span>
                        }
                        onClick={() => {
                          handleSubmit();
                        }}
                        variant="no-outline"
                        size="lg"
                        customClass={classNames(
                          uploading ? " pointer-events-none opacity-50" : "",
                          "hover:bg-vorpblue mr-2 py-2 lg:py-2 px-3 custom-button-size"
                        )}
                      />

                      <Button
                        type="button"
                        label={
                          <span className="flex items-center text-12 leading-[18px] ">
                            <CloseIcon
                              width={14}
                              height={14}
                              className="mr-[10px] [&_path]:fill-graydark"
                            />
                            Cancel
                          </span>
                        }
                        onClick={() => {
                          setIsOpen(false);
                          setIsSubmit(false);
                          setEditData("");
                          setErrorMsg("");
                          clearData();
                          setUploading(false);
                          setIsSubmit(false);
                        }}
                        variant="no-outline"
                        size="lg"
                        customClass=" mr-2 py-2 lg:py-2 px-3 bg-offwhite text-black2 border-none custom-button-size"
                      />
                    </div>
                  </div>
                  <p
                    className={classNames(
                      isSubmit || errorMsg ? "" : "hidden",
                      "mb-2 font-poppins text-12 text-redPink"
                    )}
                  >
                    {errorMsg}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default CreatePopupNameDescription;
