import { ReactElement } from 'react';
import BasicLayout from '../layouts/BasicLayout';
import { Outlet } from 'react-router-dom';
import CompanyProfilePage from './Components/CompanyProfilePage';
import CompanyVisionMissionPage from './Components/CompanyVisionMissionPage';
import LeadershipPage from './Components/LeadershipPage';
import ExecutiveLeadership from './Components/ExecutiveLeadership';
import BoardOfDirectors from './Components/BoardOfDirectors';
import AwardList from './Components/Awards/AwardList';
import AwardsListPage from './Components/Awards/AwardsListPage';
import CertificateList from './Components/Awards/CertificateList';
import SustainableDevelopmentPage from './Components/Substainable/SustainableDevelopmentPage';
import SubstainableListPage from './Components/Substainable/SubstainableListPage';
import CustomerAirlinesPage from './Components/CustomerAirlines/CustomerAirlinesPage';
import CustomerAirlines from './Components/CustomerAirlines/CustomerAirlines';
import AwardsCategoryPage from './Components/Awards/AwardsCategoryPage';
import CompanyVisionMissionList from './Components/CompanyVisionMissionList';
import CompanyHistoryList from './Components/CompanyHistoryList';
export const companyProfileList=[
    {
      id:1,
      path:'#',
      component:<CompanyProfilePage/>
    },
    {
      id:2,
      path:'our-story',
      component:<CompanyProfilePage/>
    },
    {
      id:3,
      path:'company-history',
      component:<CompanyHistoryList/>
    },
    {
      id:4,
      path:'vision-mission',
      component:<CompanyVisionMissionPage/>
    },
    {
      id:5,
      path:'vision-mission-list',
      component:<CompanyVisionMissionList/>
    },
    {
      id:6,
      path:'leadership',
      component:<LeadershipPage/>
    },
    {
      id:7,
      path:'board_of_directors',
      component:<BoardOfDirectors/>
    },
    {
      id:8,
      path:'executive_leadership',
      component:<ExecutiveLeadership/>
    },
    {
      id:9,
      path:'awards',
      component:<AwardList/>
    },
    {
      id:10,
      path:'awards_list',
      component:<AwardsListPage/>
    },
    {
      id:11,
      path:'awards_category',
      component:<AwardsCategoryPage/>
    },
    {
      id:12,
      path:'certificate_list',
      component:<CertificateList/>
    },
    {
      id:13,
      path:'sustainable',
      component:<SustainableDevelopmentPage/>
    },
    {
      id:14,
      path:'sustainable_list',
      component:<SubstainableListPage/>
    },
    {
      id:15,
      path:'airlines-page',
      component:<CustomerAirlines/>
    },
    {
      id:16,
      path:'airlines',
      component:<CustomerAirlinesPage/>
    },
  ]
const CompanyProfilePageRoute = (props:any): ReactElement => {
    const data = props.loginData;
    const permissions = props.permissions;
    return  <BasicLayout userData={data} permissions={permissions} sidebars={props.sidebarsData}>
    <div className="pt-0 pb-[58px]">
      <Outlet />
    </div>
  </BasicLayout>
}
export default CompanyProfilePageRoute;
