import { FC, useEffect, useRef, useState } from "react";
import CommonLanguage from "../../components/common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import { IContactLocation } from "./ContactUsLocationList";
import { IContactLocationData } from "./ContactUsLocationData";
import LabelAndSelect from "../../components/SeoData/LabelAndSelect";
import { ILabelValueData } from "../../latest-news/components/LatestNews";
export interface IMissionData {
    detailData: IContactLocationData;
    setDetailData: React.Dispatch<React.SetStateAction<IContactLocationData>>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    handle_submit: (data: IContactLocationData) => Promise<void>;
    isSave: boolean;
    locationData: ILabelValueData[];
}
const ContactUsLocationInputData: FC<IMissionData> = ({
    detailData,
    setDetailData,
    setShow,
    handle_submit,
    isSave,
    locationData
}) => {
    const [data, setData] = useState<IContactLocationData>({
        id: 0,
        label_en: '',
        label_tc: '',
        label_sc: '',
        text_en: '',
        text_tc: '',
        text_sc: '',
        url_en: '',
        url_tc: '',
        url_sc: '',
        icon: undefined,
        icon_url: '',
        location: ''
    });
    const [activeLang, setActiveLang] = useState<string>("en");
    const mapRef = useRef(null);
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    useEffect(() => {
        if (detailData) {
            setData({
                id: detailData.id,
                label_en: detailData?.label_en,
                label_tc: detailData?.label_tc,
                label_sc: detailData?.label_sc,
                text_en: detailData?.text_en,
                text_tc: detailData?.text_en,
                text_sc: detailData?.text_en,
                url_en: detailData.url_en,
                url_tc: detailData.url_tc,
                url_sc: detailData.url_sc,
                icon: undefined,
                icon_url: detailData.icon as any,
                location: detailData?.location_id??''
            });
        }
    }, [detailData]);
    const change_data = (value: any, key: string) => {
        if (data) {
            if(key=="location"){
            }
            setData({ ...data, [key]: value })
        }
    }
    return <div>
        <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
        />
        <div>
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Label (EN)" value={data?.label_en} setValue={(value) => change_data(value, 'label_en')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Text (EN)" value={data?.text_en} setValue={(value) => change_data(value, 'text_en')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Url (EN)" value={data?.url_en} setValue={(value) => change_data(value, 'url_en')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Label (TC)" value={data?.label_tc} setValue={(value) => change_data(value, 'label_tc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Text (TC)" value={data?.text_tc} setValue={(value) => change_data(value, 'text_tc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Url (TC)" value={data?.url_tc} setValue={(value) => change_data(value, 'url_tc')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Label (SC)" value={data?.label_sc} setValue={(value) => change_data(value, 'label_sc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Text (SC)" value={data?.text_sc} setValue={(value) => change_data(value, 'text_sc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Url (SC)" value={data?.url_sc} setValue={(value) => change_data(value, 'url_sc')} />
                </div>
            </div>
            <div className="mt-5">
                <LabelAndSelect label="Location" value={data?.location} options={locationData ?? []} setValue={(value) => change_data(value, 'location')} />
            </div>
            <div className="mt-5">
                <LabelAndImage isRequired={true} label="Icon" value={data?.icon_url} setFile={(value) => change_data(value, 'icon')} />
            </div>
        </div>
        <div className="mt-5 last:mb-0 flex items-center">
            <button
                className={twJoin(
                    isSave ? "opacity-50 pointer-events-none" : "",
                    "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                )}
                onClick={() => handle_submit(data)}
            >
                <SaveIcon className="mr-2" /> Save
            </button>
            <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                    setShow(true);
                }}
            >
                <CancelIcon className="mr-2" color="#9E3039" />
                Cancel
            </button>
        </div>
    </div>
}
export default ContactUsLocationInputData;