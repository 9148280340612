import { FC, useEffect, useState } from "react";
import { CancelIcon } from "../../components/common/Icons";
import styles from "./JobApplication.module.scss";
interface IData {
  initialData: JobApplicationType;
  setValue: React.Dispatch<any>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  handle_submit: (data: JobApplicationType) => Promise<void>;
  isSave: boolean;
  setIsView: React.Dispatch<React.SetStateAction<boolean>>;
  isView: boolean;
}

export interface JobApplicationType {
  id: number;
  job_id: number;
  job_opening:any;
  source_type: number;
  expected_salary: string;
  date_of_availability: string;
  reference_in_pre_offer_notice: string;

  category: string;
  first_name: string;
  last_name: string;
  first_name_chinese: string;
  last_name_chinese: string;
  sex: number;
  date_of_birth: string;
  phone_number: string;
  email: string;
  address: string;
  is_permanent_resider_hk: boolean;
  hk_id_passport_no: string;
  chinese_commercial_code: string;
  is_right_of_abode_hk: boolean;
  require_hk_work_visa: boolean;
  emergency_contact_name: string;
  emergency_contact_relation: string;
  emergency_contact_call: string;

  can_overtime: boolean;
  can_overtime_short_notice: boolean;
  can_rotating_shift: boolean;
  can_work_at_night: boolean;
  can_night_shift: boolean;
  can_sunday_or_statutory_holiday: boolean;
  have_any_criminal_offence: boolean;
  involved_bankruptcy_proceedings: boolean;
  issue_ara_permit: boolean;
  have_rejected_airport_area_permit: boolean;

  working_experiences: any;
  professional_qualifications: any;
  academic_background: string;

  educations: any;
  english: string;
  cantonese: string;
  mandarin: string;
  other_language: string;
  other_language_name: string;
  languages:any;
  friend_employed_by_company: string;
  related_to_sats_hk: string;
  have_declare: boolean;
  have_authorize: boolean;
  have_read: boolean;
  application_signature_path: undefined;
}
const JobApplication: FC<IData> = ({ setShowList, initialData, isView,setIsView }) => {
  const [detailData,setDetailData]=useState<JobApplicationType>()
  useEffect(()=>{
    setDetailData(initialData)
  },[initialData]);
  return (
    isView==false? <div>
   <h2 className="text-lg text-black font-bold text-left my-5">
     Job Application
   </h2>
   <div className="lg:overflow-x-hidden">
     <table className={styles["job-table"]}>
       <tbody>
         <tr>
           <td>Category</td>
           <td>{detailData?.job_opening?.title_en || "-"}</td>
         </tr>
         <tr>
           <td>Expected Salary</td>
           <td>{detailData?.expected_salary || "-"}</td>
         </tr>
         <tr>
           <td>Date of Availablity</td>
           <td>{detailData?.date_of_availability || "-"}</td>
         </tr>
         <tr>
           <td>Reference in Pre-offer notice</td>
           <td>{detailData?.reference_in_pre_offer_notice || "-"}</td>
         </tr>
         <tr>
           <td>Name</td>
           <td>{detailData?.first_name || "-"} {detailData?.last_name || "-"}</td>
         </tr>
         {/* <tr>
           <td>Last Name</td>
           <td>{detailData?.last_name || "-"}</td>
         </tr> */}
         <tr>
           <td>{`Name (in Chinese) (Same as HKID)`}</td>
           <td>{detailData?.first_name_chinese || "-"} {detailData?.last_name_chinese || "-"}</td>
         </tr>
         {/* <tr>
           <td>{`Last Name (in Chinese) (Same as HKID)`}</td>
           <td>{detailData?.last_name_chinese || "-"}</td>
         </tr> */}
         <tr>
           <td>Sex</td>
           <td>{detailData?.sex && (initialData.sex > 1 ? 'Female' : 'Male')}</td>
         </tr>
         <tr>
           <td>Date of Birth *</td>
           <td>{detailData?.date_of_birth || "-"}</td>
         </tr>
         <tr>
           <td>Phone Number</td>
           <td>{detailData?.phone_number || "-"}</td>
         </tr>
         <tr>
           <td>Email Address</td>
           <td>{detailData?.email || "-"}</td>
         </tr>
         <tr>
           <td>Address</td>
           <td>{detailData?.address || "-"}</td>
         </tr>
         <tr>
           <td>Are you a permanent resident of Hong Kong?</td>
           <td>{detailData?.is_permanent_resider_hk ? "Yes" : "No"}</td>
         </tr>

         <tr>
           <td>HKID No / Passport No</td>
           <td>{detailData?.hk_id_passport_no || "-"}</td>
         </tr>

         <tr>
           <td>Chinese Commercial Code</td>
           <td>{detailData?.chinese_commercial_code || "-"}</td>
         </tr>
         <tr>
           <td>Do you have right of abode in Hong Kong?</td>
           <td>{detailData?.is_right_of_abode_hk ? "Yes" : "No"}</td>
         </tr>
         <tr>
           <td>Do you require Hong Kong work visa?</td>
           <td>{detailData?.require_hk_work_visa ? "Yes" : "No"}</td>
         </tr>
         <tr>
           <td>Emergency Contact Name</td>
           <td>{detailData?.emergency_contact_name || "-"}</td>
         </tr>
         <tr>
           <td>Emergency Contact Relationship</td>
           <td>{detailData?.emergency_contact_relation || "-"}</td>
         </tr>
         <tr>
           <td>Emergency Call</td>
           <td>{detailData?.emergency_contact_call || "-"}</td>
         </tr>

         <tr>
           <td>Can you work overtime?</td>
           <td>{detailData?.can_overtime ? "Yes" : "No"}</td>
         </tr>
         <tr>
           <td>Can you work overtime with short notice?</td>
           <td>{detailData?.can_overtime_short_notice ? "Yes" : "No"}</td>
         </tr>
         <tr>
           <td>Can you work rotating shifts?</td>
           <td>{detailData?.can_rotating_shift ? "Yes" : "No"}</td>
         </tr>
         <tr>
           <td>Can you work night shift?</td>
           <td>{detailData?.can_night_shift ? "Yes" : "No"}</td>
         </tr>
         <tr>
           <td>Can you work on Sunday or Statutory Holiday?</td>
           <td>{detailData?.can_sunday_or_statutory_holiday ? "Yes" : "No"}</td>
         </tr>
         <tr>
           <td>
             Have you ever been convicted or accused of any criminal offence?
           </td>
           <td>{detailData?.have_any_criminal_offence ? "Yes" : "No"}</td>
         </tr>
         <tr>
           <td>
             Have you ever been declared bankrupt or involved in any
             bankruptcy proceedings?
           </td>
           <td>{detailData?.involved_bankruptcy_proceedings ? "Yes" : "No"}</td>
         </tr>
         <tr>
           <td>Previously issued ARA Permit?</td>
           <td>{detailData?.issue_ara_permit ? "Yes" : "No"}</td>
         </tr>
         <tr>
           <td>
             Have you ever got rejected for applying Airport Restricted Area
             Permit?
           </td>
           <td>
             {detailData?.have_rejected_airport_area_permit ? "Yes" : "No"}
           </td>
         </tr>

         <tr>
           <td>{`Working Experience (in descending chronological order)`}</td>
           <td>
             {detailData?.working_experiences?.map((exp: any, key: number) => {
               return <div>
                 <div className="flex">
                   <span className="mr-2 min-w-[150px]">Company Name: </span>
                   <span>{exp.name_of_company}</span>
                 </div>
                 <div className="flex">
                   <span className="mr-2 min-w-[150px]">Position: </span>
                   <span>{exp.last_position}</span>
                 </div>
                 <div className="flex">
                   <span className="mr-2 min-w-[150px]">Salary Allowance : </span>
                   <span>{exp.salary_allowance}</span>
                 </div>
               </div>
             })}</td>
         </tr>
         <tr>
           <td>{`Professional Qualification (in descending chronological order)`}</td>
           <td>
           {detailData?.professional_qualifications?.map((exp: any, key: number) => {
               return <div>
                 <div className="flex">
                   <span className="mr-2 min-w-[150px]">Name: </span>
                   <span>{exp.name_of_issuing_authority}</span>
                 </div>
               </div>
             })}
           </td>
         </tr>
         <tr>
           <td>Academic Background</td>
           <td>{detailData?.academic_background || "-"}</td>
         </tr>
         <tr>
           <td>Education</td>
           <td>
           {detailData?.educations?.map((exp: any, key: number) => {
               return <div>
                 <div className="flex">
                   <span className="mr-2 min-w-[150px]">Name: </span>
                   <span>{exp.name}</span>
                 </div>
               </div>
             })}</td>
         </tr>
         <tr>
           <td>Languages</td>
           <td>{detailData?.languages?.map((exp: any, key: number) => {
               return <div className="mb-5">
                 <div className="flex">
                   <span className="mr-2 min-w-[150px]">Name: </span>
                   <span className="capitalize">{exp.name_of_language}</span>
                 </div>
                 <div className="flex">
                   <span className="mr-2 min-w-[150px]">Speaking: </span>
                   <span className="capitalize">{exp.speaking}</span>
                 </div>
                 <div className="flex">
                   <span className="mr-2 min-w-[150px]">Reading: </span>
                   <span className="capitalize">{exp.reading}</span>
                 </div>
               </div>
             })}</td>
         </tr>
         <tr>
           <td>
             Are any of your family members or friend employed by our
             company?
           </td>
           <td>
             {detailData?.friend_employed_by_company ? "Yes" : "No"}
           </td>
         </tr>
         <tr>
           <td>
             To your best knowledge, any of your family members are related
             to thecustomers, suppliers, contractors, or staffs of SATS HK?
           </td>
           <td>
             {detailData?.related_to_sats_hk ? "Yes" : "No"}
           </td>
         </tr>
         <tr>
           <td>
             I have read the attached Personal InformationCollection
             Statement.
           </td>
           <td>{detailData?.have_read ? "Yes" : "No"}</td>
         </tr>
         <tr>
           <td>I have_authorize SATS HK Limited for Reference Check.</td>
           <td>{detailData?.have_authorize ? "Yes" : "No"}</td>
         </tr>
         <tr>
           <td>
             I declare that the particulars here entered are to the best of
             my knowledge true and accurate.
           </td>
           <td>{detailData?.have_declare ? "Yes" : "No"}</td>
         </tr>
         <tr>
           <td>Application's Signature</td>
           <td>
             {detailData?.application_signature_path ? (
               <img
                 src={initialData.application_signature_path}
                 alt="application signature"
                 className="aspect-[100/100] w-full h-full max-w-[100px] object-cover bg-center "
               />
             ) : (
               "-"
             )}
           </td>
         </tr>
       </tbody>
     </table>
   </div>

   <div className="mt-5 last:mb-0 flex items-center">
     <button
       className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
       onClick={() => {
         setShowList(true);
       }}
     >
       <CancelIcon className="mr-2" color="#9E3039" />
       Back
     </button>
   </div>
 </div>:<div>
  <h1 className="text-2xl">Loading...</h1>
 </div>
  );
};
export default JobApplication;
