import { FC, useEffect, useState } from "react";
import DebounceSearchInput from "../../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../../components/common/Icons";
import Button from "../../../components/common/Button";
import { NumberDropdownList } from "../../../components/common/DropdownList";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import DownloadItemSectionTable from "./DownloadItemTable";
import { baseObj } from "../../../utils/constants";
import { templateList } from "../../../active-time-report/views/ActiveTimeReport";
import { useGetDownloadItemsInfoMutation, useGetDownloadItemsListQuery, useGetMediaResourceCategoriesQuery } from "../../../app/services/services/mediaResource";
import CommonLanguage from "../../../components/common/CommonLanguage";
import DownloadItem, { DownloadItemType } from "../DownLoadItem";
import { endpointUrl } from "../../../app/services/api";
import axios from "../../../axios";
import { ToastContainer, toast } from "react-toastify";
import CommonErrorPopup from "../../../components/common/CommonErrorPopup";
import { getAuthUser } from "../../../app/services/dashboard";
interface IDownloadItem{
  setShowList: React.Dispatch<React.SetStateAction<boolean>>
}
const DownloadItemSectionList=()=>{
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });
  const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
    false
  );
  const [showList, setShowList] = useState<boolean>(true);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [data, setData] = useState<baseObj[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [tempList, setTempList] = useState<any>(templateList);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [activeLang, setActiveLang] = useState<string>("en");
  const [servicesData, setServicesData] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [viewCols, setCols] = useState<any>([]);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isSave, setIsSave] = useState<boolean>(false);
  const [categories,setCategories]=useState<any>([]);
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  const {data:cate}=useGetMediaResourceCategoriesQuery();
  const [DownloadItemsData, setDownloadItemsData] = useState<DownloadItemType>({
    id: 0,
    category_id:0,
    title_en: "",
    title_sc: "",
    title_tc: "",
    image_url: "",
    file: undefined,
    icon_url: "",
    icon: undefined,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [getDownloadInfo]=useGetDownloadItemsInfoMutation();
  const {data:downloads}=useGetDownloadItemsListQuery({
    page:currentPage,
    limit:pagination.pageSize,
    search:globalSearch,
    sort: sorting?sorting
      .map(
        (s) =>
          `${s.desc ? "-" : ""}${s.id}`
      )
      .join(","):'',
  });
  useEffect(()=>{
    if(downloads){
      setData(downloads?.data);
      setItemCount(downloads?.total);
    }
  },[downloads]);
  useEffect(()=>{
    if(cate){
      setCategories(cate.data)
    }
  },[cate])
  const handle_submit = async (data:DownloadItemType) => {
    setIsSave(true);
    const formData = new FormData();
    formData.append('id', data?.id?.toString() ?? '');
    formData.append('title_en', data?.title_en ?? '');
    formData.append('title_tc', data?.title_tc ?? '');
    formData.append('title_sc', data?.title_sc ?? '');
    formData.append('category_id', data?.category_id?.toString() ?? '');
    formData.append('page_type', 'media-report');
    if (data.file) {
      formData.append('file', data.file as any);
    }
    if (data.icon) {
      formData.append('icon', data.icon as any);
    }
    const url=data.id?'update-media-download-items':'create-media-download-items';
    var getUser = getAuthUser();
    await axios(endpointUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
      },
      data: formData,
    })
      .then((res: any) => {
        if (res?.data?.status == true) {
          setShowList(true);
          toast(res?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
          getDownloadInfo({
            page:currentPage,
            limit:pagination.pageSize,
            search:globalSearch,
            sorting:''
          }).then((res:any)=>{
            if(res.data){
              setData(res?.data)
            }
          })
        } else {
          setErrorPopup(true);
          setMessage(res?.data?.message);
        }
        setIsSave(false);
      })
      .catch((err) => {
        setIsSave(false);
        setErrorPopup(true);
        setMessage(Object.values(err?.response?.data?.errors)?.join(' '));
      });
  }
  const clear_data=()=>{
    setDownloadItemsData({
      id: 0,
      category_id:0,
    title_en: "",
    title_sc: "",
    title_tc: "",
    image_url: "",
    file: undefined,
    icon_url: "",
    icon: undefined,
    })
  }
  return (
    <div className="bg-white rounded-xl pt-4 pb-4 sm:pt-4 sm:pb-9 px-4 w-full h-full font-primary">
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <CommonErrorPopup
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      {showList==false?<div>
          <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
          />
          <DownloadItem
          categories={categories}
          isSave={isSave}
            activeLang={activeLang}
            initialData={DownloadItemsData}
            setValue={setDownloadItemsData}
            setShowList={setShowList}
            handle_submit={handle_submit}
          />
        </div>:<>
      <div className="flex flex-wrap justify-between items-center mb-1">
        <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] my-1">
          <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
            {setState =>
              <div className="flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                <input
                  type="text"
                  placeholder="Search"
                  className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                  onChange={e => setState(e.target.value)}
                />
                <SearchIcon className="mr-2" />
              </div>}
          </DebounceSearchInput>
        </div>

        <div className="flex items-center xs:mt-1 sm:mt-1">
          <Button
            label="+ Add New"
            onClick={() => {clear_data();setShowList(false)}}
            type="button"
            variant="primary"
            customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
            size="base"
          />
        </div>
      </div>

      <div className="flex sm:flex-row flex-col justify-end mb-1">
        <div className="flex items-center sm:justify-start justify-end">
          <div className="flex items-center 3xl:ml-3 mr-[10px]">
            <p className="xl:text-sm text-12 mr-2 text-black2 font-primary">
              DISPLAY
            </p>
            <NumberDropdownList
              state={pagination.pageSize}
              setState={value =>
                setPagination(prev => ({
                  ...prev,
                  pageSize: value as number
                }))}
              dropdownItems={new Array(5).fill(1).map((_, index: number) => {
                const count = (index + 1) * 20;
                return {
                  value: count,
                  label: String(count)
                };
              })}
              className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
              textAlign="text-left pl-3"
              paddingClass="py-[2px]"
              placeholder="All"
            />
          </div>
        </div>
      </div>

      <div>
        <div className="text-left">
          <DownloadItemSectionTable
            // columnsData={columnsData}
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            pagination={pagination}
            data={data}
            setData={setData}
            setPagination={setPagination}
            hiddenFields={hiddenFields ? hiddenFields : []}
            setHiddenFields={setHiddenFields}
            checkManagement={true}
            total={itemCount}
            localModuleId={localModuleId}
            // visArray={visArray}
            templateList={tempList}
            setCurrentView={()=>1}
            setCols={setCols}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setDetailData={setDownloadItemsData}
            setShowList={setShowList}
            sorting={sorting}
            setSorting={setSorting}
          />
        </div>
      </div>
      </>}
    </div>
  );
};
export default DownloadItemSectionList;
