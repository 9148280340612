import { Dispatch, FC, SetStateAction, useEffect, useRef, useState, forwardRef } from "react";
import { Popper } from "@mui/material"

import ReactDatePicker from "react-datepicker";
import { Cell } from "@tanstack/react-table";
import { baseObj } from "../../utils/constants";
import { useSpring, animated } from '@react-spring/web';
import { CloseIcon } from "../common/Icons";
import { renderDateFormat, renderDateFormatDMY } from "../../app/services/api";

interface IDate {
  dobDate?: Date|null;
  setDate: Dispatch<SetStateAction<Date | undefined>>;
  cell: Cell<unknown, unknown>;
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  maxDate?: Date;
  minDate?: Date;
  hasMaxDate?: boolean;
  hasMinDate?: boolean;
  isNew?: any;
  isClearDate?: boolean;
}

interface FadeProps {
  children?: React.ReactElement;
  in?: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

const Fade = forwardRef<HTMLDivElement, FadeProps>(function Fade(props: any, ref: any) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});


const DatePopup: FC<IDate> = ({ dobDate, setDate, data, setData, cell, maxDate, minDate, hasMaxDate, hasMinDate, isClearDate, isNew }) => {
  var buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(buttonRef.current);
  const [open, setOpen] = useState(true);
  const [placement, setPlacement] = useState<any>();
  const [selectedDate, setSelectedDate] = useState(dobDate);
  var dateRef = useRef<ReactDatePicker>(null);  
  var resetDate = useRef<ReactDatePicker>(null);
  const handleClick = (
    newPlacement: any,
    event: any) => {    
    setOpen(true)
    setAnchorEl(event.currentTarget);
    setPlacement(newPlacement);
    setTimeout(()=>{
      dateRef.current?.setOpen(true); 
    },100) 
  };

  const handleChangeDate = (date: Date | undefined) => {    
    setSelectedDate(date?date:undefined);
    // if(!hasMaxDate){
    // // setDate(date);
    // let dd = renderDateFormatDMY(date, "-")
    // setDate(dd)
    // }
    setDate(date);
    setData((prev: baseObj[]) => {
      return prev.map((data: baseObj, index: number) => {
        if (index === Number(cell.row.id)) {
          return { ...data, [cell.column.id]: date ? renderDateFormat(new Date(date), '/') : null };
        }
        return data;
      });
    });
  };
  const clearDate=()=>{
    setSelectedDate(undefined);
    setDate(undefined);
    // setData((prev: baseObj[]) => {
    //   return prev.map((data: baseObj, index: number) => {
    //     if (index === Number(cell.row.id)) {          
    //       return { ...data, [cell.column.id]: null };
    //     }        
    //     return data;
    //   });
    // });
  }
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'spring-popper' : undefined;

  useEffect(() => {
    setOpen(true);    
  }, [open])
  return (
    <div>
      <div>
        {
          isClearDate==true ?
            <>
              <button id="date-button"
                className="max-w-[200px] min-w-[180px] text-left px-2"
                onClick={(event) => {
                  handleClick("left", event);
                }}
              >

                {selectedDate ? renderDateFormatDMY(selectedDate, "/") : "---"}
              </button>
              {selectedDate?<button onClick={()=>clearDate()}>
                <CloseIcon color="#EE436D" width={10} height={10}/>
              </button>:''}              
            </>
            :
            <button ref={buttonRef}
              className="max-w-[200px] min-w-[180px] text-left px-2"
              onClick={(event) => {
                handleClick("left", event);
              }}
            >

              {isNew ? "---" : selectedDate ? renderDateFormatDMY(selectedDate, "/") : "--nn--"}
            </button>
        }

      </div>
      {
        // anchorEl && (
          <Popper id={id} open={open} anchorEl={anchorEl} transition placement={placement}>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps}>
                <div>
                  <ReactDatePicker
                    calendarClassName="relative"
                    popperClassName="relative"
                    ref={dateRef}
                    className="w-fit"
                    selected={dobDate}
                    // maxDate={maxDate?maxDate:new Date()}
                    maxDate={hasMaxDate ? new Date() : maxDate}
                    minDate={hasMinDate ? new Date() : minDate}
                    onChange={(prev: Date) => { handleChangeDate(prev); setOpen(false); }}
                    dateFormat={"dd MMM yyyy"}
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown={true}
                    customInput={<input className="absolute opacity-0 hidden" />}
                    onFocus={(e) => {
                      setOpen(false);
                      dateRef.current?.setOpen(false)
                    }}
                  />
                </div>
              </Fade>
            )}
          </Popper>
        // )
      }
    </div>
  );
};

export default DatePopup;
