import { FC, useEffect, useRef, useState } from "react";
import CommonLanguage from "../../components/common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
export interface IOverviewData {
  overview: IOverview;
  activeLang: string;
  setActiveLang: React.Dispatch<React.SetStateAction<string>>;
  setOverview: React.Dispatch<React.SetStateAction<IOverview>>;
}
export interface IOverview {
  title_en: string;
  title_tc: string;
  title_sc: string;

  description_en: string;
  description_tc: string;
  description_sc: string;

  competitive_compensation_icon_file: File|undefined;
  competitive_compensation_icon: string;

  competitive_compensation_title_en: string;
  competitive_compensation_title_tc: string;
  competitive_compensation_title_sc: string;

  competitive_compensation_text_en: string;
  competitive_compensation_text_tc: string;
  competitive_compensation_text_sc: string;

  amazing_coworkers_icon_file: File|undefined;
  amazing_coworkers_icon: string;

  amazing_coworkers_title_en: string;
  amazing_coworkers_title_tc: string;
  amazing_coworkers_title_sc: string;
  amazing_coworkers_description_en: string;
  amazing_coworkers_description_tc: string;
  amazing_coworkers_description_sc: string;

  contact_and_recruitment_title_en: string;
  contact_and_recruitment_title_tc: string;
  contact_and_recruitment_title_sc: string;

  contact_and_recruitment_email_en: string;
  contact_and_recruitment_phone_en: string;
  contact_and_recruitment_address_en: string;

  contact_and_recruitment_email_tc: string;
  contact_and_recruitment_phone_tc: string;
  contact_and_recruitment_address_tc: string;

  contact_and_recruitment_email_sc: string;
  contact_and_recruitment_phone_sc: string;
  contact_and_recruitment_address_sc: string;

  image: File|undefined;
  image_url: string;
}
const OverviewSection: FC<IOverviewData> = ({
  overview,
  activeLang,
  setActiveLang,
  setOverview
}) => {
  const description_en_Ref = useRef();
  const [data, setData] = useState<IOverview>(overview);
  useEffect(() => {
    if (overview) {
      setData(overview);
    }
  }, [overview]);
  const change_data = (value: any, key: string) => {
    if (overview) {
      if(key=="competitive_compensation_icon_file"){
        setOverview((prev)=>{
          return {
            ...prev,
            competitive_compensation_icon_file:value,
            competitive_compensation_icon:value?URL.createObjectURL(value):''
          }
        })
        return;
      }
      if(key=="amazing_coworkers_icon_file"){
        setOverview((prev)=>{
          return {
            ...prev,
            amazing_coworkers_icon_file:value,
            amazing_coworkers_icon:value?URL.createObjectURL(value):''
          }
        })
        return;
      }
      if(key=="amazing_coworkers_icon_file"){
        setOverview((prev)=>{
          return {
            ...prev,
            amazing_coworkers_icon_file:value,
            amazing_coworkers_icon:value?URL.createObjectURL(value):''
          }
        })
        return;
      }
      if(key=="image"){
        setOverview((prev)=>{
          return {
            ...prev,
            image:value,
            image_url:value?URL.createObjectURL(value):''
          }
        })
        return;
      }
      setOverview({ ...overview, [key]: value });
    }
  };
  return (
    <div>
      <div className="mt-5">
        <div
          className={
            "space-y-5" +
            twJoin(
              activeLang == "en"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )
          }
        >
          <LabelAndTextbox
            label="Title (EN)"
            value={data?.title_en}
            setValue={(value) => change_data(value, "title_en")}
          />
          <LabelAndEditor
            refId={description_en_Ref}
            label="Description (EN)"
            value={data?.description_en}
            setValue={(value) => change_data(value, "description_en")}
          />

          <LabelAndTextbox
            label="Competitive Compensation Title (EN)"
            value={data?.competitive_compensation_title_en}
            setValue={(value) =>
              change_data(value, "competitive_compensation_title_en")
            }
          />
          <LabelAndTextbox
            label="Competitive Compensation Text (EN)"
            value={data?.competitive_compensation_text_en}
            setValue={(value) =>
              change_data(value, "competitive_compensation_text_en")
            }
          />
           <LabelAndTextbox
            label="Amazing Coworker Title (EN)"
            value={data?.amazing_coworkers_title_en}
            setValue={(value) =>
              change_data(value, "amazing_coworkers_title_en")
            }
          />
          <LabelAndTextbox
            label="Amazing Coworker Text (EN)"
            value={data?.amazing_coworkers_description_en}
            setValue={(value) =>
              change_data(value, "amazing_coworkers_description_en")
            }
          />
          <LabelAndTextbox
            label="Contact for Recruitment Title (EN)"
            value={data?.contact_and_recruitment_title_en}
            setValue={(value) =>
              change_data(value, "contact_and_recruitment_title_en")
            }
          />
           <LabelAndTextbox
          label="Contact Email (EN)"
          value={data?.contact_and_recruitment_email_en}
          setValue={(value) =>
            change_data(value, "contact_and_recruitment_email_en")
          }
        />
        <LabelAndTextbox
          label="Contact Phone (EN)"
          value={data?.contact_and_recruitment_phone_en}
          setValue={(value) =>
            change_data(value, "contact_and_recruitment_phone_en")
          }
        />
        <LabelAndTextbox
          label="Contact Address (EN)"
          value={data?.contact_and_recruitment_address_en}
          setValue={(value) =>
            change_data(value, "contact_and_recruitment_address_en")
          }
        />
        </div>

        <div
          className={twJoin(
            activeLang == "tc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
          <LabelAndTextbox
            label="Title (TC)"
            value={data?.title_tc}
            setValue={(value) => change_data(value, "title_tc")}
          />
          <LabelAndEditor
            refId={description_en_Ref}
            label="Description (TC)"
            value={data?.description_tc}
            setValue={(value) => change_data(value, "description_tc")}
          />

          <LabelAndTextbox
            label="Competitive Compensation Title (TC)"
            value={data?.competitive_compensation_title_tc}
            setValue={(value) =>
              change_data(value, "competitive_compensation_title_tc")
            }
          />
          <LabelAndTextbox
            label="Competitive Compensation Text (TC)"
            value={data?.competitive_compensation_text_tc}
            setValue={(value) =>
              change_data(value, "competitive_compensation_text_tc")
            }
          />
          <LabelAndTextbox
            label="Amazing Coworker Title (TC)"
            value={data?.amazing_coworkers_title_tc}
            setValue={(value) =>
              change_data(value, "amazing_coworkers_title_tc")
            }
          />
          <LabelAndTextbox
            label="Amazing Coworker Text (TC)"
            value={data?.amazing_coworkers_description_tc}
            setValue={(value) =>
              change_data(value, "amazing_coworkers_description_tc")
            }
          />
          <LabelAndTextbox
            label="Contact for Recruitment Title (TC)"
            value={data?.contact_and_recruitment_title_tc}
            setValue={(value) =>
              change_data(value, "contact_and_recruitment_title_tc")
            }
          />
          <LabelAndTextbox
          label="Contact Email (TC)"
          value={data?.contact_and_recruitment_email_tc}
          setValue={(value) =>
            change_data(value, "contact_and_recruitment_email_tc")
          }
        />
        <LabelAndTextbox
          label="Contact Phone (TC)"
          value={data?.contact_and_recruitment_phone_tc}
          setValue={(value) =>
            change_data(value, "contact_and_recruitment_phone_tc")
          }
        />
        <LabelAndTextbox
          label="Contact Address (TC)"
          value={data?.contact_and_recruitment_address_tc}
          setValue={(value) =>
            change_data(value, "contact_and_recruitment_address_tc")
          }
        />
        </div>
        <div
          className={twJoin(
            activeLang == "sc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
          <LabelAndTextbox
            label="Title (SC)"
            value={data?.title_sc}
            setValue={(value) => change_data(value, "title_sc")}
          />
          <LabelAndEditor
            refId={description_en_Ref}
            label="Description (SC)"
            value={data?.description_sc}
            setValue={(value) => change_data(value, "description_sc")}
          />

          <LabelAndTextbox
            label="Competitive Compensation Title (SC)"
            value={data?.competitive_compensation_title_sc}
            setValue={(value) =>
              change_data(value, "competitive_compensation_title_tc")
            }
          />
          <LabelAndTextbox
            label="Competitive Compensation Text (SC)"
            value={data?.competitive_compensation_text_sc}
            setValue={(value) =>
              change_data(value, "competitive_compensation_text_sc")
            }
          />
          <LabelAndTextbox
            label="Amazing Coworker Title (SC)"
            value={data?.amazing_coworkers_title_sc}
            setValue={(value) =>
              change_data(value, "amazing_coworkers_title_sc")
            }
          />
          <LabelAndTextbox
            label="Amazing Coworker Text (SC)"
            value={data?.amazing_coworkers_description_sc}
            setValue={(value) =>
              change_data(value, "amazing_coworkers_description_sc")
            }
          />
          <LabelAndTextbox
            label="Contact for Recruitment Title (SC)"
            value={data?.contact_and_recruitment_title_sc}
            setValue={(value) =>
              change_data(value, "contact_and_recruitment_title_sc")
            }
          />
          <LabelAndTextbox
          label="Contact Email (SC)"
          value={data?.contact_and_recruitment_email_sc}
          setValue={(value) =>
            change_data(value, "contact_and_recruitment_email_sc")
          }
        />
        <LabelAndTextbox
          label="Contact Phone (SC)"
          value={data?.contact_and_recruitment_phone_sc}
          setValue={(value) =>
            change_data(value, "contact_and_recruitment_phone_sc")
          }
        />
        <LabelAndTextbox
          label="Contact Address (SC)"
          value={data?.contact_and_recruitment_address_sc}
          setValue={(value) =>
            change_data(value, "contact_and_recruitment_address_sc")
          }
        />
        </div>

        <LabelAndImage
          margin="mt-5"
          setFile={(value: any) =>
            change_data(value, "competitive_compensation_icon_file")
          }
          label="Competitive Compensation Icon"
          value={data?.competitive_compensation_icon}
          setValue={(value: any) =>
            change_data(value, "competitive_compensation_icon_file")
          }
        />
        <LabelAndImage
          margin="my-5"
          setFile={(value: any) => change_data(value, "amazing_coworkers_icon_file")}
          label="Amazing Coworkers Icon"
          value={data?.amazing_coworkers_icon}
          setValue={(value: any) =>
            change_data(value, "amazing_coworkers_icon_file")
          }
        />
       
        <LabelAndImage
          margin="mt-5"
          setFile={(value: any) => change_data(value, "image")}
          label="Side Image"
          value={data?.image_url}
          setValue={(value: any) => change_data(value, "image")}
        />
      </div>
    </div>
  );
};
export default OverviewSection;
