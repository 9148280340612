// import { EmployeeResponse } from './project';
import {
  EmployeeData,
  EmployeeListData,
  StoreEmployeeType,
} from "../../components/EmployeeTable/EmployeeTable";
import { redirectToLogin } from "./annoucement-sheet";
import { api, endpointUrl, language } from "./api";
import authHeader from "./auth-header";
import { DropdownDataWithLanguage } from "./departments";
export type EmployeeResponse = { data: Employee[] };
export default function fileAuthHeader() {
  const userStr = localStorage.getItem("userData");
  let user = null;
  if (userStr) user = JSON.parse(userStr);
  if (user && user.accessToken) {
    return {
      Authorization: `Bearer ${user.accessToken}`,
      Accept: `application/json`,
      "Content-Type": "multipart/form-data",
    };
    //return { 'x-access-token': user.token };
  } else {
    return { Authorization: "" };
  }
}

export type EmployeeListGet = {
  module_id: number;
};
export type CustomEmployeeData = {
  employeeList: CustomEmployeeList[];
};
export type CustomEmployeeList = {
  id: number;
  name: string;
  year_w_vo: number;
  age: number;
  department: string;
  designation: string;
  country: string;
};
export type Employee = {
  // module_id: number;
  // employeeList: EmployeeListData[];
  employeeList: EmployeeListData[];
  statusListData: string[];
  departmentData: EmployeeData[];
  designationData: EmployeeData[];
  nationalityListData: EmployeeData[];
  memberListData: EmployeeData[];
  genderListData: string[];
  currencyListData: CurrencyDataType[];
};
type EmployeeDataList = {
  employeeList: Employee;
};
export type TemplateData = {
  id: number;
  name: string;
  viewColumns: string[];
};
export type CurrencyDataType = {
  id: number;
  currency_name: string;
  currency_code: string;
};
export type DropdownData = {
  id: number;
  name: string;
};

export type CurrencyDropdownData = {
  id: number;
  currency_name: string;
  currency_code: string;
};
export type dropdownDesignationList = {
  designationList: EmployeeData[];
};
export type dropdownDepartmentList = {
  departmentList: EmployeeData[];
};
export type dropdownCurrencyList = {
  currencies: CurrencyData[];
};
export type currencyDataType = {
  currencies: CurrencyData[];
};
export type CurrencyData = {
  created_at: string;
  currency_code: string;
  currency_name: string;
  deleted_at: string;
  updated_at: string;
  id: number;
};
export type EmailData = {
  id: number;
  subject: string;
  body: string;
};
export type dropdownCountryList = {
  countryList: EmployeeData[];
};
export type ImportData = {
  file: any;
  isReplaced: boolean;
};

export interface ListResponse<T> {
  // permissions: T;
  module_id: number;
  // employeeList: EmployeeListData[];
  employeeList: EmployeeDataWithPagination;
  statusListData: string[];
  departmentData: EmployeeData[];
  designationData: EmployeeData[];
  nationalityListData: EmployeeData[];
  memberListData: EmployeeData[];
  genderListData: string[];
  currencyListData: CurrencyDataType[];
}

export type EmployeeDataWithPagination = {
  current_page: number;
  from: number;
  to: number;
  total: number;
  per_page: number;
  data: EmployeeListData[];
  first_page_url: string;
  last_page_url: string;
  next_page_url: string;
};

export type PaginationData = {
  page: number;
  department: number | string | undefined;
  nationality: number | string | undefined;
  status: string | string | undefined;
  limit: number | string | undefined;
  search: string | number;
  sort?: string;
};
export const checkedIsNew = (data?: any) => {
  if (!data?.data) {
    var isHaveNew = data?.find((x: any) => x?.isNew == true);
    var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
    var disabled = isHaveNew ? true : false;
    return { disabledClass, disabled };
  }
};

export const employeedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEmployeeData: build.query<
      ListResponse<EmployeeDataWithPagination>,
      PaginationData
    >({
      query: ({ department, nationality, status, limit, page, search, sort }) =>
        ({
          url: `${endpointUrl}employeeListData?department=${department}&nationality=${nationality}&status=${status}&limit=${limit}&page=${page}&search=${search}&sort=${sort}`,
          headers: authHeader(),
          keepalive: false,
        }),
      keepUnusedDataFor: 0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      providesTags: (result: any = []) => {
        if (result.length > 0) {
          return [
            ...result?.employeeList?.data?.map(
              (id: any) => ({ type: "EmployeeListData", id } as const)
            ),
            { type: "EmployeeListData" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "EmployeeListData" as const, id: "LIST" }];
        }
      },
    }),
    getEmployeeFilterData: build.query<
      ListResponse<EmployeeDataWithPagination>,
      void
    >({
      query: () => ({
        url: `${endpointUrl}employeeListData-filter-data`,
        headers: authHeader(),
        keepalive: false,
      }),
      keepUnusedDataFor: 0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      providesTags: (result: any = []) => {
        if (result.length > 0) {
          return [
            ...result?.employeeList?.data?.map(
              (id: any) => ({ type: "EmployeeListFilterData", id } as const)
            ),
            { type: "EmployeeListFilterData" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "EmployeeListFilterData" as const, id: "LIST" }];
        }
      },
    }),

    getEmployeeList: build.query<CustomEmployeeData, void>({
      query: () => ({
        url: `${endpointUrl}getEmployeeList`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getEmployeeAllList: build.query<CustomEmployeeData, void>({
      query: () => ({
        url: `${endpointUrl}getEmployeeList`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getDepartmentList: build.query<EmployeeData[], void>({
      query: () => ({
        url: `${endpointUrl}departments`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: dropdownDepartmentList) => res.departmentList,
      providesTags: (result = []) => {
        if (result.length > 0) {
          return [
            ...result.map(() => ({ type: "DepartmentList" } as const)),
            { type: "DepartmentList" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "DepartmentList" as const, id: "LIST" }];
        }
      },
    }),
    getDesignationList: build.query<EmployeeData[], void>({
      query: () => ({
        url: `${endpointUrl}designations`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: dropdownDesignationList) => res.designationList,
      providesTags: (result = []) => {
        if (result.length > 0) {
          return [
            ...result.map(() => ({ type: "DesignationList" } as const)),
            { type: "DesignationList" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "DesignationList" as const, id: "LIST" }];
        }
      },
    }),
    getCurrencyList: build.query<CurrencyData[], void>({
      query: () => ({
        url: `${endpointUrl}currencies`,
        headers: authHeader(),
      }),
      transformResponse: (res: currencyDataType) => res.currencies,
      providesTags: (result = []) => {
        if (result.length > 0) {
          return [
            ...result.map(() => ({ type: "CurrencyList" } as const)),
            { type: "CurrencyList" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "CurrencyList" as const, id: "LIST" }];
        }
      },
    }),
    getCountryList: build.query<EmployeeData[], void>({
      query: () => ({
        url: `${endpointUrl}countries`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: dropdownCountryList) => res.countryList,
      providesTags: (result = []) => {
        if (result.length > 0) {
          return [
            ...result.map(() => ({ type: "ContryList" } as const)),
            { type: "ContryList" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "ContryList" as const, id: "LIST" }];
        }
      },
    }),
    getTemplateList: build.query<TemplateData[], void>({
      query: () => ({
        url: `http://localhost:3004/employeeTemplates`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    createEmployee: build.mutation<EmployeeListData, Partial<any>>({
      query: (body) => {
        return {
          url: `${endpointUrl}employees`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (employees) => [
        employees
          ? { type: "EmployeeListData", id: employees?.id }
          : { type: "EmployeeListData" },
      ],
    }),

    updateEmployee: build.mutation<
      EmployeeListData,
      Partial<StoreEmployeeType>
    >({
      query: (body) => {
        return {
          url: `${endpointUrl}employees/${body.id}`,
          method: "PUT",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (employees, result, error, arg) => {
        if (result?.status !== 422) {
          return [
            employees
              ? { type: "EmployeeListData", id: employees?.id }
              : { type: "EmployeeListData" },
          ];
        } else {
          return [];
        }
      },
      //invalidatesTags: [{ type: "EmployeeListData", id: "LIST" }],
      //invalidatesTags: (employees) => [{ type: 'EmployeeListData', id:employees?.id  }],
    }),

    deleteTemplateData: build.mutation<
      { success: boolean; id: number },
      number
    >({
      query(id) {
        return {
          url: `/employee/deleteTemplate/${id}`,
          method: "DELETE",
          headers: authHeader(),
        };
      },
    }),

    deleteEmployee: build.mutation<Employee, number>({
      query(ids) {
        return {
          headers: authHeader(),
          url: `/deleteEmployees`,
          body: { ids },
          method: "DELETE",
        };
      },
      invalidatesTags: (employees) => {
        return [{ type: "EmployeeListData", id: "LIST" }];
      },
    }),

    deleteEmp: build.mutation<Employee, number>({
      query(id) {
        return {
          url: `/deleteEmployee/${id}`,
          method: "DELETE",
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "EmployeeListData", id: "LIST" }],
    }),

    createDepartment: build.mutation<DropdownData, DropdownDataWithLanguage>({
      query: (body) => {
        return {
          url: `${endpointUrl}departments`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "DepartmentList", id: data?.id }],
    }),
    removeDepartment: build.mutation<DropdownData, number>({
      query: (body) => {
        return {
          url: `${endpointUrl}deleteDepartment/${body}`,
          method: "DELETE",
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "DepartmentList", id: data?.id }],
    }),
    createDesignation: build.mutation<DropdownData, Partial<DropdownData>>({
      query: (body) => {
        return {
          url: `${endpointUrl}designations`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "DesignationList", id: data?.id }],
    }),
    createCurrency: build.mutation<
      CurrencyDropdownData,
      Partial<CurrencyDropdownData>
    >({
      query: (body) => {
        return {
          url: `${endpointUrl}currencies`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "CurrencyList", id: data?.id }],
    }),
    removeCurrency: build.mutation<CurrencyDropdownData, number>({
      query: (body) => {
        return {
          url: `${endpointUrl}deleteCurrency/${body}`,
          method: "DELETE",
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "CurrencyList", id: data?.id }],
    }),
    createCountry: build.mutation<DropdownData, Partial<DropdownData>>({
      query: (body) => {
        return {
          url: `${endpointUrl}countries`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "ContryList", id: data?.id }],
    }),
    removeCountry: build.mutation<DropdownData, number>({
      query: (body) => {
        return {
          url: `${endpointUrl}deleteCountry/${body}`,
          method: "DELETE",
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "ContryList", id: data?.id }],
    }),
    removeDesignation: build.mutation<DropdownData, number>({
      query: (body) => {
        return {
          url: `${endpointUrl}deleteDesignation/${body}`,
          method: "DELETE",
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "DesignationList", id: data?.id }],
    }),
    importEmployee: build.mutation<EmployeeListData, any>({
      query: (body) => {
        return {
          url: `${endpointUrl}importEmployees`,
          method: "POST",
          body,
          headers: fileAuthHeader(),
          // formData: true,
          prepareHeaders: (headers: any) => {
            headers.set("Content-Type", "multipart/form-data");
            return headers;
          },
        };
      },
      invalidatesTags: (data) => [{ type: "EmployeeListData", id: data?.id }],
    }),
    sendEmail: build.mutation<EmployeeListData, Partial<EmailData>>({
      query: (body) => {
        return {
          url: `${endpointUrl}employees/${body.id}/sendEmail`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "EmployeeListData", id: data?.id }],
    }),

    enableEmployee: build.mutation<Employee, { id: number }>({
      query({ id }) {
        return {
          headers: authHeader(),
          url: `/employees/${id}/agent-install-status`,
          // body: { ids },
          method: "PATCH",
        };
      },
      invalidatesTags: (employees) => {
        return [{ type: "EmployeeListData", id: "LIST" }];
      },
    }),
  }),
});

export const {
  useEnableEmployeeMutation,
  useGetEmployeeListQuery,
  useGetEmployeeDataQuery,
  useDeleteTemplateDataMutation,
  useDeleteEmployeeMutation,
  useDeleteEmpMutation,
  useGetTemplateListQuery,
  useUpdateEmployeeMutation,
  useCreateEmployeeMutation,
  useCreateDepartmentMutation,
  useCreateDesignationMutation,
  useGetDepartmentListQuery,
  useGetDesignationListQuery,
  useGetCountryListQuery,
  useGetCurrencyListQuery,
  useCreateCountryMutation,
  useCreateCurrencyMutation,
  useImportEmployeeMutation,
  useSendEmailMutation,
  useRemoveCountryMutation,
  useRemoveCurrencyMutation,
  useRemoveDepartmentMutation,
  useRemoveDesignationMutation,
  useGetEmployeeFilterDataQuery,
  useGetEmployeeAllListQuery
} = employeedApi;

export const {
  endpoints: {
    enableEmployee,
    getEmployeeList,
    getEmployeeData,
    deleteTemplateData,
    deleteEmployee,
    deleteEmp,
    getTemplateList,
    updateEmployee,
    createEmployee,
    createDepartment,
    createDesignation,
    getDepartmentList,
    getDesignationList,
    getCurrencyList,
    getCountryList,
    createCurrency,
    createCountry,
    importEmployee,
    sendEmail,
    removeCountry,
    removeCurrency,
    removeDepartment,
    removeDesignation,
    getEmployeeFilterData,
    getEmployeeAllList
  },
} = employeedApi;
