import { FC, useEffect, useState } from "react";
import DebounceSearchInput from "../../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../../components/common/Icons";
import Button from "../../../components/common/Button";
import { NumberDropdownList } from "../../../components/common/DropdownList";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import ServiceSectionTable from "./ServiceSectionTable";
import { baseObj } from "../../../utils/constants";
import { templateList } from "../../../active-time-report/views/ActiveTimeReport";
import { useGetHomeServicesQuery } from "../../../app/services/home/services";
import { IServiceInfoData } from "../../components/Services";
interface IService {
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  setServicesData: React.Dispatch<React.SetStateAction<IServiceInfoData>>
}
const ServiceSectionList: FC<IService> = ({ setShowList, setServicesData }) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });
  const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
    false
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [data, setData] = useState<baseObj[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [tempList, setTempList] = useState<any>(templateList);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [viewCols, setCols] = useState<any>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const { data: services } = useGetHomeServicesQuery({
    page: currentPage,
    limit: pagination.pageSize,
    search: '',
    sort: sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(','),
  });
  useEffect(() => {
    if (services) {
      setData(services?.data ?? []);
      setItemCount(services?.total);
    }
  }, [services])
  const clear_data = () => {
    setServicesData({
      id: 0,
      title_en: '',
      title_zh: '',
      title_cn: '',
      image_en: undefined,
      image_en_url: '',
      image_zh: undefined,
      image_zh_url: '',
      image_cn: undefined,
      image_cn_url: '',
      image: undefined,
      image_url: ''
    })
  };
  return (
    <div className="bg-white rounded-xl pb-4 sm:pb-9 w-full h-full font-primary">
      <div className="flex flex-wrap justify-between items-center mb-1">
        <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] my-1">
          <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
            {setState =>
              <div className="flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                <input
                  type="text"
                  placeholder="Search"
                  className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                  onChange={e => setState(e.target.value)}
                />
                <SearchIcon className="mr-2" />
              </div>}
          </DebounceSearchInput>
        </div>

        <div className="flex items-center xs:mt-1 sm:mt-1">
          <Button
            label="+ Add New"
            onClick={() => { clear_data(); setShowList(false) }}
            type="button"
            variant="primary"
            customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
            size="base"
          />
        </div>
      </div>

      <div className="flex sm:flex-row flex-col justify-end mb-1">
        <div className="flex items-center sm:justify-start justify-end">
          <div className="flex items-center 3xl:ml-3">
            <p className="xl:text-sm text-12 mr-2 text-black2 font-primary">
              DISPLAY
            </p>
            <NumberDropdownList
              state={pagination.pageSize}
              setState={value =>
                setPagination(prev => ({
                  ...prev,
                  pageSize: value as number
                }))}
              dropdownItems={new Array(5).fill(1).map((_, index: number) => {
                const count = (index + 1) * 20;
                return {
                  value: count,
                  label: String(count)
                };
              })}
              className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
              textAlign="text-left pl-3"
              paddingClass="py-[2px]"
              placeholder="All"
            />
          </div>
        </div>
      </div>

      <div>
        <div className="text-left">
          <ServiceSectionTable
            // columnsData={columnsData}
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            pagination={pagination}
            data={data}
            setData={setData}
            setPagination={setPagination}
            hiddenFields={hiddenFields ? hiddenFields : []}
            setHiddenFields={setHiddenFields}
            checkManagement={true}
            total={itemCount}
            localModuleId={localModuleId}
            // visArray={visArray}
            templateList={tempList}
            setCurrentView={() => 1}
            setCols={setCols}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setServicesData={setServicesData}
            setShowList={setShowList}
            sorting={sorting}
            setSorting={setSorting}
          />
        </div>
      </div>
    </div>
  );
};
export default ServiceSectionList;
