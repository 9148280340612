import {
  ReactElement,
  ReactNode,
  useEffect,
  useState,
  forwardRef
} from "react";
import styles from "./BasicLayout.module.scss";
import logo from "../../login/img/companyLogo.svg";
import arrow from "./img/Arrow-double-left-active.svg";
import menuright from "./img/submenu-righ.svg";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import UserInfoPopup from "../../components/UserInfoPopup/UserInfoPopup";
import {
  Box,
  ClickAwayListener,
  Popper,
  PopperPlacementType,
} from "@mui/material";
//import axiosCustom from "axios";
import { endpointUrl } from "../../app/services/api";
import authHeader from "../../app/services/auth-header";
import axios from "axios";
import { logout } from "../../app/services/auth.service";
import SidebarFunc from "./SidebarFunc";
import { useSpring, animated } from "react-spring";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { RootState } from "../../app/store";
import { twJoin } from "tailwind-merge";
// import {
//   // TNotification,
//   // useGetLastedNotiQuery,
// } from "../../app/services/notification";
// import NotificationPopup from "../../components/Notification/NotificationPopup";

var classNames = require("classnames");

interface FadeProps {
  children?: React.ReactElement;
  in?: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

export type DefaultLayoutProps = {
  children: ReactElement | ReactNode | null;
  userData?: any;
  permissions?: any;
  hideLeftSidebar?: boolean;
  sidebars: any;
};
export type UserDataProps = {
  user: string;
  role: string;
  permissionData: any[];
};

var userDatas = localStorage.getItem("userData");
var userJson: any = {};
if (userDatas) {
  userJson = JSON.parse(userDatas);
  userJson.token = userJson.accessToken;
}

var getUser = userJson;
const getAuthPermissions: string[] = [];

if (getUser?.permissions) {
  getUser?.permissions?.forEach((per: any) => {
    getAuthPermissions.push(per?.name);
  });
}

const Fade = forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props: any,
  ref: any
) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children ?? ""}
    </animated.div>
  );
});

const BasicLayout = (props: DefaultLayoutProps): ReactElement => {
  const { userData, hideLeftSidebar, sidebars } = props;

  // const checkPermis = getAuthPermissions.includes(permissions[0])
  const [style, setStyle] = useState("");
  const [content, setContent] = useState("");
  const [menuClose, setMenuClose] = useState("hidden");
  const [active, setActiveMenu] = useState("Admin Dashboard");
  const [subActive, setSubActiveMenu] = useState("");
  const [subMenuTitle, setSubMenuTitle] = useState("");
  const [menuTitle, setMenuTitle] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);

  const [showLoginBox, setLoginBox] = useState<boolean>(false);
  const [showNotiBox, setNotiBox] = useState<boolean>(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorElNoti, setAnchorElNoti] = useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [showLogoutPopup, setLogoutPopup] = useState<boolean>(false);
  const [showChangePwPopup, setShowChangePw] = useState<boolean>(false);
  const [unlink, setUnlink] = useState<string>("");
  const [notiClick, setNotiClick] = useState<string>("");
  // const { data: notificationList } = useGetLastedNotiQuery(notiClick);
  // const [viewNoti] = useViewNotificationMutation();
  // const [markAllAsRead] = useNotificationMaskAllAsReadMutation();
  // const notificationStateData = useSelector<RootState>(
  //   (state) => state?.dashboard?.notificationDetail
  // );


  const [notifications, setNotifications] = useState<any>([]);
  const [isProjectBoard, setIsProjectBoard] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var sidebarsData = SidebarFunc();
  // var data = sidebarsData.map((x: any) => {
  //   var filter = x.subcontents.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
  //   x.subcontents = filter;
  //   return x;
  // });
  let active_data: any = [];
  //const [sidebars, setSidebars] = useState([]);
  const [showEmp, setShowEmp] = useState<boolean>(true);
  const [showAdminSetting, setShowAdminSetting] = useState<boolean>(true);
  const [showUser, setShowUser] = useState<boolean>(true);
  const [showHome, setshowHome] = useState<boolean>(true);
  const [showCompany, setshowCompany] = useState<boolean>(true);
  const [showServices, setshowServices] = useState<boolean>(true);
  const [showContactus, setshowContactUs] = useState<boolean>(true);
  const [showLatestNews, setshowLatestNews] = useState<boolean>(true);
  const [showMediaReports, setshowMediaReports] = useState<boolean>(true);
  const [showMediaResources, setshowMediaResources] = useState<boolean>(true);
  const [showJobOpenings, setshowJobOpenings] = useState<boolean>(true);
  const [showCareerPath, setshowCareerPath] = useState<boolean>(true);
  const [showCares, setShowCare] = useState<boolean>(true);
  const [showFooter, setShowFooter] = useState<boolean>(true);
  // sidebarsData?.map(s =>
  //   s.subcontents.map(sub => sub.subMenuContents?.map(m => {
  //     if (m.link == window.location.pathname) {
  //       const link = {
  //         id: s.id,
  //         subid: sub.id,
  //         submenuid: m.id,
  //         show: true
  //       }
  //       active_data.push(link)
  //     }
  //     return m.subMenuContents.map(x => {        
  //       if (x.link == window.location.pathname) {
  //         const link = {
  //           id: s.id,
  //           subid: sub.id,
  //           submenuid: m.id,
  //           show: true
  //         }
  //         active_data.push(link)
  //         return link;
  //       }
  //     }).filter(x => x != undefined)
  //   }
  //   )));
  const [showMainContent, setShowMainContent] = useState([{
    id: 1,
    subid:0,
    submenuid:0,
    show: false
  }, {
    id: 2,
    subid:0,
    submenuid:0,
    show: false
  }]);

  const [showSubContent, setShowSubContent] = useState([{
    id: 1,
    show: false,
    subid:0,
    submenuid:0,
  }]);
  const confirmEvent = () => {
    localStorage.setItem("token", "");
    deleteDatabase();
    localStorage.clear();
    setLoginBox(false);
  };

  const deleteDatabase = () => {
    const request = indexedDB.deleteDatabase("myDatabase");

    request.onsuccess = () => {
    };

    request.onerror = (event) => {
      console.error(
        "Error deleting database",
        (event.target as IDBOpenDBRequest).error
      );
    };
  };

  const logoutFun = () => {
    setShow(false);
    setLogoutPopup(true);
  };
  const changePassword = () => {
    setShow(false);
    setShowChangePw(true);
  };
  const saveChangePassword = (
    oldPW: string,
    newPW: string,
    confirmPW: string
  ) => {
    axios
      .post(
        endpointUrl + "password/change",
        {
          current_password: oldPW,
          new_password: newPW,
          password_confirmation: confirmPW,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.status) {
          setShowChangePw(false);
          setLoginBox(false);
          navigate("/login");
          logout();
        }
      })
      .catch((error) => {
        setShowChangePw(true);
        setLoginBox(true);
        setErrorPopup(true);
        setMessage(
          error?.response
            ? [error?.response?.data?.message]
            : ["Something wrong!. Trying again."]
        );
        setErrorMsg(error.response.data.message);
      });
  };
  const handleMenu = () => {
    setStyle("hidden");
    setContent(styles.leftsideHide);
    setMenuClose(styles.handleMenuCloseImg);
    document.getElementById("");
  };
  const handleMenuClose = () => {
    setContent("");
    setStyle("");
    setMenuClose("hidden");
  };

  const changeSubmenuActive = (name: string) => {
    sidebars?.map((s: any) => {
      s.subcontents.map((m: any) => {
        m.subMenuContents?.map((sub: any) => {
          if (sub.title == name) {
            if (sub.active !== "active") {
              sub.active = "active";
              if (sub.link !== "") {
                setSubMenuTitle(sub.label);
              }
            } else {
              sub.active = "";
            }
          } else {
            sub.active = "";
          }
        });
      });
    });
  };

  const [isNested, setIsNest] = useState<boolean>(false);
  const [isClickDouble, setIsDoouble] = useState<boolean>(true);

  const changeToActiveState = (name: string) => {
    setIsDoouble(!isClickDouble);
    setIsNest(!isNested);
    var activeMenu = "";
    sidebars?.map((s: any) => {
      s.subcontents.map((m: any) => {
        if (m.title == name) {
          if (m.active !== "active") {
            m.active = "active";
            activeMenu = m.title;
            if (m.link == "#") {
              setUnlink(m.title);
            } else {
              //setUnlink('');
            }
          } else {
            m.active = "";
          }
        } else {
          m.active = "";
        }
      });
    });

    setActiveMenu(name);
    setMenuTitle(activeMenu);
    setSubMenuTitle("");
  };

  const location = useLocation();
  // var currentLink = "";

  // useEffect(() => {

  //   var currentPath = location.pathname;
  //   var data = sidebarsData
  //     ?.map((x: any) =>
  //       x?.subcontents.find((sub: any) => (sub?.subMenuContents?.find((x: any) => x.link == currentPath)))
  //     )
  //     .filter((x: any) => x !== undefined);
  //   var subdata = sidebarsData
  //     ?.map((x: any) =>
  //       x.subcontents
  //         .map((sub: any) =>
  //           sub.subMenuContents?.find((sm: any) => (sm.subMenuContents.find((x: any) => x.link == currentPath)))
  //         )
  //         .filter((x: any) => x !== undefined)
  //     )
  //     .filter((x: any) => x.length > 0)[0];
  //   var activeMenu = "";
  //   var activeSubMenu = "";
  //   if (data) {
  //     if (!window.location.href.includes("#")) {
  //       const active = data[0]?.subMenuContents?.find((sm: any) => sm.link == currentPath)
  //       if (active) {
  //         setActiveMenu(active ? active.title : "");
  //         // setActiveMenu(data[0] ? data[0].title : "");
  //         setMenuTitle(active ? active.title : "");
  //         activeMenu = active ? active.title : "";
  //         activeSubMenu = "";
  //       }
  //       // setActiveMenu(data[0] ? data[0].title : "");
  //       // // setActiveMenu(data[0] ? data[0].title : "");
  //       // setMenuTitle(data[0] ? data[0].title : "");
  //       // activeMenu = data[0] ? data[0].title : "";
  //       // activeSubMenu = "";
  //     } else {
  //       setActiveMenu("");
  //       if (subdata) {
  //         const active_sub = subdata[0]?.subMenuContents?.find((sm: any) => sm.link == currentPath);
  //         if (active_sub) {
  //           setActiveMenu(active_sub ? active_sub.title : "");
  //           var data = sidebars
  //             ?.map((x: any) =>
  //               x.subcontents.find((sub: any) =>
  //                 sub.subMenuContents?.find((x: any) => x.id == active_sub?.id)
  //               )
  //             )
  //             .filter((x: any) => x != undefined);
  //           if (data) {
  //             setMenuTitle(data[0] ? data[0].title : "");
  //             activeMenu = data[0] ? data[0].title : "";
  //           }

  //           setSubMenuTitle(active_sub ? active_sub.label : "");
  //           activeSubMenu = active_sub ? active_sub.label : "";
  //         }

  //       }
  //     }
  //   }
  //   if (subdata) {
  //     setActiveMenu(subdata[0] ? subdata[0].title : "");
  //     var data = sidebars
  //       ?.map((x: any) =>
  //         x.subcontents.find((sub: any) =>
  //           sub.subMenuContents?.find((x: any) => x.id == subdata[0]?.id)
  //         )
  //       )
  //       .filter((x: any) => x != undefined);
  //     if (data) {
  //       setMenuTitle(data[0] ? data[0].title : "");
  //       activeMenu = data[0] ? data[0].title : "";
  //     }

  //     setSubMenuTitle(subdata[0] ? subdata[0].label : "");
  //     activeSubMenu = subdata[0] ? subdata[0].label : "";
  //   }

  //   sidebarsData?.map((x: any) =>
  //     x.subcontents.map((s: any) => {
  //       if (s.title == activeMenu) {
  //         s.active = "active";

  //         if (window.location.href.includes("#")) {
  //           setActiveMenu(s.title);
  //           // setMenuTitle(activeMenu);

  //           return s.subMenuContents?.map((su: any) => {
  //             if (su.label == subMenuTitle) {
  //               su.active = "active";
  //               setSubActiveMenu(su.label);
  //             }
  //           });
  //         } else {
  //           setActiveMenu("");
  //         }
  //       } else {
  //         s.active = "";
  //       }
  //       if (window.location.href.includes("#")) {
  //         if (s.title == activeMenu) {
  //           s.active = "active";
  //           setActiveMenu(s.title);
  //           setMenuTitle(s.title);
  //           return s.subMenuContents?.map((su: any) => {
  //             if (su.label == subMenuTitle) {
  //               su.active = "active";
  //               setSubActiveMenu(su.label);
  //             }
  //           });
  //         } else {
  //           s.active = "";
  //         }
  //       } else {
  //         if (s.title == activeMenu) {
  //           s.active = "active";
  //           if (s.title !== "Setting") {
  //             setShowAdminSetting(!showAdminSetting)
  //           }
  //           if (s.title !== "Employees") {
  //             setShowEmp(!showEmp);
  //           }
  //           if (s.title !== "Contact Us") {
  //             setshowContactUs(!showContactus);
  //           }
  //           if (s.title != "Backend Users") {
  //             setShowUser(!showUser);
  //           }
  //           if (s.title != 'Footer') {
  //             setShowFooter(!showFooter);
  //           }
  //           if (s.title != "CRM") {
  //             setshowHome(!showHome);
  //           }
  //           if (s.title != 'Sats Care') {
  //             setshowCareerPath(!showCareerPath);
  //           }
  //           setActiveMenu(s.title);
  //           return s.subMenuContents?.map((su: any) => {
  //             if (su.label == subMenuTitle) {
  //               su.active = "active";
  //               const lastupdate = su.subMenuContents?.find((x: any) => x.link == window.location.pathname);
  //               setSubActiveMenu(lastupdate?.label);
  //               // setSubMenuTitle(lastupdate?.label);
  //               setIsNest(true);
  //             }
  //           });
  //         }
  //       }
  //     })
  //   );
  //   setSidebars(sidebarsData);

  //   //sidebarsData.find(x=>x.)
  // }, [active, subActive, menuTitle]);
  const isActive = (path: string) => location.pathname === path;
  const handleClickAway = () => {
    setLoginBox(false);
  };
  const handleClickAwayNoti = () => {
    setNotiBox(false);
    // setNotiClick('');
  };
  const handleClick = (
    newPlacement: PopperPlacementType,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setPlacement(newPlacement);
  };
  const handleClickNoti = (
    newPlacement: PopperPlacementType,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElNoti(event.currentTarget);
    setPlacement(newPlacement);
    setNotiClick("search");
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "spring-popper" : undefined;
  const get_submenu_active = () => {
    const path = window.location.pathname.split('/');
    if (path.length > 0) {
      return path[path.length - 1]?.replaceAll('-', ' ')
    }
  }
  let active_link_data:any = [];
  const add_link = (data: any) => {
    active_link_data.push(data);
  }
  const [activeLinkData, setActiveLinkData] = useState<any[]>([]);

  useEffect(() => {
    sidebars?.forEach((s: any) => {
      s.subcontents.forEach((sub: any) => {
        sub.subMenuContents?.forEach((m: any) => {
          const isActive = m.link === window.location.pathname;
          const link = {
            id: s.id,
            subid: sub.id,
            submenuid: m.id,
            show: isActive,
            link:m.link
          };
          add_link(link);

          m.subMenuContents?.forEach((x: any) => {
            const isSubMenuActive = x.link === window.location.pathname;
            const subLink = {
              id: s.id,
              subid: sub.id,
              submenuid: m.id,
              show: isSubMenuActive,
              link:x.link
            };
            add_link(subLink);
          });
        });
      });
    });
    
    const active_link_obj=active_link_data?.find((x:any)=>x.link==location.pathname);
    if(active_link_obj) {
      setShowMainContent(prevData =>
        prevData.map(item =>
          item.id === active_link_obj.id ? { ...item, 
            show: active_link_obj.show,
            subid:active_link_obj.subid,
            submenuid:active_link_obj.submenuid } : item
        )
      );
      // setShowSubContent(prevData =>
      //   prevData.map(item =>
      //     (item.id === active_link_obj.subid) ? 
      //     { ...item, 
      //       link: active_link_obj.link,
      //       show: active_link_obj.show,
      //       subid:active_link_obj.id,
      //       submenuid:active_link_obj.submenuid } : item
      //   ).filter(x=>x!=undefined)
      // );
    }
    
  }, [sidebars]); 
  const onClickSidebar = (id: number) => {
    setShowMainContent((prev: any) => {
      return prev.map((data: any, index: number) => {
        if (data?.id === id) {
          active_data = active_data.map((item: any) => {
            return item.id === id ? { ...item, show: !item.show } : item
          });
          return { ...data, show: !data.show };
        }
        return data;
      });
    });
  }
  const click_subcontent = (id: number) => {
    setShowSubContent((prev: any) => {
      if (prev.find((x: any) => x?.id == id)) {
        return [...prev.map((data: any, index: number) => {
          if (data?.id === id) {
            return { ...data, show: !data.show };
          }
        })].filter(x=>x!=undefined);
      } else {
        return [...prev, { id: id, show: true }].filter(x=>x!=undefined)
      }

    });
  }
  document.addEventListener('click',function(event:any){    
    if(event.target?.closest('.toggle_dropdown')){
      document.querySelectorAll('.toggle_dropdown_content').forEach(function(dropdown){
        dropdown.classList.add('hidden');
      });
      event.target?.closest('.toggle_dropdown')?.nextElementSibling?.classList?.remove('hidden')
    }
  })
  
  return (
    <>
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      <ToastContainer
        className={"text-12 font-poppins font-normal text-left"}
      />
      <div className="wrapper w-full ">
        <div
          id="sidebar"
          className={classNames(
            style == "hidden" ? styles.hiddenData : styles.openData,
            hideLeftSidebar == true ? "hidden" : "",
            styles.leftSidebar,
            isProjectBoard == true ? "fixed top-0 z-[9999999]" : "",
            "  xl:max-w-[235px] md:max-w-[227px] max-w-[200px] w-full h-full overflow-y-auto bg-white fixed left-0 z-10 border-r-[1px] border-r-[#EEEFF3]"
          )}
        >
          <div className="flex justify-between 2xl:px-6 px-3 py-1 border-b-[1px] border-b-[#EEEFF3] mb-9 h-[64px]">
            <a href="/" className="w-full focus-visible:outline-none h-full">
              <img
                src={logo}
                alt="Logo-default"
                className="xl:max-w-full max-w-[150px] cursor-pointer mx-auto  h-full"
              />
            </a>
            <img
              src={arrow}
              alt="Arrow-double-left-active"
              className={classNames("cursor-pointer max-w-[24px]")}
              onClick={() => handleMenu()}
            />
          </div>

          <div className="px-[15px] transition-all duration-1000">
            {sidebarsData?.map((sidebar: any, index: any) => (
              <div key={index}>
                <p
                  className={classNames(
                    sidebar?.subcontents.length <= 0 ? "hidden" : "",
                    "mt-[25px] uppercase md:pl-[21px] pl-2 font-poppins md:text-[13px] text-[12px] font-normal text-[#1B3757] text-left mb-[6px]"
                  )}
                >
                  {sidebar.title}
                </p>
                <ul>
                  {sidebar?.subcontents?.map((subcontent: any, key: any) => {
                    const active_tab = showMainContent?.find(x => x.id == sidebar.id);
                    return <div key={key}>
                      <div onClick={() => {
                        onClickSidebar(sidebar.id)
                      }} className="flex items-center toggle_dropdown_main cursor-pointer pb-5 pt-2">
                        <p className="mr-3 font-poppins pl-5 md:text-sm text-[12px] text-[#858795]">{subcontent.title}</p>
                        <img src={menuright} className="rotate-90" />
                      </div>
                      <ul className={twJoin((active_tab?.show == true ? '' : 'hidden'), "pl-5 toggle_dropdown_main_content")} key={key}>
                        {subcontent?.subMenuContents?.map((submenu: any, key: any) => {
                          const active_subtab = showSubContent?.find(x => (x.id==submenu.id));  
                          const active = (window.location.pathname == submenu.link ? 'text-vorpblue' : 'text-[#858795]');
                          const is_active_link = submenu?.subMenuContents?.find((x: any) => x.link == window.location.pathname);
                          return <li key={key} className={twJoin(active, "text-left mb-4 list-none font-poppins md:text-sm text-[12px] ")}>
                            {submenu?.subMenuContents?.length > 0 ?
                              <div className="toggle_dropdown_sub">
                                <div onClick={() => click_subcontent(submenu.id)} className="flex items-center toggle_dropdown cursor-pointer">
                                  <img src={submenu.icon} alt="" className="mr-2 max-w-[20px]" />
                                  <p className="mr-3">{submenu.title}</p> <img src={menuright} className="rotate-90" />
                                </div>
                                <ul className={
                                  twJoin(is_active_link?is_active_link:(active_subtab ? (active_subtab.show == true) ? '' : 'hidden' : 'hidden'),
                                 "pl-7 py-5 toggle_dropdown_content "
                                 )
                                 }>
                                  {submenu.subMenuContents?.map((submenu_sub: any, key: number) => {
                                    const sub_active = (window.location.pathname == submenu_sub.link ? 'text-vorpblue' : 'text-[#858795]')
                                    
                                    return submenu_sub.title != "" ? <li key={key} className={twJoin(sub_active, "text-left mb-3 last:mb-0 list-disc font-poppins md:text-sm text-[12px] ")}>
                                      <Link className={twJoin(isActive(submenu.link) ? 'text-vorpblue' : '')} to={submenu_sub.link}>{submenu_sub.title}</Link>
                                    </li> : ''

                                  })}
                                </ul>
                              </div>
                              : <div className="flex items-center">
                                <img src={submenu.icon} alt="" className="mr-2 max-w-[20px]" />
                                <Link className={twJoin(isActive(submenu.link) ? 'text-vorpblue' : '', submenu.title != "" ? "" : "hidden")} to={submenu.link}>{submenu.title}</Link>
                              </div>}
                          </li>
                        })}
                      </ul>
                    </div>

                    // return (
                    //   <div
                    //     key={key}
                    //     className={classNames(
                    //       subcontent.link == "/profile"
                    //         ? userData?.user?.employee_id == 0
                    //           ? "hidden"
                    //           : ""
                    //         : ""
                    //     )}
                    //   >
                    //     <li
                    //       onClick={() => {
                    //         changeToActiveState(subcontent.title);
                    //         if (subcontent.title == "Setting") {
                    //           setShowEmp(false);
                    //           setShowUser(false);
                    //           setshowHome(false);
                    //           setshowCompany(false);
                    //           setshowServices(false);
                    //           setshowLatestNews(false);
                    //           setshowMediaReports(false);
                    //           setshowMediaResources(false);
                    //           setshowJobOpenings(false);
                    //           setshowCareerPath(false);
                    //           setShowFooter(false);
                    //           setshowContactUs(false);
                    //           setShowCare(false);
                    //           setShowAdminSetting(true);
                    //         }
                    //         if (subcontent.title == "Employees") {
                    //           setShowEmp(true);
                    //           setShowUser(false);
                    //           setshowHome(false);
                    //           setshowCompany(false);
                    //           setshowServices(false);
                    //           setshowLatestNews(false);
                    //           setshowMediaReports(false);
                    //           setshowMediaResources(false);
                    //           setshowJobOpenings(false);
                    //           setshowCareerPath(false);
                    //           setShowFooter(false);
                    //           setshowContactUs(false);
                    //           setShowCare(false);
                    //         }
                    //         if (subcontent.title == "Contact Us") {
                    //           setshowContactUs(true);
                    //           setShowEmp(false);
                    //           setShowUser(false);
                    //           setshowHome(false);
                    //           setshowCompany(false);
                    //           setshowServices(false);
                    //           setshowLatestNews(false);
                    //           setshowMediaReports(false);
                    //           setshowMediaResources(false);
                    //           setshowJobOpenings(false);
                    //           setshowCareerPath(false);
                    //           setShowFooter(false);
                    //           setShowCare(false);
                    //         }
                    //         if (subcontent.title == "Backend Users") {
                    //           setshowContactUs(false);
                    //           setShowUser(true);
                    //           setShowEmp(false);
                    //           setshowHome(false);
                    //           setshowCompany(false);
                    //           setshowServices(false);
                    //           setshowLatestNews(false);
                    //           setshowMediaReports(false);
                    //           setshowMediaResources(false);
                    //           setshowJobOpenings(false);
                    //           setshowCareerPath(false);
                    //           setShowFooter(false);
                    //           setShowCare(false);
                    //         }
                    //         if (subcontent.title == "Home") {
                    //           setshowContactUs(false);
                    //           setshowHome(true);
                    //           setShowUser(false);
                    //           setShowEmp(false);
                    //           setshowCompany(false);
                    //           setshowServices(false);
                    //           setshowLatestNews(false);
                    //           setshowMediaReports(false);
                    //           setshowMediaResources(false);
                    //           setshowJobOpenings(false);
                    //           setshowCareerPath(false);
                    //           setShowFooter(false);
                    //           setShowCare(false);
                    //         }
                    //         if (subcontent.title == "Company") {
                    //           setshowContactUs(false);
                    //           setshowHome(false);
                    //           setShowUser(false);
                    //           setShowEmp(false);
                    //           setshowCompany(true);
                    //           setshowServices(false);
                    //           setshowLatestNews(false);
                    //           setshowMediaReports(false);
                    //           setshowMediaResources(false);
                    //           setshowJobOpenings(false);
                    //           setshowCareerPath(false);
                    //           setShowFooter(false);
                    //           setShowCare(false);
                    //         }
                    //         if (subcontent.title == "Services") {
                    //           setshowContactUs(false);
                    //           setshowHome(false);
                    //           setShowUser(false);
                    //           setShowEmp(false);
                    //           setshowCompany(false);
                    //           setshowServices(true);
                    //           setshowLatestNews(false);
                    //           setshowMediaReports(false);
                    //           setshowMediaResources(false);
                    //           setshowJobOpenings(false);
                    //           setshowCareerPath(false);
                    //           setShowFooter(false);
                    //           setShowCare(false);
                    //         }
                    //         if (subcontent.title == "Latest News") {
                    //           setshowContactUs(false);
                    //           setshowHome(false);
                    //           setShowUser(false);
                    //           setShowEmp(false);
                    //           setshowCompany(false);
                    //           setshowServices(false);
                    //           setshowLatestNews(true);
                    //           setshowMediaReports(false);
                    //           setshowMediaResources(false);
                    //           setshowJobOpenings(false);
                    //           setshowCareerPath(false);
                    //           setShowFooter(false);
                    //           setShowCare(false);
                    //         }
                    //         if (subcontent.title == "Media Reports") {
                    //           setshowContactUs(false);
                    //           setshowHome(false);
                    //           setShowUser(false);
                    //           setShowEmp(false);
                    //           setshowCompany(false);
                    //           setshowServices(false);
                    //           setshowLatestNews(false);
                    //           setshowMediaReports(true);
                    //           setshowMediaResources(false);
                    //           setshowJobOpenings(false);
                    //           setshowCareerPath(false);
                    //           setShowFooter(false);
                    //           setShowCare(false);
                    //         }
                    //         if (subcontent.title == "Media Resources") {
                    //           setshowContactUs(false);
                    //           setshowHome(false);
                    //           setShowUser(false);
                    //           setShowEmp(false);
                    //           setshowCompany(false);
                    //           setshowServices(false);
                    //           setshowLatestNews(false);
                    //           setshowMediaReports(false);
                    //           setshowMediaResources(true);
                    //           setshowJobOpenings(false);
                    //           setshowCareerPath(false);
                    //           setShowFooter(false);
                    //           setShowCare(false);
                    //         }
                    //         if (subcontent.title == "Job Opportunities") {
                    //           setshowContactUs(false);
                    //           setshowHome(false);
                    //           setShowUser(false);
                    //           setShowEmp(false);
                    //           setshowCompany(false);
                    //           setshowServices(false);
                    //           setshowLatestNews(false);
                    //           setshowMediaReports(false);
                    //           setshowMediaResources(false);
                    //           setshowJobOpenings(true);
                    //           setshowCareerPath(false);
                    //           setShowFooter(false);
                    //           setShowCare(false);
                    //         }
                    //         if (
                    //           subcontent.title == "Sats Care"
                    //         ) {
                    //           setshowContactUs(false);
                    //           setshowHome(false);
                    //           setShowUser(false);
                    //           setShowEmp(false);
                    //           setshowCompany(false);
                    //           setshowServices(false);
                    //           setshowLatestNews(false);
                    //           setshowMediaReports(false);
                    //           setshowMediaResources(false);
                    //           setshowJobOpenings(false);
                    //           setshowCareerPath(false);
                    //           setShowFooter(false);
                    //           setShowCare(true);
                    //         }
                    //         if (
                    //           subcontent.title == "Footer"
                    //         ) {
                    //           setshowContactUs(false);
                    //           setshowHome(false);
                    //           setShowUser(false);
                    //           setShowEmp(false);
                    //           setshowCompany(false);
                    //           setshowServices(false);
                    //           setshowLatestNews(false);
                    //           setshowMediaReports(false);
                    //           setshowMediaResources(false);
                    //           setshowJobOpenings(false);
                    //           setshowCareerPath(false);
                    //           setShowFooter(true);
                    //           setShowCare(false);
                    //         }
                    //       }}
                    //       key={subcontent.id}
                    //       className={classNames(
                    //         subcontent.link == ""
                    //           ? "opacity-70 pointer-events-none"
                    //           : "",
                    //         subcontent.link == "#"
                    //           ? unlink == subcontent.title
                    //             ? styles.menuActive
                    //             : active == subcontent.title
                    //             ? styles.menuActive
                    //             : ""
                    //           : active == subcontent.title
                    //           ? styles.menuActive
                    //           : "",
                    //         "rounded-[8px] xl:pl-[16px] pl-2 py-[11px] flex items-center font-poppins md:text-sm text-[12px] font-normal text-[#858795]"
                    //       )}
                    //     >
                    //       {subcontent ? (
                    //         subcontent.link.toString() == "#" ? (
                    //           <a className="flex justify-between w-full pr-4 cursor-pointer">
                    //             <div className="flex items-center relative">
                    //               <img
                    //                 src={
                    //                   active == subcontent.title
                    //                     ? subcontent.activeIcon
                    //                     : subcontent.icon
                    //                 }
                    //                 alt="icon"
                    //                 className="mr-[10px]"
                    //               />
                    //               <p className="text-left hover:text-vorpblue">
                    //                 {subcontent.title}
                    //               </p>

                    //             </div>
                    //             {unlink == subcontent.title ||
                    //             active == subcontent.title ? (
                    //               <div className="relative flex">
                    //                 <img
                    //                   src={
                    //                     subcontent.active == "active"
                    //                       ? menurightActive
                    //                       : menuright
                    //                   }
                    //                   className={classNames(
                    //                     "rotate-90 active",
                    //                     subcontent.subMenuContents
                    //                       ? ""
                    //                       : "hidden",
                    //                     "object-contain"
                    //                   )}
                    //                 />
                    //               </div>
                    //             ) : (
                    //               <img
                    //                 src={
                    //                   subcontent.active == "active"
                    //                     ? menurightActive
                    //                     : menuright
                    //                 }
                    //                 className={classNames(
                    //                   subcontent.active == "active" ? "" : "",
                    //                   subcontent.subMenuContents
                    //                     ? ""
                    //                     : "",
                    //                   "object-contain"
                    //                 )}
                    //               />
                    //             )}
                    //           </a>
                    //         ) : (
                    //           <NavLink
                    //             to={{
                    //               pathname:
                    //                 subcontent.link == "#"
                    //                   ? ""
                    //                   : subcontent.link,
                    //             }}
                    //             state={0}
                    //             className={classNames(
                    //               subcontent.link == ""
                    //                 ? "opacity-70 pointer-events-none"
                    //                 : "",
                    //               "pr-0 w-full flex justify-between items-center relative"
                    //             )}
                    //           >
                    //             <div className="flex items-center relative">
                    //               <img
                    //                 src={
                    //                   active == subcontent.title
                    //                     ? subcontent.activeIcon
                    //                     : subcontent.icon
                    //                 }
                    //                 alt="icon"
                    //                 className="mr-[10px]"
                    //               />
                    //               <p className="text-left mr-[3px] hover:text-vorpblue">
                    //                 {subcontent.title}
                    //               </p>
                    //               <div
                    //                 className={classNames(
                    //                   subcontent.link == "/activity_log" &&
                    //                     notifications.unread_activity_log_count >
                    //                       0
                    //                     ? Number(
                    //                         notifications.unread_activity_log_count ??
                    //                           0
                    //                       ) > 0
                    //                     : subcontent.link == "/profile" &&
                    //                       notifications.profile_unread_noti_count >
                    //                         0
                    //                     ? Number(
                    //                         notifications.profile_unread_noti_count ??
                    //                           0
                    //                       ) > 0
                    //                     : subcontent.link == "/claims" &&
                    //                       notifications.unapprove_claim_count >
                    //                         0
                    //                     ? Number(
                    //                         notifications.unapprove_claim_count ??
                    //                           0
                    //                       ) > 0
                    //                     : "hidden",
                    //                   " w-[18px] h-[18px] bg-redPink absolute top-1/2 left-full -translate-y-1/2 rounded-full whitespace-nowrap"
                    //                 )}
                    //               >
                    //                 <span
                    //                   className={classNames(
                    //                     "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block text-white text-[10px] font-semibold p-[2px] whitespace-nowrap"
                    //                   )}
                    //                 >
                    //                   {subcontent.link == "/activity_log"
                    //                     ? Number(
                    //                         notifications.unread_activity_log_count ??
                    //                           0
                    //                       )
                    //                     : subcontent.link == "/profile"
                    //                     ? Number(
                    //                         notifications.profile_unread_noti_count ??
                    //                           0
                    //                       )
                    //                     : subcontent.link == "/claims"
                    //                     ? Number(
                    //                         notifications.unapprove_claim_count ??
                    //                           0
                    //                       )
                    //                     : ""}
                    //                 </span>
                    //               </div>
                    //             </div>

                    //             {unlink == subcontent.title ||
                    //             active == subcontent.title ? (
                    //               <img
                    //                 src={
                    //                   subcontent.active == "active"
                    //                     ? menurightActive
                    //                     : menuright
                    //                 }
                    //                 className={classNames(
                    //                   "rotate-90 active",
                    //                   subcontent.subMenuContents
                    //                     ? ""
                    //                     : "hidden",
                    //                   "object-contain"
                    //                 )}
                    //               />
                    //             ) : (
                    //               <img
                    //                 src={
                    //                   subcontent.active == "active"
                    //                     ? menurightActive
                    //                     : menuright
                    //                 }
                    //                 className={classNames(
                    //                   subcontent.active == "active" ? "" : "",
                    //                   subcontent.subMenuContents
                    //                     ? ""
                    //                     : "hidden",
                    //                   "object-contain"
                    //                 )}
                    //               />
                    //             )}
                    //           </NavLink>
                    //         )
                    //       ) : (
                    //         ""
                    //       )}
                    //     </li>
                    //     <div
                    //       className={classNames(
                    //         subcontent.title == unlink &&
                    //           (subcontent.title == "Setting"
                    //             ? showAdminSetting
                    //             : subcontent.title == "Backend Users"
                    //             ? showUser
                    //             : subcontent.title == "Home"
                    //             ? showHome
                    //             : subcontent.title == "Company"
                    //             ? showCompany
                    //             : subcontent.title == "Services"
                    //             ? showServices
                    //             : subcontent.title == "Contact Us"
                    //             ? showContactus
                    //             : subcontent.title == "Latest News"
                    //             ? showLatestNews
                    //             : subcontent.title == "Media Reports"
                    //             ? showMediaReports
                    //             : subcontent.title == "Media Resources"
                    //             ? showMediaResources
                    //             : subcontent.title == "Job Opportunities"
                    //             ? showJobOpenings
                    //             : subcontent.title == "Sats Care"
                    //             ? showCares
                    //             : subcontent.title == "Footer"
                    //             ? showFooter
                    //             : false)
                    //           ? styles.toggleContent
                    //           : active == subcontent.title &&
                    //             (subcontent.title == "Setting"
                    //               ? showAdminSetting
                    //               : subcontent.title == "Backend Users"
                    //               ? showUser
                    //               : subcontent.title == "Home"
                    //               ? showHome
                    //               : subcontent.title == "Company"
                    //               ? showCompany
                    //               : subcontent.title == "Services"
                    //               ? showServices
                    //               : subcontent.title == "Contact Us"
                    //               ? showContactus
                    //               : subcontent.title == "Latest News"
                    //               ? showLatestNews
                    //               : subcontent.title == "Media Reports"
                    //               ? showMediaReports
                    //               : subcontent.title == "Media Resources"
                    //               ? showMediaResources
                    //               : subcontent.title == "Job Opportunities"
                    //               ? showJobOpenings
                    //               : subcontent.title == "Sats Care"
                    //               ? showCares
                    //               : subcontent.title =="Footer"
                    //                 ? showFooter
                    //               : false)
                    //           ? styles.toggleContent
                    //           : styles.toggleContentHide,
                    //         "mt-[10px]"
                    //       )}
                    //     >
                    //       <ul className="lg:pl-[52px] pl-9">
                    //         {subcontent.subMenuContents?.map(
                    //           (menucontent: any, uniqKey: any) => {
                    //             return (
                    //               <li
                    //                 key={menucontent.id}
                    //                 onClick={() =>
                    //                   changeSubmenuActive(menucontent.title)
                    //                 }
                    //                 className={classNames(
                    //                   menucontent.active == "active"
                    //                     ? styles.submenuActive
                    //                     : "",
                    //                   "text-left mb-3 list-disc font-poppins text-13 font-normal text-[#858795]",
                    //                   menucontent.link == ""
                    //                     ? "opacity-70 pointer-events-none"
                    //                     : ""
                    //                 )}
                    //               >
                    //                 {menucontent?.link == "#" ? (
                    //                   <a className="flex justify-between w-full pr-4">
                    //                     {menucontent.title}
                    //                   </a>
                    //                 ) : (
                    //                   <div className="relative">
                    //                     <NavLink
                    //                       to={{
                    //                         pathname:
                    //                           menucontent.link !== "#"
                    //                             ? menucontent.link
                    //                             : "",
                    //                       }}
                    //                       className={classNames(
                    //                         menucontent.link == "#"
                    //                           ? "opacity-70 pointer-events-none"
                    //                           : ""
                    //                       )}
                    //                     >
                    //                       <div className="relative">
                    //                         <span className="block mr-[10px]">
                    //                           {menucontent.title}
                    //                         </span>

                    //                       </div>
                    //                     </NavLink>
                    //                   </div>
                    //                 )}
                    //               </li>
                    //             );
                    //           }
                    //         )}
                    //       </ul>
                    //     </div>
                    //   </div>
                    // );
                  })}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div
          id="content-body"
          className={classNames(
            isProjectBoard ? "pt-[30px] bg-[#F3F6F9]" : "",
            "content bg-[#F3F6F9] w-full h-full",
            styles.hidescrollbar,
            styles.defaultWidth,
            isProjectBoard == true ? "relative" : "fixed overflow-auto"
          )}
        >
          <div
            className={classNames(
              content,
              styles.defaultWidth,
              "sm:px-4 px-2 xl:ml-[235px] lg:ml-[7px] ml-0 relative"
            )}
          >
            <div
              className={classNames(
                content,
                styles.defaultWidth,
                "flex items-center top-0 fixed w-full left-0 custom-header h-[65px] bg-white border-b-[1px] border-b-[#EEEFF3] z-[999999]"
              )}
            >
              <img
                src={arrow}
                alt="Arrow-double-left-active"
                className={classNames(
                  menuClose,
                  style == "hidden" ? "rotate-180" : "",
                  "cursor-pointer"
                )}
                onClick={() => handleMenuClose()}
              />

              <div
                className={classNames(
                  content,
                  styles.defaultWidth,
                  "xl:ml-[235px] lg:ml-[227px] ml-0 lg:pl-5 pl-0 xl:pr-9 pr-4 flex justify-between items-center w-full"
                )}
              >
                <p className="font-poppins font-semibold md:text-base text-xs text-[#464E5F] flex xs:flex-row flex-col items-center">
                  {window.location.pathname == "/users"
                    ? "Users"
                    : window.location.pathname == "/users/roles"
                      ? "Roles"
                      : window.location.pathname == "/users/permissions"
                        ? "Permissions"
                        : window.location.pathname == "/all-task-list"
                          ? "All Task List"
                          : window.location.pathname.includes("progress-checker/")
                            ? "Progress Checker"
                            : window.location.pathname.includes("employee/")
                              ? "Employee Leave"
                              : window.location.pathname.includes("cares/")
                                ? "Sats Care"
                                : window.location.pathname.includes("contact-us/")
                                  ? "Contact Us"
                                  : menuTitle}

                  {<span
                    className={classNames(
                      styles.subMenuTitle,
                      "xs:ml-4 font-poppins text-xs font-normal text-[#858795] capitalize"
                    )}
                  >
                    {get_submenu_active()}
                  </span>}

                </p>
                <div>
                  <div className="flex py-3 relative">
                    {/* <div className="mr-5">
                      <ClickAwayListener onClickAway={handleClickAwayNoti}>
                        <Box sx={{ position: "relative" }}>
                          <button
                            className="relative cursor-pointer"
                            onClick={(event) => {
                              handleClickNoti("bottom-start", event);
                              setNotiBox(!showNotiBox);
                            }}
                          >
                            <div
                              className={classNames(
                                notifications.unread_count ?? 0 > 0
                                  ? ""
                                  : "hidden",
                                "w-[18px] h-[18px] bg-redPink absolute top-0 sm:right-[-5px] right-[-10px] rounded-full"
                              )}
                            >
                              <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block text-white text-[10px] font-semibold p-[2px] whitespace-nowrap">
                                {notifications.unread_count ?? 0}
                              </span>
                            </div>
                            <div
                              className={classNames(
                                styles.notificationIcon,
                                "sm:p-2 p-1 border-1 border-[#E4E6EF] rounded-lg"
                              )}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z"
                                  fill="#464E5F"
                                />
                                <path
                                  d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z"
                                  fill="#464E5F"
                                />
                              </svg>
                            </div>
                          </button>

                          {showNotiBox ? (
                            <Popper
                              id={id}
                              open={showNotiBox}
                              anchorEl={anchorElNoti}
                              transition
                            >
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps}>
                                  <NotiList
                                    notificationsList={notifications ?? []}
                                    viewNoti={viewNoti}
                                    markAllAsRead={markAllAsRead}
                                  />
                                </Fade>
                              )}
                            </Popper>
                          ) : null}
                        </Box>
                      </ClickAwayListener>
                    </div> */}
                    <div className="mr-3 flex items-center">
                      <p className="capitalize text-right font-poppins md:text-sm text-xs text-[#464E5F] font-semibold">
                        {userData?.user?.name}
                      </p>
                      {/* <p className=" inline-block text-right font-poppins text-xs text-[#C4C4C4] font-semibold">
                        {userData?.designation ? userData.designation : ""}
                      </p> */}
                    </div>
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Box sx={{ position: "relative" }}>
                        {userData?.user?.photo ? (
                          <div className="relative inline-flex w-fit">
                            <button
                              onClick={(event) => {
                                handleClick("bottom-start", event);
                                setLoginBox(!showLoginBox);
                              }}
                            >
                              <img
                                id="profileImage"
                                src={userData?.user?.photo}
                                alt="profile img"
                                className="w-[40px] h-[40px] object-cover rounded-[6px]"
                              />
                            </button>
                          </div>
                        ) : (
                          <div className="bg-[#A5A6F6] rounded-[6px] ">
                            <button
                              onClick={(event) => {
                                handleClick("bottom-start", event);
                                setLoginBox(!showLoginBox);
                              }}
                              className="focus-visible:outline-none uppercase sm:py-[5px] py-[1px] sm:px-[14px] px-2 font-poppins font-medium md:text-xl text-base"
                            >
                              {userData?.user?.name.slice(0, 1)}
                            </button>
                          </div>
                        )}
                        {showLoginBox ? (
                          <Popper
                            id={id}
                            open={showLoginBox}
                            anchorEl={anchorEl}
                            transition
                          >
                            {({ TransitionProps }) => (
                              <Fade {...TransitionProps}>
                                <UserInfoPopup
                                  confirmEvent={confirmEvent}
                                  saveChangePassword={saveChangePassword}
                                  changePassword={changePassword}
                                  logout={logoutFun}
                                  showLogoutPopup={showLogoutPopup}
                                  showChangePwPopup={showChangePwPopup}
                                  setShowChangePw={setShowChangePw}
                                  setLogoutPopup={setLogoutPopup}
                                  setShow={setLoginBox}
                                  errorMsg={errorMsg}
                                />
                              </Fade>
                            )}
                          </Popper>
                        ) : null}
                      </Box>
                    </ClickAwayListener>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={classNames(
                "mt-[76px] ",
                isProjectBoard == true ? "w-[100vw]" : "overflow-x-hidden",
                styles.hidescrollbar
              )}
            >
              {props.children ?? ""}
            </div>
          </div>
        </div>
        <div className="footer"></div>
      </div>
    </>
  );
};

export default BasicLayout;
