// import { Modal } from "@material-ui/core";
import { Modal } from '@mui/material';

import classNames from "classnames";
import React, {
  createRef,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { CloseIcon } from "../common/Icons";
import styles from "./ChangePasswordPopup.module.scss";
import eyehide from "../../login/img/eye-open.svg";
import eyeshow from "../../login/img/eye-show.svg";


interface ICreateData {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  updateData: any;
  errorMsg?: string;
}
const ChangePasswordPopup: FC<ICreateData> = ({
  show,
  setShow,
  updateData,
  errorMsg,
}) => {
  const [error, setError] = useState<string>("");
  const [oldPasswordError, setOldPasswordError] = useState<string>("");
  const [newPasswordError, setNewPasswordError] = useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");
  var oldPw = useRef<any>(null);
  var newPw = useRef<any>(null);
  var confirmPw = useRef<any>(null);

  const saveUpdatedData = () => {
    if (!oldPw.current.value && !newPw.current.value && !confirmPw.current.value) {
      setOldPasswordError("Old password is required.");
      setNewPasswordError("New password is required.");
      setConfirmPasswordError("Retype password is required.");
      return;
    }
    if (!oldPw.current.value && !newPw.current.value) {
      setOldPasswordError("Old password is required.");
      setNewPasswordError("New password is required.");
      setConfirmPasswordError('');
      return;
    }
    if (!oldPw.current.value && !confirmPw.current.value) {
      setOldPasswordError("Old password is required.");
      setNewPasswordError('');
      setConfirmPasswordError("Retype password is required.");
      return;
    }
    if (!newPw.current.value && !confirmPw.current.value) {
      setNewPasswordError("New password is required.");
      setConfirmPasswordError("Retype password is required.");
      setOldPasswordError('');
      return;
    }
    if (!newPw.current.value) {
      setOldPasswordError('');
      setConfirmPasswordError('');
      setNewPasswordError("New password is required.");
      return;
    }
    if (!confirmPw.current.value) {
      setOldPasswordError('');
      setNewPasswordError('');
      setConfirmPasswordError("Retype password is required.");
      return;
    }
    if (newPw.current.value !== confirmPw.current.value) {
      setConfirmPasswordError("Confirm password is not match.");
      return;
    }
    if (newPw.current.value == confirmPw.current.value) {
      setConfirmPasswordError("");
      setOldPasswordError("");      
      if (newPw.current.value == oldPw.current.value){
        setNewPasswordError("New password and old password must not be same.");
        return;
      }else{
        setNewPasswordError("");
      }   
    }
    setError("");
    cleanErrorMsg();
    updateData(
      oldPw.current.value,
      newPw.current.value,
      confirmPw.current.value
    );
  };

  const switchEyeHandler = (event: any) => {
    var hideimg = eyehide;
    var showimg = eyeshow;
    const type =
      event.target.previousElementSibling.getAttribute("type") === "password"
        ? "text"
        : "password";
    const img =
      event.target.previousElementSibling.getAttribute("type") === "password"
        ? hideimg
        : showimg;
    event.target.previousElementSibling.setAttribute("type", type);
    event.target.setAttribute("src", img);
  };
  const closeAndCleanData = () => {
    cleanErrorMsg();
    oldPw.current.value = "";
    newPw.current.value = "";
    confirmPw.current.value = "";
  }
  const cleanErrorMsg = () => {
    setConfirmPasswordError('');
    setNewPasswordError('');
    setOldPasswordError('');
  }

  useEffect(() => {
    if (errorMsg?.includes('old')) {
      setOldPasswordError(errorMsg)
    }
  }, [errorMsg])
  return (
    <div>
      <Modal open={show} onClose={() => setShow(false)}  disableEnforceFocus={true}>
        <div>
          <div className={classNames("z-10 fixed top-0 left-0 w-full h-full")}>
            <div
              className={classNames(
                "relative z-10 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 md:max-w-[457px] 2xs:max-w-[400px] 5xs:max-w-[340px] max-w-[300px] bg-[#fff] rounded-xl"
              )}
            >
              <div className="flex justify-between items-center bg-darkblue px-10 py-6 rounded-tl-xl rounded-tr-xl">
                <p className="text-left font-poppins font-semibold lg:text-lg text-base text-[#fff]">
                  Change Password
                </p>
                <span
                  onClick={() => setShow(false)}
                  className="text-[#B5B5C3] text-20 cursor-pointer"
                >

                  <CloseIcon />
                </span>
              </div>
              <div className="md:px-10 px-6 pt-9 pb-10">
                <div>
                  <p
                    className={classNames(
                      "pb-2 font-poppins font-medium lg:text-15 text-xs text-black2"
                    )}
                  >
                    Old Password
                  </p>
                  <div className="relative ">
                    <input autoComplete="new-password" defaultValue=""
                      ref={oldPw}
                      type="password"
                      placeholder="Enter old password"
                      className="focus:outline-none w-full rounded-4 font-poppins font-normal lg:text-sm text-xs text-graydark py-14 px-3 border border-vorpgraylight"
                    />
                    <img
                      src={eyeshow}
                      alt="eye-open"
                      className={classNames(
                        styles.theeye,
                        "absolute top-1/3 right-4 cursor-pointer w-[22px] h-[16px] object-cover"
                      )}
                      onClick={switchEyeHandler}
                    />
                  </div>
                  <p className={classNames(oldPasswordError != "" ? '' : 'hidden', "mt-2 font-poppins text-12 text-redPink")}>
                    {oldPasswordError ? oldPasswordError : ''}
                  </p>
                </div>
                <div className="mt-5">
                  <p
                    className={classNames(
                      "pb-2 font-poppins font-medium lg:text-15 text-xs text-black2"
                    )}
                  >
                    New Password
                  </p>
                  <div className="relative ">
                    <input autoComplete="off" defaultValue=""
                      ref={newPw}
                      type="password"
                      placeholder="Enter new password"
                      className="focus:outline-none w-full rounded-4 font-poppins font-normal lg:text-sm text-xs text-graydark py-14 px-3 border border-vorpgraylight"
                    />
                    <img
                      src={eyeshow}
                      alt="eye-open"
                      className={classNames(
                        styles.theeye,
                        "absolute top-1/3 right-4 cursor-pointer w-[22px] h-[16px] object-cover"
                      )}
                      onClick={switchEyeHandler}
                    />
                  </div>
                  <p className={classNames(newPasswordError != "" ? '' : 'hidden', "mt-2 font-poppins text-12 text-redPink")}>
                    {newPasswordError ? newPasswordError : ''}
                  </p>
                </div>
                <div className="mt-5">
                  <p
                    className={classNames(
                      "pb-2 font-poppins font-medium lg:text-15 text-xs text-black2"
                    )}
                  >
                    Confirm Password
                  </p>
                  <div className="relative ">
                    <input autoComplete="off" defaultValue=""
                      ref={confirmPw}
                      type="password"
                      placeholder="Retype the new password"
                      className="focus:outline-none w-full rounded-4 font-poppins font-normal lg:text-sm text-xs text-graydark py-14 px-3 border border-vorpgraylight"
                    />
                    <img
                      src={eyeshow}
                      alt="eye-open"
                      className={classNames(
                        styles.theeye,
                        "absolute top-1/3 right-4 cursor-pointer w-[22px] h-[16px] object-cover"
                      )}
                      onClick={switchEyeHandler}
                    />
                  </div>
                  <p className={classNames(confirmPasswordError != "" ? '' : 'hidden', "mt-2 font-poppins text-12 text-redPink")}>
                    {confirmPasswordError ? confirmPasswordError : ''}
                  </p>
                </div>
                <div>
                  <div className="flex justify-start mt-9">
                    <button
                      onClick={saveUpdatedData}
                      className={classNames(
                        styles.customConfirmBtn,
                        "flex items-center px-3 py-2 rounded-[6px] font-poppins font-semibold lg:text-sm text-xs text-[#fff] bg-vorpblue hover:text-vorpblue hover:bg-vorpmyanmar mr-2"
                      )}
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setShow(false)}
                      className={classNames(
                        styles.cancelBtn,
                        "flex items-center px-4 py-2 rounded-[6px] font-poppins font-semibold lg:text-sm text-xs text-black2 bg-offwhite hover:bg-anncolor"
                      )}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <p className="mt-2 font-poppins text-12 text-redPink">
                  {error ? error : ''}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default ChangePasswordPopup;
