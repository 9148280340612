import { FC, useEffect, useRef, useState } from "react";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import { twJoin } from "tailwind-merge";
import classNames from "classnames";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import { IHomeBanner, useGetSliderQuery } from "../../app/services/home/home";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { getAuthUser } from "../../app/services/dashboard";
interface IData {
  activeLang: string;
  initialData: IHomeBanner;
  setValue: React.Dispatch<any>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
}
export interface IBannerSlider {
  description_en: string;
  description_zh: string;
  description_cn: string;
  slider_desktop_image: File | undefined;
  image_url: string;
  id:number;
  // image_en:File|undefined;
  // image_en_url:string;
  // image_zh:File|undefined;
  // image_zh_url:string;
  // image_cn:File|undefined;
  // image_cn_url:string;
}
const BannerSlider: FC<IData> = ({
  activeLang,
  initialData,
  setValue,
  setShowList
}) => {
  const [data, setData] = useState<IBannerSlider>({
    id:0,
    description_en: '',
    description_zh: '',
    description_cn: '',
    slider_desktop_image: undefined,
    image_url: ''
  });
  const descriptionRef_en: any = useRef(null);
  const descriptionRef_tc: any = useRef(null);
  const descriptionRef_sc: any = useRef(null);
  const [message, setMessage] = useState<string>("");
  const [image,setImageUrl]=useState<string>("");
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [isSave, setIsSave] = useState<boolean>(false);
  const [isRequired, setIsRequired] = useState<boolean>(false);

  const change_data = (value: any, key: string) => {
    if (data) {
      if (key == "slider_desktop_image") {
        const url=value?URL.createObjectURL(value):'';  
        setData({ ...data, ['image_url']: url });
        setData({ ...data, [key]: value });        
        setImageUrl(url);
      }
      setData({ ...data, [key]: value });
    }
  };
  const check_validation=()=>{
    if(data?.description_en && data?.description_zh && data?.description_cn && image){
      return true;
    }
    return false;
  }
  const handle_submit = async () => {
    setIsSave(true);
    setIsRequired(true);
    if(check_validation()==false){  
      setIsRequired(true);
      setIsSave(false);
      return;
    }
    setIsRequired(false);
    const formData = new FormData();
    formData.append('page_slug', 'home');
    formData.append('id', data?.id?.toString()??'0');
    formData.append('title_en', data?.description_en);
    formData.append('title_tc', data?.description_zh);
    formData.append('title_cn', data?.description_cn);
    formData.append('content_type', 'image');
    formData.append('slider_desktop_image', data?.slider_desktop_image ?? '');
    const url=data?.id?'update-slider':'slider';
    var getUser = getAuthUser();
    await axios(endpointUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
      },
      data: formData,
    })
      .then((res: any) => {
        if (res?.data?.status == true) {
          toast(res?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        }
        setShowList(true);
        setIsSave(false);
      })
      .catch((err) => {
        setIsSave(false);
      });
  }
  const get_filename=(file:string)=>{
    const files=file?.split('/');
    if(files?.length>0){
      return files[files.length-1];
    }else{
      return '';
    }
    
  }
  useEffect(()=>{
    if(initialData){
      setData({
        id:initialData?.id,
        description_en:initialData?.title_en,
        description_zh:initialData?.title_tc,
        description_cn:initialData?.title_cn,
        slider_desktop_image:initialData?.slider_desktop_image,
        image_url:initialData?.slider_desktop_image
      });
      setImageUrl(initialData?.slider_desktop_image);
    }
  },[initialData]);
  return <div className="mt-5">
    <ToastContainer className={"text-12 font-poppins font-normal"} />
    <ErrorPopup
      descText="Error Message!"
      confirmBtnText="Yes, Close."
      show={errorPopup}
      setShow={setErrorPopup}
      message={message}
      setMessage={setMessage}
    />
    <div
      className={twJoin(
        activeLang == "en"
          ? ""
          : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
      )}
    >
      <LabelAndEditor
      isRequired={true}
        name="description"
        refId={descriptionRef_en}
        label=" Description (EN)"
        value={data?.description_en}
        setValue={(value: any) => {
          change_data(value, 'description_en');
        }}
      />
      {(isRequired==true && !data?.description_en)?<span className="text-12 text-red-500 font-normal">Description is required.</span>:null}
    </div>
    <div
      className={twJoin(
        activeLang == "tc"
          ? ""
          : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
      )}
    >
      <LabelAndEditor
      isRequired={true}
        name="description"
        refId={descriptionRef_tc}
        label=" Description (TC)"
        value={data?.description_zh}
        setValue={(value: any) => {
          change_data(value, 'description_zh');
        }}
      />
      {(isRequired && !data?.description_zh)?<span className="text-12 text-red-500 font-normal">Description is required.</span>:null}
    </div>
    <div
      className={twJoin(
        activeLang == "sc"
          ? ""
          : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
      )}
    >
      <LabelAndEditor
      isRequired={true}
        name="description"
        refId={descriptionRef_sc}
        label="Description (SC)"
        value={data?.description_cn}
        setValue={(value: any) => {
          change_data(value, 'description_cn');
        }}
      />
      {(isSave && !data?.description_cn)?<span className="text-12 text-red-500 font-normal">Description is required.</span>:null}
    </div>
    <div className="mt-5">
      <LabelAndImage
      isRequired={true}
        margin="mt-0"
        label="Image"
        value={image}
        //setValue={(value:any)=>change_data(value,'image_url')}
        setValue={() => 1}
        setFile={(value: any) => change_data(value, 'slider_desktop_image')}
      />
      {(isRequired && !data?.image_url)?<span className="text-12 text-red-500 font-normal mt-5 block">Image is required.</span>:null}
    </div>
    <div className="mt-5 last:mb-0 flex items-center">
      <button
        className={classNames(
          (isSave) ? "opacity-50 pointer-events-none" : "",
          "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
        )}
        onClick={handle_submit}
      >
        <SaveIcon className="mr-2" /> Save
      </button>
      <button
        className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
        onClick={() => {
          setShowList(true);
        }}
      >
        <CancelIcon className="mr-2" color="#9E3039" />
        Cancel
      </button>
    </div>
  </div>
}
export default BannerSlider;