import { ReactElement, useState } from "react";
import BasicLayout from "../../layouts/BasicLayout";
import Privacy from "./Privacy";

const PrivacyRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;
  return (
    <BasicLayout userData={data} permissions={permissions} sidebars={props.sidebarsData}>
       <Privacy/>
    </BasicLayout>
  );
};

export default PrivacyRoute;
