import { twJoin } from "tailwind-merge";
import { useState } from "react";
import JobOpening, { JobOpeningType } from "../components/JobOpening";
import JobOpeningSectionList from "../components/job-opening/JobOpeningSectionList";

const JobOpeningPage = () => {
  

  return (
    <div
      className={twJoin(
        "text-left  bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pb-8 sm:pb-9 "
      )}
    >
      <JobOpeningSectionList />
    </div>
  );
};
export default JobOpeningPage;
