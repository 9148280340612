import { ReactElement } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import OverViewPage from "./sections/OverviewPage";
import MediaReportPage from "./sections/MediaReportPage";
import CategoryPage from "../latest-news/sections/CategoryPage";
import SidebarFunc from "../layouts/BasicLayout/SidebarFunc";
export const mediaReportsPagesList = [
  {
    id: 1,
    path: "#",
    component: <OverViewPage />,
  },
  {
    id: 2,
    path: "overview",
    component: <OverViewPage />,
  },
  {
    id: 3,
    path: "media-reports-list",
    component: <MediaReportPage />,
  },
  {
    id: 4,
    path: "category",
    component: <CategoryPage />,
  },
];
const MediaReportsPageRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;
  var sidebarsData = SidebarFunc();
  return (
    <BasicLayout userData={data} permissions={permissions} sidebars={sidebarsData}>
    <div className="pt-0 pb-[58px]">
      <Outlet />
    </div>
  </BasicLayout>
  );
};
export default MediaReportsPageRoute;
