import { FC, useState } from "react";
import { twJoin } from "tailwind-merge";
import { CloseIcon } from "../common/Icons";
interface ILabelAndEditor {
  label: string;
  value: string | undefined;
  setValue?: (value: React.SetStateAction<any>) => void;
  setFile: (value: React.SetStateAction<File | undefined>) => void;
  margin?: string;
  isRequired?:boolean;
  isRemove?:boolean;
  setIsRemoveImage?: React.Dispatch<React.SetStateAction<boolean>>
}
const LabelAndImage: FC<ILabelAndEditor> = ({
  label,
  value,
  setValue,
  setFile,
  margin,
  isRequired,
  isRemove,
  setIsRemoveImage
}) => {
  const [filename,setFileName]=useState<string>('');
  const handleRemove=()=>{
    setFileName('');
    setFile(undefined);
    if(setIsRemoveImage){
      setIsRemoveImage(true);
    }
  }
  return (
    <div className={twJoin(margin ? margin : "mt-5", " last:mb-0")}>
      <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
      {label}{isRequired==true?<span className="text-14 text-red-500">*</span>:''}
      </label>
      
      {value
        ? <div className="relative inline-block">
          <img
            src={value}
            className=" max-w-[100px] w-full min-w-[100px] mb-5 aspect-[100/100] object-contain"
          />
          {isRemove==true?<button className="absolute right-[-10px] top-[-10px]" onClick={()=>handleRemove()}><CloseIcon color="#9e3039"/></button>:null}
          </div>
        : null}
        
      
      
      <div className=" border border-gray-300 text-gray-400">
        <div className="flex items-start p-4 relative">
          <input
            type="file"
            name="meta_image"
            onChange={event => {
              if (event.target.files) {
                setFileName(event.target.files[0]?.name)
                setFile(event.target.files[0]);
                var url = URL.createObjectURL(event.target.files[0]);
               // setValue(url ? url : "");
              }
            }}
            className="focus:outline-none absolute opacity-0 focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark"
          />
          <button className="whitespace-nowrap text-black2 text-15 font-medium font-primary">Choose file</button>
          <p className="font-primary text-10 sm:text-12 text-black2 ml-3 " style={{wordBreak:'break-word'}}>
           {filename?filename:(value?value.split('/')[value.split('/').length-1]:'')}
          </p>
        </div>
      </div>
    </div>
  );
};
export default LabelAndImage;
