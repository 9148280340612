import { ReactElement } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import OverViewPage from "./sections/OverviewPage";
import ResourcesPage from "./sections/ResourcesPage";
import DownloadItemPage from "./sections/DownloadItemPage";
import SidebarFunc from "../layouts/BasicLayout/SidebarFunc";
import ResourceCategory from "./sections/ResourceCategory";
export const MediaResourcesPagesList = [
  {
    id: 1,
    path: "#",
    component: <OverViewPage />,
  },
  {
    id: 2,
    path: "overview",
    component: <OverViewPage />,
  },
  {
    id: 3,
    path: "category",
    component: <ResourceCategory />,
  },
  {
    id: 4,
    path: "resources",
    component: <ResourcesPage />,
  },
  {
    id: 5,
    path: "download-items",
    component: <DownloadItemPage />,
  },
];
const MediaResourcesPageRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;
  var sidebarsData = SidebarFunc();
  return (
    <BasicLayout userData={data} permissions={permissions} sidebars={sidebarsData}>
      <div className="pt-0 pb-[58px]">
        <Outlet />
      </div>
    </BasicLayout>
  );
};
export default MediaResourcesPageRoute;
