import { FC, useRef, useState } from "react";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import { twJoin } from "tailwind-merge";
import classNames from "classnames";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import LabelAndSelect from "../../components/SeoData/LabelAndSelect";
import CommonLanguage from "../../components/common/CommonLanguage";
import { JobCategoryType } from "./JobCategory";
import SeoData from "../../components/SeoData/SeoData";

interface IData {
  initialData: JobOpeningType;
  setValue: React.Dispatch<any>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  handle_submit: (data: JobOpeningType) => Promise<void>;
  isSave: boolean;
  categoryList: JobCategoryType[];
  departmentList: JobCategoryType[];
}

export interface JobOpeningType {
  id: number;
  job_category_id: string;
  job_department_id: string;
  title_en: string;
  title_sc: string;
  title_tc: string;
  apply_now_en: string;
  apply_now_sc: string;
  apply_now_tc: string;
  description_en: string;
  description_sc: string;
  description_tc: string;
  meta_title_en: string;
  meta_title_tc: string;
  meta_title_sc: string;
  meta_description_en: string;
  meta_description_tc: string;
  meta_description_sc: string;
  meta_image: File | undefined;
  meta_image_url: string;
}

export const options = [
  {
    label: "No Experience",
    value: "1",
  },
  {
    label: "DSE or above",
    value: "2",
  },
  {
    label: "Diploma or above",
    value: "3",
  },
  {
    label: "Degree or above",
    value: "4",
  },
];

const JobOpening: FC<IData> = ({ setShowList, initialData, handle_submit, isSave, categoryList, departmentList }) => {
  const [activeLang, setActiveLang] = useState<string>("en");
  const [data, setData] = useState<JobOpeningType>(initialData);
  const [metaImage, setMetaImage] = useState<any>(undefined);
  const [metaImageUrl, setMetaImageUrl] = useState<string>(
    initialData.meta_image as any || ""
  );
  const description_en_Ref = useRef();
  const description_sc_Ref = useRef();
  const description_tc_Ref = useRef();
  const change_data = (value: any, key: string) => {
    if (data) {
      setData({ ...data, [key]: value });
    }
  };

  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  
  return (
    <div>
      <h2 className="text-lg text-black font-bold text-left my-5">
        Job Opening
      </h2>
      <div className="flex flex-col lg:flex-row">
        <div className="md:w-3/5 w-full md:mr-5 md:mb-0 mb-5">
          <div className="mb-5">
            <CommonLanguage
              changeLanguage={changeLanguage}
              activeLang={activeLang}
            />
          </div>
          <LabelAndSelect
            label="Category"
            value={data?.job_category_id}
            options={categoryList?.map((x) => {
              return {
                label: x.title_en,
                value: x.id.toString()
              }
            })}
            setValue={(value) => change_data(value, "job_category_id")}
          />
          <div>
            <LabelAndSelect
              label="Department"
              value={data?.job_department_id}
              options={departmentList?.map((x) => {
                return {
                  label: x.title_en,
                  value: x.id.toString()
                }
              })}
              setValue={(value) => change_data(value, "job_department_id")}
            />
          </div>
          <div
            className={twJoin(
              activeLang === "en"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <LabelAndTextbox
              label="Title (EN)"
              value={data?.title_en}
              setValue={(value: any) =>
                change_data(value, "title_en")
              }
            />
            <LabelAndEditor
              refId={description_en_Ref}
              label="Description (En)"
              value={data?.description_en}
              setValue={(value) =>
                change_data(value, "description_en")
              }
            />
            {/* <LabelAndTextbox
              label="Apply Now (EN)"
              value={data?.title_en}
              setValue={(value: any) =>
                change_data(value, "apply_now_en")
              }
            /> */}
          </div>

          <div
            className={twJoin(
              activeLang === "sc"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <LabelAndTextbox
              label="Title (SC)"
              value={data?.title_sc}
              setValue={(value: any) =>
                change_data(value, "title_sc")
              }
            />
            <LabelAndEditor
              refId={description_sc_Ref}
              label="Description (SC)"
              value={data?.description_sc}
              setValue={(value) =>
                change_data(value, "description_sc")
              }
            />
            {/* <LabelAndTextbox
              label="Apply Now (SC)"
              value={data?.title_sc}
              setValue={(value: any) =>
                change_data(value, "apply_now_sc")
              }
            /> */}
          </div>

          <div
            className={twJoin(
              activeLang === "tc"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <LabelAndTextbox
              label="Title (TC)"
              value={data?.title_tc}
              setValue={(value: any) =>
                change_data(value, "title_tc")
              }
            />
            <LabelAndEditor
              refId={description_tc_Ref}
              label="Description (TC)"
              value={data?.description_tc}
              setValue={(value) =>
                change_data(value, "description_tc")
              }
            />
            {/* <LabelAndTextbox
              label="Apply Now (TC)"
              value={data?.title_tc}
              setValue={(value: any) =>
                change_data(value, "apply_now_tc")
              }
            /> */}
          </div>
        </div>
        <div className="md:w-2/5 w-full">
          <h2 className="text-lg text-black font-bold text-left my-5">SEO</h2>
          <SeoData
            showPageTitle={false}
            setPageTitleEn={() => 1}
            setPageTitleZh={() => 1}
            setPageTitleCn={() => 1}
            metatitle_en={data?.meta_title_en}
            metatitle_zh={data?.meta_title_tc}
            metatitle_cn={data?.meta_title_sc}
            setMetaTitleEn={(value) => change_data(value, 'meta_title_en')}
            setMetaTitleZh={(value) => change_data(value, 'meta_title_tc')}
            setMetaTitleCn={(value) => change_data(value, 'meta_title_sc')}
            metadescription_en={data?.meta_description_en}
            metadescription_zh={data?.meta_description_tc}
            metadescription_cn={data?.meta_description_sc}
            setMetaDescriptionEn={(value) => change_data(value, 'meta_description_en')}
            setMetaDescriptionZh={(value) => change_data(value, 'meta_description_tc')}
            setMetaDescriptionCn={(value) => change_data(value, 'meta_description_sc')}
            meta_image={metaImageUrl}
            setMetaImage={setMetaImage}
            setMetaImageFile={(value) => change_data(value, 'meta_image')}
          />
        </div>
      </div>
      <div className="mt-5 last:mb-0 flex items-center">
        <button
          className={classNames(
            isSave ? "opacity-50 pointer-events-none" : "",
            "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
          )}
          onClick={() => handle_submit(data)}
        >
          <SaveIcon className="mr-2" /> Save
        </button>
        <button
          className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
          onClick={() => {
            setShowList(true);
          }}
        >
          <CancelIcon className="mr-2" color="#9E3039" />
          Cancel
        </button>
      </div>
    </div>
  );
};
export default JobOpening;
