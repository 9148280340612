import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
const currentlanguage = localStorage.getItem("language");
export const language = currentlanguage ? currentlanguage + "/" : "en/";
export const endpointUrl = process.env.REACT_APP_API_URI;
export const ssoEndPointUrl = process.env.REACT_APP_API_URI_SSO;
// export const endpointUrl = "https://vorpapi.visibleone.com/api/v1/";
// Create our baseQuery instance
export const assignedColor = ["#FFC700", "#464E5F", "#3699FF", "#EE436D"];
const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URI}`,
  prepareHeaders: (headers, { getState }) => {
    headers.append("Accept", `application/json`);
    headers.set("Content-Type", `application/json`);
    return headers;
  },
});
export const getLanguageCode=()=>{
  return currentlanguage ? currentlanguage : "en";
}
export const renderDateFormatMY = (date: Date, character: string) => {
  var year = date.getFullYear();
  var month = date.toLocaleDateString("default", { month: "short" });
  var day = date.getDate();
  return (month.toString().length < 2 ? "0" + month : month) + character + year;
};
export const renderDateFormatMLongY = (date: Date, character: string) => {
  var year = date.getFullYear();
  var month = date.toLocaleDateString("default", { month: "long" });
  var day = date.getDate();
  return (month.toString().length < 2 ? "0" + month : month) + character + year;
};
export const renderDateFormat = (date: Date, character: string) => {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  return (
    year +
    character +
    (month.toString().length < 2 ? "0" + month : month) +
    character +
    (day.toString().length < 2 ? "0" + day : day)
  );
};

export const renderDateFormatDMY = (date: Date, character: string) => {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  return (
    (day.toString().length < 2 ? "0" + day : day) +
    character +
    (month.toString().length < 2 ? "0" + month : month) +
    character +
    year
  );
};

export const renderDateFormatDMYTimeStamp = (date: Date, character: string) => {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var h = date.getHours();
  var m = date.getMinutes();
  var s = date.getSeconds();
  return (
    (day.toString().length < 2 ? "0" + day : day) +
    character +
    (month.toString().length < 2 ? "0" + month : month) +
    character +
    year +
    ' ' +
    h +
    ":" +
    m +
    ":" +
    s
  );
};
export const renderDateFormatYMD = (date: Date, character: string) => {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  return (
    year +
    character +
    (month.toString().length < 2 ? "0" + month : month) +
    character +
    (day.toString().length < 2 ? "0" + day : day)
  );
};
const getTime = (d:Date)=>{
  const dd = [d.getHours(), d.getMinutes(), d.getSeconds()].map((a)=>(a < 10 ? '0' + a : a));
  return dd.join(':');
};
export const renderDateFormatYMDTFormat = (date: Date, character: string) => {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hour = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();
  var timezone=date.getTime();
  return (
    year +
    character +
    (month.toString().length < 2 ? "0" + month : month) +
    character +
    (day.toString().length < 2 ? "0" + day : day)+'T'+(date?getTime(date):'')
  );
};

export const renderDateFormatMDY = (date: Date, character: string) => {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  return (
    (month.toString().length < 2 ? "0" + month : month) +
    character +
    (day.toString().length < 2 ? "0" + day : day) +
    character +
    year
  );
};
export const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithRetry,
  keepUnusedDataFor: 30 * 60 * 1000,
  // refetchOnFocus: true,
  // refetchOnReconnect: true,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  // tagTypes: ['Users', 'Posts', 'Products',"Permissions","rTData","UsersList","RoleData","EmployeeListData", "CreateAnnoun", "Tags"],
  tagTypes: [
    "ClaimTypeList",
    "Users",
    "Posts",
    "Products",
    "Permissions",
    "UsersList",
    "RoleData",
    "EmployeeListData",
    "TemplateList",
    "templateData",
    "templateSpecificData",
    "addTempleData",
    "DepartmentList",
    "DesignationList",
    "CurrencyList",
    "ContryList",
    "CreateAnnoun",
    "Tags",
    "CreateeAnnounSheet",
    "tagsDataList",
    "Password",
    "EventList",
    "ProjectListData",
    "ProjectTypeList",
    "EventTypes",
    "PasswordList",
    "LeaveDataList",
    "ProjectStatusList",
    "KeyDateList",
    "PaymentStatusList",
    "RegionsList",
    "QuotationNoList",
    "CheckList",
    "PaymentList",
    "ProfileList",
    "MyProfileList",
    "MyGoal",
    "ImportProjects",
    "CheckInOut",
    "MyTimeSheet",
    "MyAuthLeaves",
    "MyAuthLeaveBalance",
    "MyAuthLeaveTypes",
    "LeaveData",
    "MyPasswordList",
    "MyEmpyTimeSheet",
    "TimeSheetDetail",
    "EmployeeList",
    "RoleListData",
    "GetTimeSheet",
    "Documents",
    "Medias",
    "MyPasswordList",
    "TagsCustom",
    "ProfileListData",
    "OTList",
    "LeaveAllowanceList",
    "OnePasswordList",
    "KeyPasswordList",
    "PasswordLog",
    "ListUsers",
    "IsKeyPwd",
    "UserAssign",
    "Notification",
    "AnnoucementData",
    "NotificationList",
    "Claims",
    "MyClaims",
    "EmployeeIncentive",
    "OffDay",
    "ProjectTech",
    "ProjectType",
    "KeyDate",
    // "ExchangeRate"
    "ExchangeRate",
    "PayRoll",
    "ProfileDailyReport",
    "ProfileDailyReportPopup",
    "ProgressChecker",
    "ProfileTaskName",
    "TaskList",
    "TaskDetailList",
    "EmployeeAssign",
    "EmployeeAssignRow",
    "TaskCategories",
    "Departments",
    "ProfileProjects",
    "PayRollFilterData",
    "ClaimsFilter",
    "ProfileDailyReportRefillPopup",
    "DailyReportFilterData",
    "ProjectBoardFilter",
    "ProjectBoardStatus",
    "ProjectStatus",
    "KeyDateDataList",
    "LeaveInformation",
    "LeaveTaken",
    "Monitoring",
    "EmployeeDevice",
    "MonitoringScreenShot",
    "LeaveType",
    "WorkingShift",
    "NoticeBoard",
    "Category",
    "FAQ",
    "Manuals",
    "Services",
    "Header",
    "Home",
    "Slider",
    "Footer",
    "WhyChooseUs",
    "Airline",
    "CompanyHistory",
    "Company",
    "Mission",
    "Leadership",
    "Awards",
    "Sustainability",
    "Services",
    "MediaData",
    "MediaCategory",
    "Job",
    "JobCategory",
    "JobDepartment",
    "JobApplication",
    "Career",
    "GoodPeopleCategory",
    "AwardCategory",
    "ContactList",
    "MediaResourceCategory"
    // 'PJTypeList', 'PJStatusList', 'RegionList', "rTData",
  ],

  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
});
