import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  forwardRef,
} from "react";
import { Popper } from "@mui/material";
import { useSpring, animated } from "react-spring";
import classNames from "classnames";
import ReactDatePicker from "react-datepicker";
import { CalendarIcon } from "../common/Icons";
import { twMerge } from "tailwind-merge";
import { renderDateFormatDMY } from "../../app/services/api";

interface IDate {
  date?: Date;
  setDate: Dispatch<React.SetStateAction<Date | undefined>>;
  maxDate?: Date;
  minDate?: Date;
  hasMaxDate?: boolean;
  hasMinDate?: boolean;
  placeholder: string;
  isProject?: boolean;
  includeDates?: Date[];
  classesProps?: string;
}

interface FadeProps {
  children?: React.ReactElement;
  in?: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

const Fade = forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props: any,
  ref: any
) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const DatePopupFoFilter: FC<IDate> = ({
  date,
  setDate,
  isProject,
  maxDate,
  minDate,
  hasMaxDate,
  hasMinDate,
  placeholder,
  includeDates,
  classesProps,
}) => {
  var buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
    buttonRef?.current
  );
  const [open, setOpen] = useState(true);
  const [placement, setPlacement] = useState<any>();
  const [selectedDate, setSelectedDate] = useState(date);

  var dateRef = useRef<ReactDatePicker>(null);
  var resetDate = useRef<ReactDatePicker>(null);
  const handleClick = (newPlacement: any, event: any) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
    setPlacement(newPlacement);
    dateRef.current?.setOpen(true);
  };

  const handleChangeDate = (date: Date) => {
    setSelectedDate(date);
    setDate(date);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "spring-popper" : undefined;

  useEffect(() => {
    setOpen(true);
  }, [open]);
  useEffect(() => {
    setAnchorEl(buttonRef.current);
  }, [buttonRef]);

  useEffect(() => {
    if (date) {
      setSelectedDate(date);
    }
  }, [date]);

  return (
    <div className="relative">
      {/* <div> */}
        <button
          ref={buttonRef}
          className={classNames(
            isProject ? "py-[7px] rounded-sm" : "rounded-[28px]",
            classesProps ? classesProps : "min-w-[120px]",
            "border border-[#CCDAE4]  w-auto  text-left flex justify-between items-center  text-[13px] sm:text-14 leading-5 text-graydark"
          )}
          onClick={(event) => {
            handleClick("left-start", event);
          }}
        >
          <span
            className={twMerge(
              "font-normal w-full h-full px-[10px] py-[1px] text-left text-graydark xl:text-sm text-12 whitespace-nowrap"
            )}
          >
            {isProject && selectedDate
              ? renderDateFormatDMY(selectedDate, "/")
              : date && selectedDate
                ? placeholder + ":" + renderDateFormatDMY(selectedDate, "/")
                : placeholder}
            {/* {date && selectedDate ? placeholder + ':' + renderDateFormatDMY(selectedDate, "/") : placeholder} */}
          </span>
          <div
            className={twMerge("border-l border-[#CCDAE4] px-[10px] py-[1px]")}
          >
            <CalendarIcon width={22} height={22} />
          </div>
        </button>
      {/* </div> */}
      {anchorEl && (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          placement={placement}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <ReactDatePicker
                calendarClassName="relative"
                popperClassName="relative"
                ref={dateRef}
                className="w-fit"
                selected={date}
                // maxDate={maxDate?maxDate:new Date()}
                maxDate={hasMaxDate ? new Date() : maxDate}
                minDate={hasMinDate ? new Date() : minDate}
                onChange={(prev: Date) => {
                  handleChangeDate(prev);
                  setOpen(false);
                }}
                dateFormat={"dd MMM yyyy"}
                showMonthDropdown
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown={true}
                customInput={<input className="absolute opacity-0 hidden" />}
                onFocus={(e) => {
                  setOpen(false);
                  dateRef.current?.setOpen(false);
                }}
                includeDates={includeDates}                
              />
            </Fade>
          )}
        </Popper>
      )}
    </div>
  );
};

export default DatePopupFoFilter;
