import { ReactElement } from "react";
import { lazy, Suspense } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import Employees from "./views/Employees";

export const employeeRoutes = [
  { id: 1, path: "", component: <Employees /> },
];

const EmployeeRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;

  return (
    <BasicLayout userData={data} permissions={permissions} sidebars={props.sidebarsData}>
      <Outlet />
    </BasicLayout>
  );
};

export default EmployeeRoute;
