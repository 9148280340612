import { ReactElement } from 'react';
import BasicLayout from '../layouts/BasicLayout';
import HomePage from './components/HomePage';
import { Outlet } from 'react-router-dom';
import WhoWeAreSection from './sections/WhoWeAreSection';
import ServiceSection from './sections/ServiceSection';
import WhyChooseUsSection from './sections/WhyChooseUsSection';
import CustomerAirline from './sections/CustomerAirline';
import BannerSliderSection from './sections/BannerSliderSection';
export const pagesList=[
    {
      id:1,
      path:'#',
      component:<HomePage/>
    },
    {
      id:2,
      path:'home',
      component:<HomePage/>
    },
    {
      id:3,
      path:'banner',
      component:<BannerSliderSection/>
    },
    {
      id:34,
      path:'who-we-are',
      component:<WhoWeAreSection/>
    },
    {
      id:5,
      path:'services',
      component:<ServiceSection/>
    },
    {
      id:6,
      path:'why-choose-us',
      component:<WhyChooseUsSection/>
    },
    {
      id:7,
      path:'airlines',
      component:<CustomerAirline/>
    }
  ]
const HomePageRoute = (props:any): ReactElement => {
    const data = props.loginData;
    const permissions = props.permissions;
    return  <BasicLayout userData={data} permissions={permissions} sidebars={props.sidebarsData}>
    <div className="pt-0 pb-[58px]">
      <Outlet />
    </div>
  </BasicLayout>
}
export default HomePageRoute;
