import { FC } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import FooterComponent from "./views/FooterComponent";
import FooterSocialMediaPage from "./views/FooterSocialMediaPage";
import FooterList from "./views/FooterList";

interface IFooterrRoute {
  loginData: any,
  permissions: any;
  sidebarsData:any;
}

export const footerRoutes = [
  { id: 1, path: "", component: <FooterComponent /> },
  { id: 2, path: "footer", component: <FooterComponent /> },
  { id: 3, path: "footerlist", component: <FooterList /> },
  { id: 4, path: "social", component: <FooterSocialMediaPage /> },
];

const FooterRoute: FC<IFooterrRoute> = ({ loginData, permissions,sidebarsData }) => {
  const data = loginData;

  return (
    <BasicLayout userData={data} permissions={permissions} sidebars={sidebarsData}>
      <div className="pt-0 pb-[58px]">
        <Outlet />
      </div>
    </BasicLayout>
  );
};

export default FooterRoute;
