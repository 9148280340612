import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { baseObj } from "../../utils/constants";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { useGetServiceInfoMutation, useGetServicesListQuery } from "../../app/services/services/services";
import { endpointUrl } from "../../app/services/api";
import axios from "../../axios";
import Button from "../../components/common/Button";
import Service from "../components/Service";
import ServicePageSectionTable from "./ServicePageSectionTable";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../components/common/Icons";
import { NumberDropdownList } from "../../components/common/DropdownList";
import { getAuthUser } from "../../app/services/dashboard";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";
export interface IServicePageData {
    id: number;
    title_en: string;
    title_tc: string;
    title_sc: string;
    image: File | undefined;
    image_url: string;
    icon: File | undefined;
    icon_url: string;
    page_type: string;
    category?: string;
}
const ServicePageList = () => {
    const [detailData, setDetailData] = useState<IServicePageData>({
        id: 0,
        title_en: '',
        title_tc: '',
        title_sc: '',
        image: undefined,
        image_url: '',
        icon: undefined,
        icon_url: '',
        page_type: 'passenger-service'
    });
    const [show, setShow] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [viewCols, setCols] = useState<any>([]);
    const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
        false
    );
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [data, setData] = useState<baseObj[]>([]);
    const [itemCount, setItemCount] = useState<number>(0);
    const [hiddenFields, setHiddenFields] = useState<string[]>([]);
    const [globalSearch, setGlobalSearch] = useState<string>("");
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20
    });
    const [getSustainabilityInfo] = useGetServiceInfoMutation();
    const { data: services } = useGetServicesListQuery({
        page: currentPage,
        limit: pagination.pageSize,
        search: globalSearch,
        sort: sorting ? sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(',') : '',
    })
    useEffect(() => {
        if (services) {
            setData(services?.data);
            setItemCount(services.total);
        }
    }, [services])
    const handle_submit = async (data: IServicePageData) => {
        const formData = new FormData();
        if (data) {
            formData.set('id', data.id?.toString() ?? 0)
            formData.set('title_en', data.title_en);
            formData.set('title_tc', data.title_tc);
            formData.set('title_sc', data.title_sc);
            formData.set('page_type', (data.page_type !== '' ? data.page_type : 'passenger-service'));
            if (data.image) {
                formData.set('image', data.image);
            }
            if (data.icon) {
                formData.set('icon', data.icon);
            }
            const url = detailData?.id ? 'update-service-content' : 'service-content';
            var getUser = getAuthUser();
            await axios(endpointUrl + url, {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${getUser.token}`,
                },
                data: formData,
            })
                .then((res: any) => {
                    if (res?.data?.status === true) {
                        getSustainabilityInfo({
                            page: currentPage,
                            limit: pagination.pageSize,
                            search: '',
                            type: (data.page_type !== '' ? data.page_type : 'passenger-service'),
                            sort: ''
                        }).then((res: any) => {
                            setData(res?.data?.data);
                            setItemCount(res?.data?.total)
                        })
                        toast(res?.data?.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            type: "success",
                        });
                        setShow(true);
                    } else {
                        setErrorPopup(true);
                        setMessage(res?.response?.data?.message);
                    }
                    setIsSave(false);
                })
                .catch((err) => {
                    setErrorPopup(true);
                    if (err?.response?.status === 500) {
                        setMessage(err?.response?.data?.message);
                        return;
                    }
                    setMessage(Object.values(err?.response?.data?.errors)?.join(' '));
                });
        }

    }
    const clear_data = () => {
        setDetailData({
            id: 0,
            title_en: '',
            title_tc: '',
            title_sc: '',
            image: undefined,
            image_url: '',
            icon: undefined,
            icon_url: '',
            page_type: ''
        })
    }
    return <div >
        <div className="text-left">
            <ToastContainer className={"text-12 font-poppins font-normal"} />
            <CommonErrorPopup
                show={errorPopup}
                setShow={setErrorPopup}
                message={message}
            />
            {show === false ? <Service
                initialData={detailData}
                setValue={setDetailData}
                setShowList={setShow}
                handle_submit={handle_submit}
            /> :
                <>
                    <div className="items-center xs:mt-1 sm:mt-1 flex justify-between mb-3">
                        <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] my-1">
                            <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
                                {setState =>
                                    <div className="flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                                            onChange={e => setState(e.target.value)}
                                        />
                                        <SearchIcon className="mr-2" />
                                    </div>}
                            </DebounceSearchInput>
                        </div>
                        <Button
                            label="+ Add New"
                            onClick={() => { clear_data(); setShow(false) }}
                            type="button"
                            variant="primary"
                            customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                            size="base"
                        />
                    </div>
                    <div className="flex sm:flex-row flex-col justify-end mb-1">
                        <div className="flex items-center sm:justify-start justify-end">
                            <div className="flex items-center 3xl:ml-3">
                                <p className="xl:text-sm text-12 mr-2 text-black2 font-primary">
                                    DISPLAY
                                </p>
                                <NumberDropdownList
                                    state={pagination.pageSize}
                                    setState={value =>
                                        setPagination(prev => ({
                                            ...prev,
                                            pageSize: value as number
                                        }))}
                                    dropdownItems={new Array(5).fill(1).map((_, index: number) => {
                                        const count = (index + 1) * 20;
                                        return {
                                            value: count,
                                            label: String(count)
                                        };
                                    })}
                                    className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                                    textAlign="text-left pl-3"
                                    paddingClass="py-[2px]"
                                    placeholder="All"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-left">
                        <ServicePageSectionTable
                            // columnsData={columnsData}
                            isLayoutControlOpen={isLayoutControlOpen}
                            setIsLayoutControlOpen={setIsLayoutControlOpen}
                            globalSearch={globalSearch}
                            setGlobalSearch={setGlobalSearch}
                            columnFilters={columnFilters}
                            setColumnFilters={setColumnFilters}
                            pagination={pagination}
                            data={data}
                            setData={setData}
                            setPagination={setPagination}
                            hiddenFields={hiddenFields ? hiddenFields : []}
                            setHiddenFields={setHiddenFields}
                            checkManagement={true}
                            total={itemCount}
                            setItemCount={setItemCount}
                            localModuleId={3}
                            // visArray={visArray}
                            templateList={templateList}
                            setCurrentView={() => 1}
                            setCols={setCols}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            setServicesData={setDetailData}
                            setShow={setShow}
                            sorting={sorting}
                            setSorting={setSorting}
                        />
                    </div>
                </>
            }
        </div>
    </div>
}
export default ServicePageList;