import React, { useEffect, useMemo, useState } from "react";
import {
  useGetDashboardDataQuery,
} from "../../../app/services/dashboard";
import DashboardInformation from "../../../components/DashboardInformation/DashboardInformation";
const Dashboard = (props: any) => {
  const {data:dashboardDataList,isLoading}=useGetDashboardDataQuery();
  const [dashboardData,setDashboardData]=useState<any>([]);
  useEffect(()=>{
    if(dashboardDataList){
      setDashboardData(dashboardDataList?.listData);
    }
  },[dashboardDataList])
  return (
    <>
      <div className=''>
        {isLoading ? (
          <div
            role="status"
            className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center mb-2"
          >
            <div className="flex items-center justify-center w-36 h-40 bg-gray-300 rounded sm:w-1/4">
              <div className="w-9 h-10 text-gray-200"></div>
            </div>
            <div className="flex items-center justify-center w-36 h-40 bg-gray-300 rounded sm:w-1/4">
              <div className="w-9 h-10 text-gray-200"></div>
            </div>
          </div>
        ) : (
          <DashboardInformation
            dashboardCountData={
              dashboardData ? dashboardData : []
            }
          />
        )}
      </div>
    </>
  );
};
export default Dashboard;
