import { ReactElement } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import OverViewPage from "./sections/OverviewPage";
import ServicePage from "./sections/ServicePage";
import RampOverViewPage from "./sections/RampOverviewPage";
import LoadControlOverviewPage from "./sections/LoadControlOverviewPage";
import SidebarFunc from "../layouts/BasicLayout/SidebarFunc";
export const servicePagesList = [
  {
    id: 1,
    path: "#",
    component: <ServicePage />,
  },
  {
    id: 2,
    path: "passenger-overview",
    component: <OverViewPage />,
  },
  {
    id: 3,
    path: "ramp-overview",
    component: <RampOverViewPage />,
  },
  {
    id: 4,
    path: "load-control-overview",
    component: <LoadControlOverviewPage />,
  },
  {
    id: 5,
    path: "service-list",
    component: <ServicePage />,
  },
];
const ServicePageRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;
  var sidebarsData = SidebarFunc();
  return (
    <BasicLayout userData={data} permissions={permissions} sidebars={sidebarsData}>
    <div className="pt-0 pb-[58px]">
      <Outlet />
    </div>
  </BasicLayout>
  );
};
export default ServicePageRoute;
