
import { FC, useEffect, useState } from "react";
interface ILabelAndEditor {
  refId?: any;
  label: string;
  value: string|undefined;
  setValue: (value: React.SetStateAction<string>) => void;
  isRequired?:boolean;
}
const LabelAndTextbox: FC<ILabelAndEditor> = ({
  refId,
  label,
  value,
  setValue,isRequired
}) => {
  const [data,setData]=useState<string>('');
  useEffect(()=>{
    setData(value??'');
  },[value]);
  return (
    <div className="mb-5 last:mb-0">
      <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
      {label}{isRequired==true?<span className="text-14 text-red-500">*</span>:''}
      </label>
      <input autoComplete="false"
        type="text"
        name="textbox"
        value={data}
        onChange={(e)=>setData(e.target.value)}
        onBlur={event => {
          setValue(event.target.value);
        }}
        placeholder=""
        className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
      />
    </div>
  );
};
export default LabelAndTextbox;
