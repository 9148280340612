import { FC, useEffect, useState } from "react";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../components/common/Icons";
import Button from "../../components/common/Button";
import { NumberDropdownList } from "../../components/common/DropdownList";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import HeaderTable from "./HeaderTable";
import { baseObj } from "../../utils/constants";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { useGetMenusQuery } from "../../app/services/header";
interface INews {
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSubmenuList: React.Dispatch<React.SetStateAction<boolean>>;
  showSubmenuList: boolean;
  setDetailData: React.Dispatch<any>;
}
const HeaderList: FC<INews> = ({ setShowList, showSubmenuList, setShowSubmenuList, setDetailData }) => {
  const [editRowData, setEditRowData] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [viewCols, setCols] = useState<any>([]);
  const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
    false
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [data, setData] = useState<baseObj[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [tempList, setTempList] = useState<any>(templateList);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });
  const { data: menuList } = useGetMenusQuery({
    page: currentPage,
    limit: pagination.pageSize,
    search: globalSearch,
    sort: sorting
      .map(
        (s) =>
          `${s.desc ? "-" : ""}${s.id == "roles"
            ? "role"
            : s.id == "createdBy"
              ? "created_user"
              : s.id
          }`
      )
      .join(","),
  })

  useEffect(() => {
    if (menuList) {
      setData(menuList?.data);
      setItemCount(menuList?.total);
    }
  }, [menuList])
  return (
    <div className="bg-white rounded-xl pt-4 pb-4 sm:pt-4 sm:pb-9 w-full h-full font-primary">
      <div className="flex flex-wrap justify-between items-center mb-1">
        <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] my-1">
          <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
            {setState =>
              <div className="flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                <input
                  type="text"
                  placeholder="Search"
                  className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                  onChange={e => setState(e.target.value)}
                />
                <SearchIcon className="mr-2" />
              </div>}
          </DebounceSearchInput>
        </div>
        <div className="items-center xs:mt-1 sm:mt-1">
          <div className="flex items-center 3xl:ml-3">
            <p className="xl:text-sm text-12 mr-2 text-black2 font-primary">
              DISPLAY
            </p>
            <NumberDropdownList
              state={pagination.pageSize}
              setState={value =>
                setPagination(prev => ({
                  ...prev,
                  pageSize: value as number
                }))}
              dropdownItems={new Array(5).fill(1).map((_, index: number) => {
                const count = (index + 1) * 20;
                return {
                  value: count,
                  label: String(count)
                };
              })}
              className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
              textAlign="text-left pl-3"
              paddingClass="py-[2px]"
              placeholder="All"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="text-left">
          <HeaderTable
            // columnsData={columnsData}
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            pagination={pagination}
            data={data}
            setData={setData}
            setPagination={setPagination}
            hiddenFields={hiddenFields ? hiddenFields : []}
            setHiddenFields={setHiddenFields}
            checkManagement={true}
            total={itemCount}
            localModuleId={localModuleId}
            // visArray={visArray}
            templateList={tempList}
            setCurrentView={() => 1}
            setCols={setCols}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setEditRowData={setEditRowData}
            editRowData={editRowData}
            setShowList={setShowList}
            setShowSubmenuList={setShowSubmenuList}
            setDetailData={setDetailData}
            sorting={sorting}
            setSorting={setSorting}
          />
        </div>
      </div>
    </div>
  );
};
//

export default HeaderList;
